var _a;
class SecCompany {
    cik;
    id;
    name;
    sicDescription;
    tags;
    static columns = [
        { accessorKey: 'cik', header: 'CIK' },
        {
            accessorKey: 'name', header: 'Name',
        },
    ];
    static getSort = (s0, s1) => s0.name.localeCompare(s1.name);
    constructor(response) {
        this.cik = response.cik;
        this.id = response.id;
        this.name = response.name;
        this.sicDescription = response.sicDescription;
        this.tags = response.tags;
    }
    #clone() {
        return new _a({
            cik: this.cik,
            id: this.id,
            name: this.name,
            sicDescription: this.sicDescription,
            tags: this.tags
        });
    }
    updateTags(allCompanyTags) {
        const clone = this.#clone();
        clone.tags = allCompanyTags.find(t => t.companyId === this.id);
        return clone;
    }
}
_a = SecCompany;
export default SecCompany;
