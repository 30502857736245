import * as React from 'react';
import { Button } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import TransactionWrapper from '../../../models/TransactionWrapper';
import TransactionMergeModal from './TransactionMergeModal';
const TransactionMergeButton = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { selection, resetRowExpansion, resetRowSelection, tableUpdateFn, } = React.useContext(TableContext);
    const [loadingMerge, setLoadingMerge] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const disabledMerge = React.useMemo(() => selection !== undefined && selection.length < 2, [selection]);
    const saveMerge = React.useCallback(async (sector, regions, types, name) => {
        if (selection !== undefined && resetRowSelection !== undefined && resetRowExpansion !== undefined && tableUpdateFn !== undefined) {
            const selectedIndexedTransactions = selection;
            const idSelections = selection.map((t) => t.id);
            const idSelection = idSelections.join(',');
            resetRowSelection();
            setLoadingMerge(true);
            const formData = new FormData();
            formData.append('ids', idSelection);
            formData.append('sector', sector);
            formData.append('regions', regions.join(","));
            formData.append('types', types.join(','));
            formData.append('name', name);
            const request = new ServerRequest('/syndicate/transactions/merge', { body: formData, method: 'POST' });
            try {
                const responseTransction = await apiFetchJson(request);
                const returnTransaction = new TransactionWrapper(responseTransction);
                const newestTransaction = selectedIndexedTransactions
                    .sort((t0, t1) => (t1.lastUpdate.getTime() - t0.lastUpdate.getTime()))[0];
                const updateFn = (data) => ({
                    pageParams: data.pageParams,
                    pages: [...data.pages].map((p) => p
                        .filter((t) => (t.id === newestTransaction.id || !idSelections.includes(t.id)))
                        .map((t) => (t.id === newestTransaction.id ? returnTransaction : t))),
                });
                tableUpdateFn(updateFn);
                /**
               * we remove the older ones. As there are now two transactions with the same 'oldestId',
               * we cannot select the ones to remove based on Id.
               */
                //        resetRowExpansion(selection.map((s) => s));
            }
            catch (error) {
                sendFetchError('Unable to merge transactions', error, request);
                //
            }
            finally {
                setLoadingMerge(false);
            }
        }
    }, [apiFetchJson, resetRowExpansion, resetRowSelection, selection, tableUpdateFn]);
    const clickMerge = React.useCallback(() => {
        if (selection !== undefined && selection.length > 1) {
            const uniqueSectors = [...new Set(selection.map((t) => t.sector).filter((t) => t !== undefined))];
            // const uniqueRegions: Region[] = [...new Set(selection.map((t) => t.region).filter((t) => t !== undefined))];
            const uniqueRegions = selection.map(t => t.regions).filter(r => r !== undefined).filter(r => r.length > 0);
            const uniqueRegionNames = [...new Set(uniqueRegions.map(r => r.join(",")))];
            const uniqueTypes = selection.map((t) => t.types).filter(t => t !== undefined).filter((t) => t.length > 0);
            const uniqueTypesNames = [...new Set(uniqueTypes.map((t) => t.join(',')))];
            const uniqueNames = [...new Set(selection.map((t) => t.name).filter((t) => t !== undefined))];
            if (uniqueSectors.length === 1 && uniqueRegionNames.length === 1 && uniqueTypesNames.length === 1 && uniqueNames.length === 1) {
                (async () => {
                    await saveMerge(uniqueSectors[0], uniqueRegions[0], uniqueTypes[0], uniqueNames[0]);
                })();
            }
            else {
                setShowModal(true);
            }
        }
    }, [saveMerge, selection]);
    return (React.createElement(React.Fragment, null,
        showModal
            ? React.createElement(TransactionMergeModal, { onClose: () => { setShowModal(false); }, save: saveMerge }) : undefined,
        React.createElement(Button, { disabled: disabledMerge, loading: loadingMerge, name: "merge", onClick: clickMerge }, "MERGE")));
};
export default TransactionMergeButton;
