import FilterItem from '../FilterItem';
import AnnaSecurity from '../security/AnnaSecurity';
class AnnaFilter {
    tags;
    constructor(response) {
        this.tags = response.tags ? new FilterItem(response.tags) : undefined;
    }
    static DEFAULT = new AnnaFilter({
        tags: new FilterItem()
    });
    clone() {
        return new AnnaFilter({
            tags: this.tags
        });
    }
    updateTags(value) {
        const clone = this.clone();
        if (clone.tags === undefined) {
            clone.tags = new FilterItem({ exclude: true, values: value });
        }
        else {
            clone.tags.values = value;
        }
        return clone;
    }
    create() {
        const test = (contains, name) => (this[name]?.exclude && !contains)
            ?? (!this[name]?.exclude && contains);
        return (s) => {
            if (s instanceof AnnaSecurity) {
                const tagsTest = this.tags?.values.length ?
                    test(s.tags.some((t) => this.tags?.values.includes(t)), 'tags')
                    : true;
                return tagsTest;
            }
            return false;
        };
    }
    hasValues() {
        if (this.tags?.values !== undefined && this.tags.values.length > 0) {
            return true;
        }
        return false;
    }
}
export default AnnaFilter;
