class SecQuery {
    CIK;
    company;
    dateb;
    owner;
    type;
    constructor(response) {
        this.company = response?.company;
        this.CIK = response?.CIK;
        this.dateb = response?.dateb;
        this.owner = response?.owner;
        this.type = response?.type;
    }
    getCreationDate = () => undefined;
    getCurrency = () => undefined;
    getMaturity = () => undefined;
}
export default SecQuery;
