import * as React from 'react';
import { Dropdown, Switch, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import { passwordRequirements } from 'utils/passwords';
import { errorMessage } from 'utils/validation/initialValidation';
import User from '../../models/User';
import useOption from 'components/customHooks';
const UserForm = ({ save, user = new User() }) => {
    const [data, setData] = React.useState(user);
    const [errorState, setErrorState] = React.useState({});
    const [isUser, setUser] = React.useState(user.username !== undefined);
    const [blanked, setBlanked] = React.useState(false);
    const authoritiesOptions = useOption('/admin/users/options');
    const passwordInfo = React.useMemo(() => passwordRequirements.map((t) => `* ${t}`).join(''), []);
    const companyOptions = useOption('/admin/companies/USER/options', { cache: false });
    /**
     * Symphony's Validator.js gives an error if we have a single Validator rather than an array...; this may change in future, in which case we can move to single ones.
     */
    const validators = React.useMemo(() => {
        const validatorsNew = {
            displayName: [Validators.Required, Validators.MinLength(3)],
            emailAddress: [Validators.Email, Validators.Required],
            firstName: [Validators.Required, Validators.MinLength(3)],
            lastName: [Validators.Required, Validators.MinLength(3)],
        };
        if (isUser) {
            validatorsNew.username = [Validators.Required, Validators.MaxLength(40)];
            validatorsNew.password = [CustomValidators.Password];
        }
        return validatorsNew;
    }, [isUser]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedData = data.updateField(name, value);
        setData(updatedData);
    }, [data]);
    React.useEffect(() => {
        const blankPassword = () => {
            const updatedData = data.updateField('password', "");
            setData(updatedData);
            setBlanked(true);
        };
        if (!blanked) {
            document.querySelectorAll('input[type="password"]').forEach((element) => element.addEventListener('focus', blankPassword, { once: true }));
        }
        return () => {
            document.querySelectorAll('input[type="password"]').forEach((element) => element.removeEventListener('focus', blankPassword));
        };
    }, [blanked, data]);
    const onChangeCompany = React.useCallback((event) => {
        const { value } = event.target.value;
        data.updateField('companyId', value);
    }, [data]);
    const onChangeSwitch = React.useCallback((name) => () => {
        const updatedData = data.toggleBooleanField(name);
        setData(updatedData);
    }, [data]);
    const onChangeAuthorities = React.useCallback((event) => {
        const updatedData = data.updateAuthorities(event.target.value);
        setData(updatedData);
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        console.log("Error state: %o", errorState);
        const newErrorState = { ...errorState };
        newErrorState[name] = isValid;
        setErrorState(newErrorState);
    }, [errorState]);
    const getAuthoritiesValue = React.useCallback(() => {
        if (data.authorityMap === undefined) {
            return [];
        }
        return Object.entries(data.authorityMap).flatMap(([key, values]) => values.map((v) => ({
            data: key,
            label: `${key.replace('cmo.', '')}: ${v.replace('ROLE_', '')}`,
            value: v,
        })));
    }, [data]);
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "User", save: save(isUser), setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form" },
            React.createElement(Validation, { validator: validators.firstName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('firstName') },
                React.createElement(TextField, { label: "First Name", onChange: onChangeInput('firstName'), showRequired: true, value: data.firstName })),
            React.createElement(Validation, { validator: validators.lastName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('lastName') },
                React.createElement(TextField, { label: "Last Name", onChange: onChangeInput('lastName'), showRequired: true, value: data.lastName })),
            React.createElement(Validation, { validator: validators.displayName, errorMessage: errorMessage, onValidationChanged: onValidationChanged('displayName') },
                React.createElement(TextField, { label: "Display Name", onChange: onChangeInput('displayName'), showRequired: true, value: data.displayName })),
            React.createElement(Validation, { validator: validators.emailAddress, errorMessage: errorMessage, onValidationChanged: onValidationChanged('emailAddress') },
                React.createElement(TextField, { label: "Email Address", onChange: onChangeInput('emailAddress'), showRequired: true, value: data.emailAddress })),
            React.createElement(TextField, { label: "Title", onChange: onChangeInput('title'), value: data.title }),
            React.createElement(TextField, { label: "Job Function", onChange: onChangeInput('jobFunction'), value: data.jobFunction }),
            React.createElement(Dropdown, { label: "Company", onChange: onChangeCompany, options: companyOptions }),
            React.createElement(TextField, { label: "Company", onChange: onChangeInput('company'), value: data.company }),
            React.createElement(TextField, { label: "Location", onChange: onChangeInput('location'), value: data.location }),
            React.createElement(TextField, { label: "Department", onChange: onChangeInput('department'), value: data.department }),
            React.createElement(TextField, { label: "Division", onChange: onChangeInput('division'), value: data.division }),
            React.createElement(TextField, { label: "Mobile Phone Number", onChange: onChangeInput('mobilePhoneNumber'), value: data.mobilePhoneNumber }),
            React.createElement(TextField, { label: "Work Phone Number", onChange: onChangeInput('workPhoneNumber'), value: data.workPhoneNumber })),
        React.createElement("div", { className: "form-buttons" },
            React.createElement(Switch, { label: "User", name: "switch", onChange: () => setUser(!isUser), status: isUser ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: isUser ? 'true' : 'false' })),
        isUser
            ? (React.createElement("div", { className: "base-form" },
                React.createElement(Validation, { validator: validators.username, errorMessage: errorMessage, onValidationChanged: onValidationChanged('username') },
                    React.createElement(TextField, { label: "User Name", onChange: onChangeInput('username'), showRequired: true, value: data.username })),
                React.createElement(Validation, { validator: validators.password, errorMessage: errorMessage, onValidationChanged: onValidationChanged('password') },
                    React.createElement(TextField, { isMasked: true, label: "Password", onChange: onChangeInput('password'), showRequired: true, tooltip: passwordInfo, value: data.password })),
                React.createElement(Switch, { label: "Account Non Expired", name: "accountNonExpired", onChange: onChangeSwitch('accountNonExpired'), status: data.accountNonExpired ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: data.accountNonExpired ? 'true' : 'false' }),
                React.createElement(Switch, { label: "Account Non Locked", name: "accountNonLocked", onChange: onChangeSwitch('accountNonLocked'), status: data.accountNonLocked ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: data.accountNonLocked ? 'true' : 'false' }),
                React.createElement(Switch, { label: "Credentials Non Expired", name: "credentialsNonExpired", onChange: onChangeSwitch('credentialsNonExpired'), status: data.credentialsNonExpired ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: data.credentialsNonExpired ? 'true' : 'false' }),
                React.createElement(Switch, { label: "Enabled", name: "enabled", onChange: onChangeSwitch('enabled'), status: data.enabled ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: data.enabled ? 'true' : 'false' }),
                React.createElement(Dropdown, { isMultiSelect: true, label: "Authorities", name: "authorities", onChange: onChangeAuthorities, options: authoritiesOptions, value: getAuthoritiesValue() }))) : React.createElement("div", null)));
};
export default UserForm;
