import FilterItem from '../FilterItem';
import SecSecurity from '../security/SecSecurity';
class SecFilter {
    tags;
    exclude;
    name;
    values;
    constructor(response) {
        this.tags = response?.tags;
        this.exclude = response?.exclude ?? true;
        this.name = response?.name;
        this.values = response?.values ?? [];
    }
    clone() {
        return new SecFilter({
            exclude: this.exclude,
            name: this.name,
            values: this.values,
            tags: this.tags
        });
    }
    updateExclude(event) {
        const newFilter = this.clone();
        newFilter.exclude = event.target.value;
        return newFilter;
    }
    updateName(event) {
        const newFilter = this.clone();
        newFilter.name = event.target.value;
        return newFilter;
    }
    updateTags(event) {
        const newFilter = this.clone();
        const values = event.target.value.map(t => t.value);
        if (newFilter.tags === undefined) {
            newFilter.tags = new FilterItem({ exclude: true, values });
        }
        else {
            newFilter.tags.values = values;
        }
        return newFilter;
    }
    updateValues(event) {
        const newFilter = this.clone();
        newFilter.values = event.target.value;
        return newFilter;
    }
    create() {
        const test = (contains, name) => (this[name]?.exclude && !contains) ?? (!this[name]?.exclude && contains);
        return (s) => {
            if (s instanceof SecSecurity) {
                const tagsTest = this.tags?.values.length ? test(s.tags.some((t) => this.tags?.values.includes(t)), 'tags') : true;
                return tagsTest;
            }
            return false;
        };
    }
}
export default SecFilter;
