import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import NavigationContext from 'contexts/NavigationContext';
import NoViewError from 'components/NoViewError';
import Party from '../../models/Party';
import PartyTable from './PartyTable';
import ServerRequest from 'models/ServerRequest';
import { sendError, sendFetchError } from 'utils/messageUtils';
const Parties = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { updateView, view } = React.useContext(NavigationContext);
    const [parties, setParties] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/syndicate/parties');
        (async () => {
            setLoading(true);
            try {
                const body = await apiFetchJson(request);
                const downloadedParties = body.map((u) => new Party(u));
                setParties(downloadedParties);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    setError(true);
                    sendFetchError('Unable to download parties', error, request);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const deleteFn = React.useCallback(({ row: { original: party } }) => {
        const requestInit = { body: JSON.stringify(party), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' };
        const request = new ServerRequest('/syndicate/parties', requestInit);
        (async () => {
            try {
                await apiFetchNoContent(request);
                const filteredParties = parties.filter((u) => u.id !== party.id);
                setParties(filteredParties);
            }
            catch (error) {
                sendFetchError('Unable to Delete', error, request);
                //
            }
        })();
    }, [apiFetchNoContent, parties]);
    const removeName = React.useCallback((name) => {
        const updatedParties = [...parties].map(p => {
            if (p.name !== 'unknown') {
                return p;
            }
            p.names = p.names.filter(n => n !== name);
            return p;
        });
        setParties(updatedParties);
    }, [parties]);
    const saveOnly = React.useCallback(async (data) => {
        const method = data.id ? 'PUT' : 'POST';
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method };
        const request = new ServerRequest('/syndicate/parties', requestInit);
        const body = await apiFetchJson(request);
        return new Party(body);
    }, [apiFetchJson]);
    const save = React.useCallback(async (data) => {
        const method = data.id ? 'PUT' : 'POST';
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method };
        const request = new ServerRequest('/syndicate/parties', requestInit);
        try {
            const updatedParty = await saveOnly(data);
            const index = parties.findIndex((d) => d.id === updatedParty.id);
            const updatedParties = [...parties];
            if (index === -1) {
                updatedParties.push(updatedParty);
            }
            else {
                updatedParties[index] = updatedParty;
            }
            setParties(updatedParties);
        }
        catch (error) {
            sendFetchError('Unable to save party', error, request);
            //
        }
    }, [saveOnly, parties]);
    const upsertList = React.useCallback((list, party) => {
        const index = list.findIndex((d) => d.id === party.id);
        if (index === -1) {
            list.push(party);
        }
        else {
            list[index] = party;
        }
        list.sort((a, b) => a.name.localeCompare(b.name));
    }, []);
    const saveName = React.useCallback(async (name, party) => {
        const updatedParties = [...parties];
        try {
            const updatedParty = await saveOnly(party);
            upsertList(updatedParties, updatedParty);
            const unknown = parties.find((p) => p.name === 'unknown');
            if (unknown !== undefined) {
                unknown.names = [...new Set(unknown.names.filter((n) => n !== name))];
                const updatedUnknown = await saveOnly(unknown);
                upsertList(updatedParties, updatedUnknown);
            }
            setParties(updatedParties);
        }
        catch (error) {
            sendError('Unable to save', error);
        }
    }, [parties, saveOnly, upsertList]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('TABLE');
        }
    }, [view, updateView]);
    switch (view) {
        case 'TABLE':
            return (React.createElement(PartyTable, { deleteFn: deleteFn, parties: parties, hasError: hasError, isLoading: isLoading, removeName: removeName, save: save, saveName: saveName }));
        default:
            return (React.createElement(NoViewError, { view: view }));
    }
};
export default Parties;
