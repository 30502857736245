import UserContext from 'contexts/UserContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError, setOnline } from 'utils/messageUtils';
const CACHE = "options";
export default function useMap(url) {
    const [data, setData] = React.useState(new Map());
    const { authorizedFetch } = React.useContext(UserContext);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest(url);
        (async () => {
            const cache = await caches.open(CACHE);
            const matchedRequest = await cache.match(request);
            if (matchedRequest !== undefined) {
                const body = await matchedRequest.json();
                const map = new Map();
                Object.entries(body).forEach(([key, value]) => {
                    map.set(key, value);
                });
                setData(map);
            }
            else {
                try {
                    const response = await authorizedFetch(request, abortController.signal);
                    setOnline();
                    if (response.status === 200) {
                        await cache.put(request, response.clone());
                        const body = await response.json();
                        const map = new Map();
                        Object.entries(body).forEach(([key, value]) => {
                            map.set(key, value);
                        });
                        setData(map);
                    }
                }
                catch (error) {
                    sendFetchError('Unable to download options', error, request);
                }
            }
        })();
        return () => abortController.abort();
    }, [authorizedFetch, url]);
    return data;
}
