import * as React from 'react';
import InfiniteTable from 'components/custom-table/InfiniteTable';
import ActionButton from 'components/custom-table/action/ActionButton';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import './OverviewTable.css';
import overviewColumns from './overviewColumns';
import useOption from 'components/customHooks';
const OverviewTable = ({ addTranche, confirm, details, manageMeta, removeOverrides, seeUpdate, meta, viewOthers, }) => {
    const currencyOptions = useOption('/syndicate/overview/currencies/options');
    const esgOptions = useOption('/syndicate/constant/ESG/options');
    const regionOptions = useOption('/syndicate/overview/regions/options');
    const sectorOptions = useOption('/syndicate/overview/sectors/options');
    const statusOfNotesOptions = useOption('/syndicate/constant/STATUS_NOTES/options');
    const statusOptions = useOption('/syndicate/overview/status/options');
    const typeOptions = useOption('/syndicate/overview/types/options');
    const actionCell = React.useCallback((context) => {
        const { data } = context.row.original;
        const isins = data?.identifiers?.some((i) => i.type === 'ISIN' && 'value' in i);
        const actionsMenu = [
            { callback: details, label: 'View Messages' },
            { callback: seeUpdate, label: 'Go to Update' },
            { callback: viewOthers, disabled: !isins, label: 'View Bond in Other Sources' },
            { callback: manageMeta, label: 'Manage meta data' },
            { callback: removeOverrides, label: 'Remove Overrides' },
            { callback: addTranche, label: 'Add Tranche' },
            { callback: confirm, label: 'Confirm' },
        ];
        return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
    }, [addTranche, confirm, details, manageMeta, removeOverrides, seeUpdate, viewOthers]);
    const columns = React.useMemo(() => {
        const actionColumn = [{
                cell: actionCell,
                enableColumnFilter: false,
                id: 'actions',
                size: 20,
            }];
        const otherColumns = overviewColumns(sectorOptions, esgOptions, statusOfNotesOptions, currencyOptions, typeOptions, statusOptions, regionOptions);
        return actionColumn.concat(otherColumns);
    }, [actionCell, currencyOptions, esgOptions, regionOptions, statusOfNotesOptions, sectorOptions, statusOptions, typeOptions]);
    const dataTransform = React.useCallback((data) => data.map((d) => new TrancheWrapper(d)), []);
    return (React.createElement(InfiniteTable, { columns: columns, dataTransform: dataTransform, emptyMessage: "No transactions found", errorMessage: "Error downloading transactions", fetchSize: 50, meta: meta, name: "overview", urlPage: "/syndicate/overview/page", urlTotal: "/syndicate/overview/total" }));
};
export default OverviewTable;
