import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import Country from '../../models/Country';
import CountryTable from './CountryTable';
const Countries = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [countries, setCountries] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [hasError, setError] = React.useState(false);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/admin/countries');
        setLoading(true);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const downloadedCountries = body.map((u) => new Country(u));
                setCountries(downloadedCountries);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError("Unable to download countries", error, request);
                    setError(true);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const deleteFn = React.useCallback(async (country) => {
        const request = new ServerRequest('/admin/countries', { body: JSON.stringify(country), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
        try {
            await apiFetchNoContent(request);
            const filteredCountries = [...countries].filter((u) => u.id !== country.id);
            setCountries(filteredCountries);
        }
        catch (error) {
            sendFetchError("Unable to delete company", error, request);
        }
    }, [apiFetchNoContent, countries]);
    const save = React.useCallback(async (country) => {
        const method = country.id ? "PATCH" : "POST";
        const request = new ServerRequest("/admin/countries", { body: JSON.stringify(country), headers: { 'Content-Type': 'application/json' }, method });
        try {
            const body = await apiFetchJson(request);
            ;
            const updatedCountry = new Country(body);
            ;
            const index = countries.findIndex(d => d.id === body.id);
            const updatedCountries = [...countries];
            if (index === -1) {
                updatedCountries.push(updatedCountry);
            }
            else {
                updatedCountries[index] = updatedCountry;
            }
            setCountries(updatedCountries);
            updateView('TABLE');
        }
        catch (error) {
            sendFetchError("Unable to update country", error, request);
        }
    }, [apiFetchJson, countries, updateView]);
    switch (view) {
        case 'TABLE':
            return (React.createElement(CountryTable, { countries: countries, deleteFn: deleteFn, hasError: hasError, isLoading: isLoading, save: save }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Countries;
