class MetaData {
    createdBy;
    createdDate;
    id;
    lastModifiedBy;
    lastModifiedDate;
    permissions;
    constructor(response) {
        this.createdBy = response?.createdBy;
        this.createdDate = response?.createdDate;
        this.id = response?.id;
        this.lastModifiedBy = response?.lastModifiedBy;
        this.lastModifiedDate = response?.lastModifiedDate;
        this.permissions = response?.permissions;
    }
}
export default MetaData;
