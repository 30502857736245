import { printEquivalentAmount, realDateSort, realNumberSort } from 'utils/table';
import cfiParser from '../../utils/cfiParser';
import securitiesTableActionCell from '../../components/results/securitiesTableActionCell';
import EsmaCompany from '../company/EsmaCompany';
class EsmaSecurity {
    bnd_fixed_rate;
    bnd_fltng_rate_bs_pnt_sprd;
    bnd_fltng_rt_ndx_code;
    bnd_fltng_rt_ndx_name;
    bnd_fltng_rt_ndx_trm_value;
    bnd_fltng_rt_ndx_trm_unit;
    bnd_maturity_date;
    bnd_nmnl_value_total;
    bnd_nmnl_value_unit;
    bnd_seniority;
    cfi;
    gnr_cfi_code;
    gnr_full_name;
    gnr_notional_curr_code;
    gnr_short_name;
    isin;
    issuername; // added manually, not from ESMA database
    lei;
    maturityObj;
    mic;
    mrkt_trdng_rqst_date;
    mrkt_trdng_start_date;
    nominalObj;
    rate; // added manually, not from ESMA database
    tags;
    static parseNumber = (text) => parseInt(text.replace(/,/, ''), 10);
    static parseDate = (date) => {
        if (date === '9999-12-31 00:00:00.0') {
            return 'Perpetual';
        }
        return new Date(date);
    };
    static printDate = (date) => {
        if (date instanceof Date) {
            return date.toLocaleDateString('en-GB', { dateStyle: 'short' });
        }
        return date;
    };
    static printNumber = (value) => Intl.NumberFormat().format(value);
    getAmount = () => parseFloat(this.bnd_nmnl_value_total);
    getCfi = () => this.gnr_cfi_code;
    getCurrency = () => this.gnr_notional_curr_code;
    getIssuername = () => this.issuername;
    getMaturity = () => EsmaSecurity.parseDate(this.bnd_maturity_date);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getSort = (s0, s1) => 0;
    getShortname = () => this.issuername;
    static columnsSpreadsheet = [
        { accessorFn: (s) => s.issuername, header: 'Issuer' },
        { accessorFn: (s) => s.lei, header: 'LEI' },
        { accessorFn: (s) => s.isin, header: 'ISIN' },
        { accessorFn: (s) => s.gnr_notional_curr_code, header: 'Currency' },
        {
            accessorFn: (s) => s.bnd_nmnl_value_total, header: 'Nominal Value Total',
        },
        {
            accessorFn: (s) => s.bnd_maturity_date, header: 'Maturity Date',
        },
        { accessorFn: (s) => s.bnd_fixed_rate, header: 'Fixed Rate' },
        {
            accessorFn: (s) => s.bnd_nmnl_value_unit, header: 'Nominal Value Unit',
        },
        { accessorFn: (s) => s.bnd_seniority, header: 'Seniority' },
        { accessorFn: (s) => s.mic, header: 'MIC' },
        { accessorFn: (s) => s.cfi.category, header: 'Category' },
        { accessorFn: (s) => s.cfi.group, header: 'Group' },
        { accessorFn: (s) => s.cfi.attribute1, header: 'Attribute1' },
        { accessorFn: (s) => s.cfi.attribute2, header: 'Attribute2' },
        { accessorFn: (s) => s.cfi.attribute3, header: 'Attribute3' },
        { accessorFn: (s) => s.cfi.attribute4, header: 'Attribute4' },
    ];
    getProperties = () => [
        { label: 'Issuer', value: this.issuername },
    ];
    static sortDate = (field) => (rowA, rowB) => {
        const maturityDate1 = rowA.original[field];
        const maturityDate2 = rowB.original[field];
        if (maturityDate2 === 'Perpetual') {
            return -1;
        }
        if (maturityDate1 === 'Perpetual') {
            return 1;
        }
        if (maturityDate1 < maturityDate2) {
            return -1;
        }
        if (maturityDate1 > maturityDate2) {
            return 1;
        }
        return 0;
    };
    static sortNumber = (field) => (rowA, rowB) => {
        const maturityDate1 = rowA.original[field];
        const maturityDate2 = rowB.original[field];
        return maturityDate1 - maturityDate2;
    };
    static columns(currencies, setModalContent) {
        return [
            { cell: securitiesTableActionCell(setModalContent), id: 'actions', size: 20 },
            { accessorKey: 'issuername', header: 'Name' },
            { accessorKey: 'gnr_notional_curr_code', header: 'Currency' },
            {
                accessorFn: (s) => EsmaSecurity.printNumber(s.nominalObj),
                header: 'Nom.value total',
                id: 'nominal',
                sortingFn: EsmaSecurity.sortNumber('nominalObj'),
            },
            {
                accessorFn: (data) => printEquivalentAmount(data.gnr_notional_curr_code, Number.parseInt(data.bnd_nmnl_value_total, 10), currencies),
                header: `Eqv in ${localStorage.getItem('baseCurrency') ?? 'EUR'}`,
                id: 'eqvAmount',
                size: 40,
                sortingFn: (d0, d1) => realNumberSort(d0.original.bnd_nmnl_value_total, d1.original.bnd_nmnl_value_total),
            },
            { accessorKey: 'rate', header: 'Rate' },
            {
                accessorFn: (s) => EsmaSecurity
                    .printDate(s.maturityObj),
                header: 'Maturity',
                id: 'maturity',
                sortingFn: EsmaSecurity.sortDate('maturityObj'),
            },
            {
                accessorFn: (s) => s.mrkt_trdng_rqst_date,
                header: 'Mkt Rqst Date',
                id: 'maturityDate',
                sortingFn: (d0, d1) => realDateSort(d0.original.mrkt_trdng_rqst_date, d1.original.mrkt_trdng_rqst_date),
            },
            {
                accessorKey: 'mrkt_trdng_start_date',
                header: 'Mkt Start Date',
                id: 'mrkt_trdng_start_date',
                sortingFn: (d0, d1) => realDateSort(d0.original.mrkt_trdng_start_date, d1.original.mrkt_trdng_start_date),
            },
            { accessorKey: 'gnr_full_name', header: 'Full name' },
            { accessorKey: 'gnr_short_name', header: 'Short name' },
            { accessorKey: 'bnd_seniority', header: 'Seniority' },
            {
                accessorFn: (data) => (new Intl.NumberFormat()).format(Number.parseInt(data.bnd_nmnl_value_unit, 10)),
                header: 'Nom. value unit',
                id: 'bnd_nmnl_value_unit',
                sortingFn: (d0, d1) => realNumberSort(d0.original.bnd_nmnl_value_unit, d1.original.bnd_nmnl_value_unit),
            },
            { accessorKey: 'time_lastUpdate', header: 'Last Update' },
            { accessorKey: 'isin', header: 'ISIN' },
            { accessorKey: 'cfi.category', header: 'Category' },
            { accessorKey: 'cfi.group', header: 'Group' },
            { accessorKey: 'cfi.attribute1', header: 'Attribute 1' },
            { accessorKey: 'cfi.attribute2', header: 'Attribute 2' },
            { accessorKey: 'cfi.attribute3', header: 'Attribute 3' },
            { accessorKey: 'cfi.attribute4', header: 'Attribute 4' },
            { accessorKey: 'tags', header: 'Tags' },
        ];
    }
    static create = (base, companies) => {
        const security = Object.assign(new EsmaSecurity(), base);
        security.cfi = cfiParser(security.gnr_cfi_code);
        if (companies.length > 0) {
            const company = companies.find(security.companyMatcher);
            if (company !== undefined) {
                security.tags = company.tags;
                if (company.company instanceof EsmaCompany) {
                    security.issuername = company.company ? company.company.entity.legalName.name : 'unknown';
                }
            }
            else {
                security.tags = [];
            }
        }
        else {
            security.issuername = 'unknown';
            security.tags = [];
        }
        security.rate = security.getRate();
        security.maturityObj = this.parseDate(security.bnd_maturity_date);
        security.nominalObj = this.parseNumber(security.bnd_nmnl_value_total);
        return security;
    };
    getRate() {
        let rate = '';
        if (this.bnd_fixed_rate) {
            rate = this.bnd_fixed_rate;
        }
        else if (this.bnd_fltng_rt_ndx_name === 'FORMULA') {
            rate = 'FORMULA';
        }
        else if (this.bnd_fltng_rt_ndx_trm_value) {
            rate = this.bnd_fltng_rt_ndx_trm_value;
            rate += `${this.bnd_fltng_rt_ndx_trm_unit.substring(0, 1)} `;
            if (this.bnd_fltng_rt_ndx_name)
                rate += `${this.bnd_fltng_rt_ndx_name} `;
            if (this.bnd_fltng_rt_ndx_code)
                rate += `${this.bnd_fltng_rt_ndx_code} `;
            if (Number.parseFloat(this.bnd_fltng_rate_bs_pnt_sprd) >= 0)
                rate += '+';
            rate += `${this.bnd_fltng_rate_bs_pnt_sprd} bps`;
        }
        return rate;
    }
    companyMatcher = (c) => c.company.lei === this.lei;
}
export default EsmaSecurity;
