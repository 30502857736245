import * as React from 'react';
import { Dropdown, Typography, Validation, } from '@symphony-ui/uitoolkit-components';
import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import FileInput from 'components/file-input/FileInput';
import { getOptionsMulti } from 'utils/change';
import { errorMessage } from 'utils/validation/initialValidation';
import useOption from 'components/customHooks';
const WorkflowForm = ({ save, workflow }) => {
    const [data, setData] = React.useState(workflow);
    const [errorState, setErrorState] = React.useState({});
    const partiesOptions = useOption('/users/dropdown');
    const validators = React.useMemo(() => ({
        partiesBloomberg: CustomValidators.RequiredArray,
        partiesBooking: CustomValidators.RequiredArray,
        partiesDocumentation: CustomValidators.RequiredArray,
        partiesIsin: CustomValidators.RequiredArray,
        partiesListing: CustomValidators.RequiredArray,
        termsheet: CustomValidators.RequiredFile,
    }), []);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newValue = Array.isArray(value) ? [...new Set(value.map((v) => v.value))] : [value.value];
        const newObject = data.updateParties(name, newValue);
        setData(newObject);
    }, [data]);
    const onChangeFile = React.useCallback((event) => {
        const { files } = event.target;
        const file = files?.item(0);
        if (file != null) {
            const newObject = data.updateId(file.name);
            setData(newObject);
        }
    }, [data]);
    const onValidationChanged = (name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    };
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "Workflow", save: save, setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form", id: "workflow-form" },
            React.createElement(Validation, { validator: validators.termsheet, errorMessage: errorMessage, onValidationChanged: onValidationChanged('termsheet') },
                React.createElement(FileInput, { label: "Term Sheet", name: "termsheet", onChange: onChangeFile })),
            React.createElement(Typography, { type: "h2" }, "Select those responsible for"),
            React.createElement(Validation, { validator: validators.partiesBloomberg, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesBloomberg') },
                React.createElement(Dropdown, { label: "Bloomberg Set Up", options: partiesOptions, onChange: onChangeDropdown('partiesBloomberg'), isMultiSelect: true, value: getOptionsMulti(data.partiesBloomberg, partiesOptions) })),
            React.createElement(Validation, { validator: validators.partiesIsin, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesIsin') },
                React.createElement(Dropdown, { label: "ISIN Code", options: partiesOptions, onChange: onChangeDropdown('partiesIsin'), isMultiSelect: true, value: getOptionsMulti(data.partiesIsin, partiesOptions) })),
            React.createElement(Validation, { validator: validators.partiesDocumentation, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesDocumentation') },
                React.createElement(Dropdown, { label: "Documentation", options: partiesOptions, onChange: onChangeDropdown('partiesDocumentation'), isMultiSelect: true, value: getOptionsMulti(data.partiesDocumentation, partiesOptions) })),
            React.createElement(Validation, { validator: validators.partiesListing, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesListing') },
                React.createElement(Dropdown, { label: "Listing", options: partiesOptions, onChange: onChangeDropdown('partiesListing'), isMultiSelect: true, value: getOptionsMulti(data.partiesListing, partiesOptions) })),
            React.createElement(Validation, { validator: validators.partiesBooking, errorMessage: errorMessage, onValidationChanged: onValidationChanged('partiesBooking') },
                React.createElement(Dropdown, { label: "Trade Booking", options: partiesOptions, onChange: onChangeDropdown('partiesBooking'), isMultiSelect: true, value: getOptionsMulti(data.partiesBooking, partiesOptions) })))));
};
export default WorkflowForm;
