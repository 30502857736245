import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import { getOptions } from 'utils/change';
import QualifiedSearch from '../../models/QualifiedSearch';
import './QualifiedSearch.css';
const QualifiedSearchInput = ({ disabled, label, onChange, tooltip, value = new QualifiedSearch({ qualifier: 'sw', text: [] }), }) => {
    const onChangeDropdown = React.useCallback((event) => {
        const { value: value2 } = event.target.value;
        onChange({ target: { value: new QualifiedSearch({ qualifier: value2, text: value.text }) } });
    }, [onChange, value.text]);
    const onChangeDropdownMulti = React.useCallback((event) => {
        const values = event.target.value.map(v => v.value);
        onChange({ target: { value: new QualifiedSearch({ qualifier: value.qualifier, text: values }) } });
    }, [onChange, value.qualifier]);
    const options = React.useMemo(() => value.text.map((t) => ({ label: t, value: t })), [value.text]);
    return (React.createElement("div", { className: "tk-input-group tag-input" },
        React.createElement(LabelTooltipDecorator, { label: label, tooltip: tooltip }),
        React.createElement("div", { className: "co-input-container", id: "search-group" },
            React.createElement(Dropdown, { addNewOptions: true, className: "first-one", size: "large", isDisabled: disabled, menuPortalStyles: { background: 'green' }, style: { background: 'green' }, onChange: onChangeDropdown, options: QualifiedSearch.options, value: getOptions(value.qualifier, QualifiedSearch.options) }),
            React.createElement(Dropdown, { addNewOptions: true, className: "second-one", closeMenuOnSelect: true, isDisabled: disabled, isMultiSelect: true, onChange: onChangeDropdownMulti, options: options, value: options }))));
};
export default QualifiedSearchInput;
