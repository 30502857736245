import * as React from 'react';
import { Dropdown, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper/FormWrapper';
import { getOptions } from 'utils/change';
import { errorMessage } from 'utils/validation/initialValidation';
import TermSheet from '../../model/TermSheet';
import useOption from 'components/customHooks';
const TermSheetForm = ({ save, termSheet, }) => {
    const [data, setData] = React.useState(termSheet ?? new TermSheet({}));
    const [errorState, setErrorState] = React.useState({});
    const currencyOptions = useOption('/mtn/currencies/options');
    const interestBasisOptions = useOption('/mtn/interest-basis/options');
    const issuerOptions = useOption('/mtn/issuers/options');
    const validators = React.useMemo(() => ({
        coupon: Validators.Required,
        currency: CustomValidators.RequiredOption,
        interestBasis: CustomValidators.RequiredOption,
        issueDate: Validators.Required,
        issuer: CustomValidators.RequiredOption,
        maturityDate: Validators.Required,
        nominal: Validators.Required,
    }), []);
    const onChangeDropdown = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const newObject = data.update(name, value.value);
        setData(newObject);
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        const updatedTermsheet = data.update(name, value);
        setData(updatedTermsheet);
    }, [data]);
    const onValidationChanged = React.useCallback((name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    }, [errorState]);
    return (React.createElement(FormWrapper, { data: data, errorState: errorState, name: "Term Sheet", save: save, setErrorState: setErrorState, validators: validators },
        React.createElement("div", { className: "base-form", id: "termsheet-form" },
            React.createElement(Validation, { validator: validators.issuer, errorMessage: errorMessage, onValidationChanged: onValidationChanged('issuer') },
                React.createElement(Dropdown, { isInputClearable: true, label: "Issuer", name: "issuer", onChange: onChangeDropdown('issuer'), options: issuerOptions, value: getOptions(data.issuer, issuerOptions) })),
            React.createElement(Validation, { validator: validators.currency, errorMessage: errorMessage, onValidationChanged: onValidationChanged('currency') },
                React.createElement(Dropdown, { label: "Currency", name: "currency", onChange: onChangeDropdown('currency'), options: currencyOptions, value: getOptions(data.currency, currencyOptions) })),
            React.createElement(Validation, { validator: validators.nominal, errorMessage: errorMessage, onValidationChanged: onValidationChanged('nominal') },
                React.createElement(TextField, { label: "Nominal", onChange: onChangeInput('nominal'), value: data.nominal ?? '' })),
            React.createElement(Validation, { validator: validators.issueDate, errorMessage: errorMessage, onValidationChanged: onValidationChanged('issueDate') },
                React.createElement(TextField, { label: "Issue Date", onChange: onChangeInput('issueDate'), value: data.issueDate ?? '' })),
            React.createElement(Validation, { validator: validators.maturityDate, errorMessage: errorMessage, onValidationChanged: onValidationChanged('maturityDate') },
                React.createElement(TextField, { label: "Maturity Date", onChange: onChangeInput('maturityDate'), value: data.maturityDate ?? '' })),
            React.createElement(Validation, { validator: validators.interestBasis, errorMessage: errorMessage, onValidationChanged: onValidationChanged('interestBasis') },
                React.createElement(Dropdown, { label: "Interest Basis", onChange: onChangeDropdown('interestBasis'), options: interestBasisOptions, value: getOptions(data.interestBasis, interestBasisOptions) })),
            React.createElement(Validation, { validator: validators.coupon, errorMessage: errorMessage, onValidationChanged: onValidationChanged('coupon') },
                React.createElement(TextField, { label: "Coupon", onChange: onChangeInput('coupon'), value: data.coupon ?? '' })))));
};
export default TermSheetForm;
