import * as React from 'react';
import { Button, Icon, TextField, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const CredentialsInput = ({ initial, source, title }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [data, setData] = React.useState(initial);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData({ ...data, [name]: value });
    }, [data]);
    const onClickShare = React.useCallback(() => {
        const request = new ServerRequest(`/nit/credentials/${source.toLowerCase()}`, { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'PUT' });
        (async () => {
            try {
                await apiFetchJson(request);
            }
            catch (error) {
                sendFetchError('Unable to save credentials', error, request);
            }
        })();
    }, [data, apiFetchJson, source]);
    const disabled = React.useMemo(() => !initial.password || !initial.username, [initial]);
    return (React.createElement("div", { className: "creds" },
        React.createElement(Typography, { type: "h2" }, title),
        React.createElement("div", { className: "cred-input" },
            React.createElement(TextField, { label: "username", onChange: onChangeInput('username'), value: data.username })),
        React.createElement("div", { className: "cred-input" },
            React.createElement(TextField, { isMasked: true, label: "password", onChange: onChangeInput('password'), value: data.password })),
        React.createElement(Button, { className: "cred-button", disabled: disabled, iconButton: true, onClick: onClickShare },
            React.createElement(Icon, { iconName: "share" }))));
};
export default CredentialsInput;
