import * as React from 'react';
import PeriodInput from './period-input/PeriodInput';
import PeriodRange from '../models/PeriodRange';
const PeriodRangeInput = ({ defaultPeriod, disabled, onChange, value = { start: undefined, end: undefined }, }) => {
    const [data, setData] = React.useState(value);
    const onChangeInput = React.useCallback((name) => ({ target }) => {
        const { value: newValue } = target;
        const updatedPeriodRange = name === 'start' ? { start: newValue, end: data.end } : { start: data.start, end: newValue };
        setData(updatedPeriodRange);
        const returnObject = {
            target: {
                value: PeriodRange.isEmpty(updatedPeriodRange) ? undefined : new PeriodRange(updatedPeriodRange),
            },
        };
        onChange(returnObject);
    }, [data, onChange]);
    return (React.createElement("div", { className: "co-range" },
        React.createElement(PeriodInput, { defaultPeriod: defaultPeriod, disabled: disabled, period: data.start, onChange: onChangeInput('start') }),
        React.createElement(PeriodInput, { defaultPeriod: defaultPeriod, disabled: disabled, period: data.end, onChange: onChangeInput('end') })));
};
export default PeriodRangeInput;
