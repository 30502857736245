import * as React from 'react';
import { Dropdown, TextField, } from '@symphony-ui/uitoolkit-components';
import UserContext from 'contexts/UserContext';
import { getOptions } from 'utils/change';
import SecSearch from '../../../models/search/SecSearch';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import FilterItem from '../../../models/FilterItem';
const SecForm = ({ search, setSearch }) => {
    const { checkEditable } = React.useContext(UserContext);
    const disabled = React.useMemo(() => search.id ? !checkEditable(search) : false, [checkEditable, search]);
    const onChangeFilter = React.useCallback((name) => (event) => {
        const updatedSearch = Object.assign(new SecSearch(), search);
        updatedSearch.filter[name] = event.target.value;
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const onChangeQuery = React.useCallback((name) => (event) => {
        const updatedSearch = Object.assign(new SecSearch(), search);
        updatedSearch.query = { ...search.query, [name]: event.target.value };
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const onChangeQuerySelect = React.useCallback((name) => (event) => {
        const updatedSearch = Object.assign(new SecSearch(), search);
        updatedSearch.query = { ...search.query, [name]: event.target.value.value };
        setSearch(updatedSearch);
    }, [search, setSearch]);
    const options = [{ label: 'Include', value: 'include' }, { label: 'Exclude', value: 'exclude' }, { label: 'Only', value: 'only' }];
    return (React.createElement("div", { className: "base-form" },
        React.createElement(TextField, { disabled: disabled, label: "CIK", value: search.query.CIK ?? '', onChange: onChangeQuery('CIK'), tooltip: "The CIK code, which is the SEC's classification for legal entities, similar to the global LEI codes." }),
        React.createElement(TextField, { disabled: disabled, label: "Company", onChange: onChangeQuery('company'), value: search.query.company ?? '' }),
        React.createElement(TextField, { disabled: disabled, label: "DateB", onChange: onChangeQuery('dateb'), value: search.query.dateb ?? '' }),
        React.createElement(Dropdown, { isDisabled: disabled, label: "Owner", name: "owner", onChange: onChangeQuerySelect('owner'), options: options, tooltip: "Whether to include changes in ownership by insiders in the results. This will have no impact if you choose a Type.", value: getOptions(search.query.owner, options) }),
        React.createElement(TextField, { disabled: disabled, label: "Type", onChange: onChangeQuery('type'), tooltip: "Filing type, such as 424B2, 144 or 8K", value: search.query.type ?? '' }),
        React.createElement(RemoteFilterItem, { disabled: disabled, label: "Tags", onChange: onChangeFilter('tags'), path: "/nit/companies/tags/options/sec", value: search.filter.tags ?? new FilterItem() })));
};
export default SecForm;
