var _a;
class Currency {
    id;
    value;
    constructor(response) {
        this.id = response.id;
        this.value = response.value;
    }
    #clone() {
        return new _a({
            id: this.id,
            value: this.value
        });
    }
    updateValue(name, newValue) {
        const clone = this.#clone();
        clone[name] = newValue;
        return clone;
    }
}
_a = Currency;
export default Currency;
