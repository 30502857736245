import * as React from 'react';
import KeyValueRegexPicker from './KeyValueRegexPicker';
import KeyValueRegexButtons from './KeyValueRegexButtons';
import useOption from 'components/customHooks';
const KeyValueRegexForm = ({ callbackAdd, callbackCreate, isFormDisabled, initialKeyValueRegexId, name, setKeyValueRegex, }) => {
    const [keyValueRegexId, setKeyValueRegexId] = React.useState(initialKeyValueRegexId);
    const [options, setOptions] = React.useState([]);
    const loadedOptions = useOption('/syndicate/regex/keyvalues/regexoptions');
    console.log("keyvalue regex id: %o = %o", keyValueRegexId, initialKeyValueRegexId);
    const callbackButtonCreate = (updatedKeyValueRegex) => {
        if (name !== undefined && updatedKeyValueRegex.id !== undefined) {
            const newOptions = [...options];
            newOptions.push({ label: name, value: updatedKeyValueRegex.id });
            setOptions(newOptions);
            setKeyValueRegex(updatedKeyValueRegex);
            callbackCreate(updatedKeyValueRegex);
        }
    };
    const callbackButtonAdd = (updatedKeyValueRegex) => {
        const newOptions = [...options];
        const option = newOptions.find((o) => 'value' in o && o.value === updatedKeyValueRegex.id);
        if (option !== undefined) {
            option.label = updatedKeyValueRegex.keys.join(', ');
            setOptions(newOptions);
            setKeyValueRegex(updatedKeyValueRegex);
            callbackAdd(updatedKeyValueRegex);
        }
    };
    React.useEffect(() => {
        setOptions(loadedOptions);
    }, [loadedOptions]);
    return (React.createElement("div", { className: "co-keyregexvalue-form" },
        React.createElement(KeyValueRegexPicker, { isDisabled: isFormDisabled, keyValueRegexId: keyValueRegexId, options: options, setKeyValueRegexId: setKeyValueRegexId }),
        !isFormDisabled
            ? (React.createElement(KeyValueRegexButtons, { callbackAdd: callbackButtonAdd, callbackCreate: callbackButtonCreate, keyValueRegexId: keyValueRegexId, name: name })) : ''));
};
export default KeyValueRegexForm;
