import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import { BannerType } from '@symphony-ui/uitoolkit-components';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
import SearchForm from '../components/searches/SearchForm';
import SearchTable from '../components/searches/SearchTable';
import { fromSource, Source, turnIntoClass } from '../sources/sources';
const Searches = () => {
    const PATH = '/nit/search';
    const { apiFetchNoContent, apiFetchJson } = React.useContext(DownloadContext);
    const { updateView, view } = React.useContext(NavigationContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [searches, setSearches] = React.useState([]);
    const [source, setSource] = React.useState(() => {
        const storedSource = localStorage.getItem('source');
        return storedSource !== null ? fromSource(storedSource) : Source.EUROCLEAR;
    });
    const [searchMap, setSearchMap] = React.useState(() => {
        const map = new Map();
        const storedSearch = localStorage.getItem('searches');
        if (storedSearch !== null) {
            const searchRecord = JSON.parse(storedSearch);
            Object.entries(searchRecord).forEach(([key, value]) => {
                map.set(key, turnIntoClass(key, value));
            });
        }
        return map;
    });
    const updateMap = React.useCallback((newSearch) => {
        console.log("New search: %o", newSearch);
        const newSource = newSearch.database();
        const newMap = new Map(searchMap);
        newMap.set(newSource, newSearch);
        setSearchMap(newMap);
        const mapAsRecord = {};
        [...newMap.entries()].forEach(([key, value]) => {
            mapAsRecord[key] = value;
        });
        console.log("NEW MAP: %o =  %o", newMap, JSON.stringify(mapAsRecord));
        localStorage.setItem('searches', JSON.stringify(mapAsRecord));
    }, [searchMap]);
    const updateSource = React.useCallback((updatedSource) => {
        setSource(updatedSource);
        localStorage.setItem('source', updatedSource);
    }, []);
    const path = React.useCallback((db) => `${PATH}/${db.toLowerCase()}`, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            setSearches([]);
            const request = new ServerRequest(PATH);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const loadedSearches = Object.entries(body).flatMap(([key, value]) => value.map(v => turnIntoClass(fromSource(key), v)));
                setSearches(loadedSearches);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError('Unable to download Searches', error, request);
                    setError(true);
                }
            }
            setLoading(false);
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const deleteFn = React.useCallback(({ row: { original: newSearch } }) => {
        console.log("DELETING: %o", newSearch);
        const db = newSearch.database();
        const request = new ServerRequest(path(db), { body: JSON.stringify(newSearch), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
        (async () => {
            try {
                await apiFetchNoContent(request);
                const filteredData = searches.filter((i) => i.id !== newSearch.id);
                setSearches(filteredData);
            }
            catch (error) {
                sendFetchError(`Unable to delete ${db} search`, error, request);
            }
        })();
    }, [apiFetchNoContent, path, searches]);
    const edit = React.useCallback(({ row: { original: newSearch } }) => {
        updateMap(newSearch);
        updateView('FORM');
    }, [updateMap, updateView]);
    const save = React.useCallback(async (search) => {
        const request = new ServerRequest(path(source), { body: JSON.stringify(search), headers: { 'Content-Type': 'application/json' }, method: 'PUT' });
        try {
            const body = await apiFetchJson(request);
            const classedObject = turnIntoClass(source, body);
            const index = searches.findIndex((d) => d.id === classedObject.id);
            const newData = [...searches];
            if (index === -1) {
                newData.push(classedObject);
            }
            else {
                newData[index] = classedObject;
            }
            updateMap(classedObject);
            setSearches(newData);
            sendMessage(`Search ${body.name ?? "UNKNOWN"} updated succesfully`, BannerType.SUCCESS);
        }
        catch (error) {
            sendFetchError(`Unable to update ${source} search`, error, request);
            //
        }
    }, [apiFetchJson, path, searches, source, updateMap]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('TABLE');
        }
    }, [updateView, view]);
    switch (view) {
        case 'FORM':
            return (React.createElement(SearchForm, { save: save, source: source, anySearch: searchMap.get(source), updateSource: updateSource, setAnySearch: (s) => updateMap(s) }));
        case 'TABLE':
            return (React.createElement(SearchTable, { deleteFn: deleteFn, edit: edit, isLoading: isLoading, searches: searches, hasError: hasError }));
        default:
            updateView('TABLE');
            return React.createElement(LoadingPage, null);
    }
};
export default Searches;
