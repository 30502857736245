import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import { sendError, sendFetchError } from 'utils/messageUtils';
const TableSettingsModal = ({ loadSettings, onClose, id, tableName, setTableSettings, }) => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const [value, setValue] = React.useState(id);
    const [tableStateOptions, setTableStateOptions] = React.useState([]);
    const onClickLoad = React.useCallback(() => {
        if (value !== undefined) {
            (async () => {
                try {
                    const body = await loadSettings(value);
                    setTableSettings(body);
                    onClose();
                }
                catch (error) {
                    sendError('Unable to load table settings', error);
                }
            })();
        }
    }, [loadSettings, onClose, setTableSettings, value]);
    const onClickDelete = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/table', { body: value, method: 'DELETE' });
        (async () => {
            try {
                await apiFetchNoContent(request);
                const newOptions = tableStateOptions.filter((o) => 'value' in o && o.value !== value);
                setValue(undefined);
                setTableStateOptions(newOptions);
            }
            catch (error) {
                sendFetchError('Unable to delete table settings', error, request);
            }
        })();
    }, [apiFetchNoContent, setTableStateOptions, tableStateOptions, value]);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value.value);
    }, []);
    const loadDisabled = React.useMemo(() => (value === undefined), [value]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL('/syndicate/table/options');
            url.searchParams.append('name', tableName);
            const request = new ServerRequest(url);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setTableStateOptions(body);
            }
            catch (error) {
                sendFetchError('Unable to download table options', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson, tableName]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Table Settings")),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: {
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%',
                } },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', width: '100%' } },
                    React.createElement("div", null,
                        React.createElement("div", { style: { minWidth: 'calc(100% - 16rem)', position: 'absolute' } },
                            React.createElement(Dropdown, { className: "co-drop-16", label: "Saved Table Settings", onChange: onChange, options: tableStateOptions, value: tableStateOptions.find((o) => 'value' in o && o.value === value) }))),
                    React.createElement("div", { style: { display: 'flex' } },
                        React.createElement(Button, { disabled: loadDisabled, onClick: onClickLoad, style: { marginLeft: '1rem', marginTop: '1.5rem' } }, "LOAD"),
                        React.createElement(Button, { disabled: loadDisabled, onClick: onClickDelete, style: { marginLeft: '1rem', marginTop: '1.5rem' } }, "DELETE"))))),
        React.createElement(ModalFooter, null)));
};
export default TableSettingsModal;
