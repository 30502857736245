import * as React from 'react';
import { Loader, Typography } from '@symphony-ui/uitoolkit-components';
import { getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, getPaginationRowModel, useReactTable, getCoreRowModel, } from '@tanstack/react-table';
import './CustomTable.css';
import DraggableColumnHeader from './DraggableColumnHeader';
import ScrollContext from './ScrollContext';
import SettingsButton from './buttons/SettingsButton';
import ExportButton from './buttons/ExportButton';
import TableStateClass from './TableStateClass';
import { TableFormattingDefault } from './TableFormatSettingsModal';
import RowRender from './RowRender';
import { FiniteTableContext } from './TableContext';
const CustomTable = function CustomTableElement({ actionElements = [], columns, data, emptyMessage, errorMessage, hasError, isLoading, meta = {}, name, }) {
    const { setResetRowExpansion, setResetRowSelection, setSelection, } = React.useContext(FiniteTableContext);
    const tableContainerRef = React.useRef(null);
    const [tableState, setTableState] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        if (stored !== null) {
            const s = JSON.parse(stored);
            if ('state' in s) {
                return new TableStateClass(s.state);
            }
            localStorage.removeItem(`${name}-table-settings`);
        }
        return TableStateClass.DEFAULT;
    });
    const [formatting, setFormatting] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).formatting : TableFormattingDefault;
    });
    const [settingsMeta, setSettingsMeta] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        if (stored === null) {
            return undefined;
        }
        const { id, createdBy, createdDate, modifiedBy, modifiedDate, permissions, } = JSON.parse(stored);
        return {
            id, createdBy, createdDate, modifiedBy, modifiedDate, permissions,
        };
    });
    const [settingsName, setSettingsName] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).name : undefined;
    });
    const bodyRef = React.useRef(null);
    const headRef = React.useRef(null);
    const [tbodyX, setTbodyX] = React.useState(0);
    const [tbodyY, setTbodyY] = React.useState(0);
    const [theadX, setTheadX] = React.useState(0);
    const [theadY, setTheadY] = React.useState(0);
    const [scrollX, setScrollX] = React.useState(0);
    const [scrollY, setScrollY] = React.useState(0);
    React.useEffect(() => {
        // first stringify and then save, as otherwise the text becomes recursive (seems like a bug)
        const settings = {
            state: tableState,
            id: settingsMeta?.id,
            createdBy: settingsMeta?.createdBy,
            createdDate: settingsMeta?.createdDate,
            modifiedBy: settingsMeta?.modifiedBy,
            modifiedDate: settingsMeta?.modifiedDate,
            permissions: settingsMeta?.permissions,
            name: settingsName,
            formatting,
            table: name,
        };
        const stringified = JSON.stringify(settings);
        localStorage.setItem(`${name}-table-settings`, stringified);
    }, [formatting, name, settingsMeta, settingsName, tableState]);
    const checkFilter = React.useCallback((row, columnId, filterValue) => {
        const value = row.getValue(columnId);
        if (Array.isArray(filterValue)) {
            return filterValue.includes(value);
        }
        return (typeof filterValue === 'string' && typeof value === 'string') ? value.toUpperCase().includes(filterValue.toUpperCase()) : true;
    }, []);
    const table = useReactTable({
        columnResizeMode: 'onChange',
        columns,
        data,
        filterFns: {
            checkFilter,
        },
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        meta,
        onStateChange: setTableState,
        state: tableState,
    });
    const arrowKeyEvent = React.useCallback((e) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            if (document.activeElement !== null && document.activeElement.tagName !== 'INPUT') {
                let active = document.activeElement.classList.contains('co-editable') ? document.activeElement : document.querySelector('.co-active');
                if (document.activeElement !== active) {
                    active.focus();
                }
                while (active !== null && !active.className.includes('co-editable')) {
                    active = active.parentElement;
                }
                const tableBody = bodyRef.current;
                if (active === null && tableBody !== null) {
                    const cell = tableBody.querySelector('.co-editable');
                    if (cell !== null) {
                        const body = document.querySelector('body');
                        if (body !== null) {
                            body.classList.add('tab-clicked');
                        }
                        cell.focus();
                    }
                }
                else if (active?.classList.contains('co-editable')) {
                    e.preventDefault();
                    let row = active;
                    while (row !== null && row.tagName !== 'TR') {
                        row = row.parentElement;
                    }
                    if (row !== null && tableBody !== null) {
                        let columnIndex = 0;
                        const allEditable = row.querySelectorAll('.co-editable');
                        while (allEditable.item(columnIndex) !== active) {
                            columnIndex += 1;
                        }
                        let rowIndex = 0;
                        const allRows = tableBody.querySelectorAll('tr');
                        while (allRows.item(rowIndex) !== row) {
                            rowIndex += 1;
                        }
                        if (e.key === 'ArrowLeft') {
                            if (columnIndex > 0) {
                                allEditable.item(columnIndex - 1).focus();
                            }
                        }
                        if (e.key === 'ArrowRight') {
                            if (columnIndex < allEditable.length - 1) {
                                allEditable.item(columnIndex + 1).focus();
                            }
                        }
                        if (e.key === 'ArrowUp') {
                            if (rowIndex > 0) {
                                const newRow = allRows.item(rowIndex - 1);
                                const newAllEditable = newRow.querySelectorAll('.co-editable');
                                newAllEditable.item(columnIndex).focus();
                            }
                        }
                        if (e.key === 'ArrowDown') {
                            if (rowIndex < allRows.length - 1) {
                                const newRow = allRows.item(rowIndex + 1);
                                const newAllEditable = newRow.querySelectorAll('.co-editable');
                                newAllEditable.item(columnIndex).focus();
                            }
                        }
                    }
                }
            }
        }
    }, []);
    const focusInEvent = React.useCallback((event) => {
        const eventTarget = event.target;
        if (eventTarget.tagName === 'DIV' && eventTarget.classList.contains('co-editable')) {
            document.querySelectorAll('.co-active-parent').forEach((e) => {
                e.classList.remove('co-active-parent');
            });
            document.querySelectorAll('.co-active').forEach((e) => {
                e.classList.remove('co-active');
            });
            const parent = eventTarget.parentElement;
            if (parent !== null) {
                parent.classList.add('co-active-parent');
            }
            eventTarget.classList.add('co-active');
        }
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        window.addEventListener('keydown', arrowKeyEvent, { signal: abortController.signal });
        window.addEventListener('focusin', focusInEvent, { signal: abortController.signal });
        return () => { abortController.abort(); };
    }, [arrowKeyEvent, focusInEvent]);
    React.useLayoutEffect(() => {
        document.querySelectorAll('td').forEach((elem) => {
            if (elem.offsetWidth < elem.scrollWidth && elem.textContent !== null) {
                elem.setAttribute('title', elem.textContent);
            }
        });
    }, [isLoading]);
    const onScroll = React
        .useCallback((event) => {
        setScrollX(event.currentTarget.scrollLeft || 0);
        setScrollY(event.currentTarget.scrollTop || 0);
    }, []);
    React.useEffect(() => {
        if (bodyRef.current !== null) {
            setTbodyX(bodyRef.current.getBoundingClientRect().x);
            setTbodyY(bodyRef.current.getBoundingClientRect().y);
        }
        if (headRef.current !== null) {
            setTheadX(headRef.current.getBoundingClientRect().x);
            setTheadY(headRef.current.getBoundingClientRect().y);
        }
    }, []);
    React.useEffect(() => {
        if (setSelection !== undefined) {
            const selectedData = Object.keys(tableState.rowSelection)
                .map((index) => {
                const indexNumber = Number.parseInt(index, 10);
                return data[indexNumber];
            });
            setSelection(selectedData);
        }
    }, [data, tableState.rowSelection, setSelection]);
    /**
     * The values of the row selection can be changed programmatically, using React Table's
     * table.resetRowSelection. However, when the value of the checkbox is changed programatically,
     * the tick does NOT disappear. This is because the row doesn't change - only the result of
     * row.getIsSelected(), which is stored in separate variables in the background.
     * As the tick only disappears when clicking, we mimic the click for each row that is selected.
     */
    React.useEffect(() => {
        if (setResetRowSelection !== undefined) {
            setResetRowSelection(() => () => {
                if (bodyRef.current !== null) {
                    const rowElements = bodyRef.current.children;
                    Array.from(rowElements).forEach(row => {
                        Array.from(row.getElementsByClassName('tk-checkbox__input')).forEach((checkbox) => {
                            const inputElement = checkbox;
                            if (inputElement.checked) {
                                inputElement.click();
                            }
                        });
                    });
                    /*
                    * We reset it here too to ensure boxes that are not rendered are correctly toggeled too.
                    */
                    table.getSelectedRowModel().rows.forEach(r => { r.toggleSelected(); });
                }
            });
        }
    }, [setResetRowSelection, table]);
    /**
    * Set the function to reset the row expansion. Either specific rows are collapsed, or if an
    * empty string is used as parameter, then all expanded rows will be collapsed.
    */
    React.useEffect(() => {
        if (setResetRowExpansion !== undefined) {
            setResetRowExpansion(() => (items) => {
                table.getRowModel().rows.forEach((row) => {
                    if (items.length === 0 && row.getIsExpanded()) {
                        row.toggleExpanded(false);
                    }
                    else if (items.some((item) => item === row.original)) {
                        row.toggleExpanded(false);
                    }
                });
            });
        }
    }, [setResetRowExpansion, table]);
    const scrollValue = React.useMemo(() => ({
        scrollX, scrollY, tbodyX, tbodyY, theadX, theadY,
    }), [scrollX, scrollY, tbodyX, tbodyY, theadX, theadY]);
    const hasData = React.useMemo(() => !isLoading && !hasError && data.length > 0, [data, isLoading, hasError]);
    return (React.createElement("div", { style: {
            display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%',
        } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("div", { style: { display: 'flex' } }, actionElements),
            React.createElement("div", { style: { alignItems: 'end', display: 'flex' } },
                React.createElement(SettingsButton, { formatting: formatting, meta: settingsMeta, name: settingsName, setFormatting: setFormatting, setMeta: setSettingsMeta, setName: setSettingsName, table: table, tableState: tableState, tableName: name }),
                React.createElement(ExportButton, { exportHeaders: formatting.exportHeaders, groupData: formatting.groupData, table: table }))),
        React.createElement("div", { className: "co-table-container tk-theme-condensed", onScroll: onScroll, ref: tableContainerRef },
            React.createElement(ScrollContext.Provider, { value: scrollValue },
                React.createElement("table", { className: `co-table ${meta.className ?? ''}`, role: "grid", style: { height: isLoading || hasError ? '100%' : '' } },
                    React.createElement("thead", { ref: headRef }, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { className: "co-header-row", key: headerGroup.id }, headerGroup.headers.map((header) => (React.createElement(DraggableColumnHeader, { header: header, isNumber: typeof table.getFilteredRowModel().flatRows[0]?.getValue(header.column.id) === 'number', key: header.id, table: table }))))))),
                    React.createElement("tbody", { ref: bodyRef, style: { position: 'relative' } }, hasData
                        ? (table.getRowModel().rows.map((row) => (React.createElement(RowRender, { key: row.index, meta: meta, row: row })))) : undefined))),
            !hasData ?
                React.createElement("div", { className: "co-loader-table" }, isLoading
                    ? React.createElement(Loader, { className: "co-center-page", variant: "primary" })
                    : React.createElement(Typography, { type: "h1" }, hasError ? errorMessage : emptyMessage))
                : undefined)));
};
export default CustomTable;
