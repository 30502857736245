import * as React from 'react';
import { Button, Dropdown, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import useOption from 'components/customHooks';
const SubscriptionsTable = () => {
    const { apiFetchNoContent, apiFetchJson } = React.useContext(DownloadContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [subscriptions, setSubscription] = React.useState([]);
    const [isAddLoading, setAddLoading] = React.useState(false);
    const [newUser, setNewUser] = React.useState();
    const allOptions = useOption('/syndicate/subscriptions/user-options');
    const addSubscription = React.useCallback((subscription) => {
        setSubscription(subscriptions.concat(subscription));
    }, [subscriptions]);
    const deleteSubscription = React.useCallback(({ row: { original: { id } } }) => {
        (async () => {
            const request = new ServerRequest('/syndicate/subscriptions', { body: id, method: 'DELETE' });
            try {
                await apiFetchNoContent(request);
                setSubscription(subscriptions.filter((s) => s.id !== id));
            }
            catch (error) {
                sendFetchError('Unable to delete subscription to daily', error, request);
                setError(true);
            }
        })();
    }, [apiFetchNoContent, subscriptions]);
    const changeUser = React.useCallback((event) => {
        setNewUser(event.target.value.value);
    }, []);
    const add = React.useCallback(() => {
        (async () => {
            const request = new ServerRequest('/syndicate/subscriptions/add', { body: newUser, method: 'POST' });
            try {
                setAddLoading(true);
                const body = await apiFetchJson(request);
                addSubscription(body);
                setNewUser(undefined);
            }
            catch (error) {
                sendFetchError('Unable to add subscription to daily', error, request);
                //
            }
            finally {
                setAddLoading(false);
            }
        })();
    }, [addSubscription, apiFetchJson, newUser]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: deleteSubscription, label: 'Delete' },
                ],
            },
            size: 20,
        },
        {
            accessorKey: 'name',
            enableColumnFilter: true,
            header: 'Name',
            minSize: 280,
        },
        {
            accessorKey: 'company',
            enableColumnFilter: true,
            header: 'Company',
            minSize: 280,
        },
        {
            accessorKey: 'emailAddress',
            enableColumnFilter: true,
            header: 'Email',
            minSize: 280,
        },
    ], [deleteSubscription]);
    const options = React.useMemo(() => {
        const subscriptionEmails = subscriptions.map((s) => s.userId);
        return allOptions.filter((o) => 'value' in o && !subscriptionEmails.includes(o.value));
    }, [allOptions, subscriptions]);
    const isAddDisabled = React.useMemo(() => (newUser == null), [newUser]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const request = new ServerRequest('/syndicate/subscriptions');
            try {
                setLoading(true);
                const body = await apiFetchJson(request, abortController.signal);
                setSubscription(body);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError('Unable to download subscriptions to daily', error, request);
                    setError(true);
                }
            }
            setLoading(false);
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    return (React.createElement(CustomTable, { actionElements: [(React.createElement("div", { key: "subscriber-dropdown", style: { width: '20rem' } },
                React.createElement(Dropdown, { isInputClearable: true, options: options, label: "Add new Subscriber", onChange: changeUser, value: options.find((o) => 'value' in o && o.value === newUser) }))),
            (React.createElement("div", { key: "subscriber-botton", style: { alignSelf: 'self-end', marginLeft: '1rem' } },
                React.createElement(Button, { disabled: isAddDisabled, loading: isAddLoading, onClick: () => add() }, "ADD")))], columns: columns, data: subscriptions, emptyMessage: "", errorMessage: "", hasError: hasError, isLoading: isLoading, name: "subscriptions" }));
};
export default SubscriptionsTable;
