import DownloadContext from 'contexts/DownloadContext';
import RenderingContext from 'contexts/RenderingContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import Popup from 'components/custom-table/action/Popup';
import { Checkbox, SelectionStatus } from '@symphony-ui/uitoolkit-components';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const EditableBooleanCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { printBoolean } = React.useContext(RenderingContext);
    const [errorText, setErrorText] = React.useState();
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(row.original, row.index);
            if (typeof calculated === 'boolean') {
                return calculated;
            }
        }
        return false;
    });
    const remove = React.useCallback(async () => {
        if (typeof table.options.meta?.updateTable === 'function') {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'DELETE' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setLoading(true);
            setEditing(false);
            try {
                const body = await apiFetchJson(request);
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
                // we need to manually update the value as it won't have fed through yet into the row.
                const rowClone = row.original.setEditable(false);
                if (rowClone.overrideMap !== undefined && column.id in rowClone.overrideMap) {
                    delete rowClone.overrideMap[column.id];
                }
            }
            catch (error) {
                sendFetchError('Unable to save override', error, request);
                setErrorText(`Unable to remove ${JSON.stringify(error)}`);
            }
            finally {
                setEditing(false);
                setLoading(false);
            }
        }
    }, [column.id, apiFetchJson, row.original, table]);
    const save = React.useCallback(async () => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('value', 'true');
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setLoading(true);
            try {
                const body = await apiFetchJson(request);
                const response = body.map((v) => new TrancheWrapper(v));
                table.options.meta.updateTable(response);
            }
            catch (error) {
                sendFetchError('Unable to update override', error, request);
                setErrorText(`Unable to save boolean ${JSON.stringify(error)}`);
            }
            setEditing(false);
            setLoading(false);
        }
    }, [column.id, apiFetchJson, row.original.trancheIndex, row.original.transaction.id, table]);
    const removeOrSave = React.useCallback((newValue) => {
        (async () => {
            if (newValue) {
                await save();
            }
            else {
                await remove();
            }
        })();
    }, [remove, save]);
    const onBlur = React.useCallback(() => {
        removeOrSave(value);
    }, [removeOrSave, value]);
    const closePopup = React.useCallback(() => {
        setEditing(false);
    }, []);
    const removeOverride = React.useCallback(() => {
        (async () => remove())();
    }, [remove]);
    const onClick = React.useCallback(() => {
        const newValue = !value;
        setValue(newValue);
        removeOrSave(newValue);
    }, [removeOrSave, value]);
    const content = React.useMemo(() => {
        if (isEditing) {
            return (React.createElement("div", { onBlur: onBlur },
                React.createElement(Popup, { close: closePopup, style: { alignContent: 'center', padding: '2rem' } },
                    React.createElement(Checkbox, { disabled: isLoading, name: `check${row.index.toString()}`, status: value ? SelectionStatus.CHECKED : SelectionStatus.UNCHECKED, onClick: onClick, value: "" }))));
        }
        return printBoolean(value);
    }, [closePopup, isEditing, isLoading, onBlur, onClick, printBoolean, row.index, value]);
    return (React.createElement(EditableCell, { errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] != null, key: cell.id, removeOverride: removeOverride, setEditing: setEditing }, content));
};
export default EditableBooleanCell;
