import options from '../../components/searches/anna-search-form/anna';
import TimeRange from '../TimeRange';
class AnnaQuery {
    annaStatus;
    country;
    entityName;
    entityNameOpton1;
    entityNameOpton2;
    instrumentCategory;
    issueCurrency;
    issueDescription;
    issueDesOptions;
    isinType;
    isinValue;
    maturity;
    maturitydate;
    update;
    getCreationDate = () => this.update;
    getCurrency = () => this.issueCurrency;
    getMaturity = () => this.maturity;
    constructor(response) {
        this.annaStatus = response.annaStatus ?? [options.annaStatus[0].value];
        this.country = response.country ?? [options.country[0].value];
        this.entityName = response.entityName;
        this.entityNameOpton1 = response.entityNameOpton1;
        this.entityNameOpton2 = response.entityNameOpton2;
        this.instrumentCategory = response.instrumentCategory ?? [options.instrumentCategory[0].value];
        this.issueCurrency = response.issueCurrency ?? [options.issueCurrency[0].value];
        this.issueDescription = response.issueDescription;
        this.issueDesOptions = response.issueDesOptions;
        this.isinType = response.isinType;
        this.isinValue = response.isinValue;
        this.maturity = response.maturity ? new TimeRange(response.maturity) : undefined;
        this.maturitydate = response.maturitydate;
        this.update = response.update ? new TimeRange(response.update) : undefined;
    }
    getState() {
        if (this.isinValue && this.isinValue.length > 0)
            return 'isin';
        if (this.entityName && this.entityName.length > 0)
            return 'other';
        if (this.issueDescription && this.issueDescription.length > 0)
            return 'other';
        if (this.annaStatus.length > 0)
            return 'other';
        if (this.country.length > 0)
            return 'other';
        if (this.instrumentCategory.length > 0)
            return 'other';
        if (this.issueCurrency.length > 0)
            return 'other';
        if (!TimeRange.isEmpty(this.maturity))
            return 'other';
        if (!TimeRange.isEmpty(this.update))
            return 'other';
        return undefined;
    }
}
export default AnnaQuery;
