import { Button } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import * as React from 'react';
import KeyValueRegex from '../../../../models/regexes/KeyValueRegex';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const CreateKeyValueRegexButton = ({ callback, name }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const create = () => {
        if (name !== undefined) {
            const newKeyValueRegex = new KeyValueRegex({ keys: [name] });
            newKeyValueRegex.keys = [name];
            (async () => {
                const requestInit = { body: JSON.stringify(newKeyValueRegex), headers: { 'Content-Type': 'application/json' }, method: 'POST' };
                const request = new ServerRequest('/syndicate/regex/keyvalues', requestInit);
                try {
                    const body = await apiFetchJson(request);
                    const savedVersion = new KeyValueRegex(body);
                    callback(savedVersion);
                }
                catch (error) {
                    sendFetchError("Unable to save keyvalueregex", error, request);
                    //
                }
            })();
        }
    };
    return (React.createElement(Button, { onClick: create }, "Create New"));
};
export default CreateKeyValueRegexButton;
