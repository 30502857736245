import * as React from 'react';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import RenderingContext from 'contexts/RenderingContext';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const EditableTextCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { printDate, printNumber } = React.useContext(RenderingContext);
    const editableCellRef = React.useRef(null);
    const [errorText, setErrorText] = React.useState();
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState();
    React.useEffect(() => {
        if (typeof column.accessorFn === 'function') {
            const calculated = column.accessorFn(row.original, row.index);
            if (calculated === undefined) {
                setValue(undefined);
            }
            else if (typeof calculated === 'string') {
                setValue(calculated);
            }
            else if (typeof calculated === 'number') {
                const options = column.columnDef.meta?.numberFormat;
                setValue(printNumber(calculated, options));
            }
            else if (typeof calculated === 'object' && calculated instanceof Date) {
                try {
                    setValue(printDate(calculated, 'YMD'));
                }
                catch (error) {
                    setErrorText(JSON.stringify(error));
                }
            }
        }
    }, [column, printDate, printNumber, row.index, row.original]);
    const print = React.useCallback((calculated) => {
        if (calculated === undefined) {
            return '';
        }
        if (typeof calculated === 'string') {
            return calculated;
        }
        if (typeof calculated === 'number') {
            const options = column.columnDef.meta?.numberFormat;
            return printNumber(calculated, options);
        }
        if (typeof calculated === 'object' && calculated instanceof Date) {
            try {
                return printDate(calculated, 'YMD');
            }
            catch (error) {
                setErrorText(JSON.stringify(error));
            }
        }
        return '';
    }, [column.columnDef.meta?.numberFormat, printDate, printNumber]);
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function' && newValue !== undefined) {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('value', newValue);
            url.searchParams.append('key', column.id);
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            const request = new ServerRequest(url, { method: 'PATCH' });
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setLoading(true);
            try {
                const body = await apiFetchJson(request);
                const response = body.map((v) => new TrancheWrapper(v));
                table.options.meta.updateTable(response);
            }
            catch (error) {
                sendFetchError('Unable to update override information', error, request);
                setErrorText(`Unable to save override information: ${JSON.stringify(error)}`);
            }
            finally {
                setEditing(false);
                setLoading(false);
            }
        }
    }, [column.id, apiFetchJson, row.original.trancheIndex, row.original.transaction.id, table]);
    const onBlur = React.useCallback(() => {
        (async () => save(value))();
    }, [save, value]);
    const onChange = React.useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter') {
            (async () => {
                if (editableCellRef.current !== null) {
                    editableCellRef.current.focus();
                }
                await save(value);
            })();
        }
    }, [save, value]);
    const removeOverride = React.useCallback(() => {
        (async () => {
            await save('');
        })();
    }, [save]);
    const copyValue = React.useCallback(async () => {
        if (typeof column.accessorFn === 'function') {
            const overrideLess = row.original.removeOverrideMap();
            const calculated = column.accessorFn(overrideLess, row.index);
            let text;
            if (typeof calculated === 'string') {
                text = calculated;
            }
            else if (typeof calculated === 'number') {
                text = calculated.toString();
            }
            else if (typeof calculated === 'object' && calculated instanceof Date) {
                text = calculated.toISOString().substring(0, 10);
            }
            if (text !== undefined) {
                const t = { type: 'text/plain' };
                const clipboardText = { columnId: column.id, text };
                const p = JSON.stringify(clipboardText);
                const blob = new Blob([p], t);
                const items = new ClipboardItem({ 'text/plain': blob });
                await navigator.clipboard.write([items]);
            }
        }
    }, [column, row.index, row.original]);
    const pasteValue = React.useCallback(async () => {
        const clipboard = await navigator.clipboard.read();
        const blob = await clipboard[0].getType('text/plain');
        const text = await blob.text();
        try {
            const clipboardText = JSON.parse(text);
            if (typeof clipboardText === 'object' && clipboardText !== null && 'columnId' in clipboardText && 'text' in clipboardText && clipboardText.columnId === column.id && typeof clipboardText.text === 'string') {
                setValue(clipboardText.text);
                await save(clipboardText.text);
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }
        catch (error) {
            // the current value in the clipboard is not a saved value
        }
    }, [column.id, save]);
    const originalValue = React.useMemo(() => {
        if (typeof column.accessorFn === 'function') {
            const overrideLess = row.original.removeOverrideMap();
            const calculated = column.accessorFn(overrideLess, row.index);
            return print(calculated);
        }
        return undefined;
    }, [column, print, row.index, row.original]);
    const content = React.useMemo(() => {
        if (originalValue === undefined) {
            return undefined;
        }
        if (isEditing) {
            return (React.createElement("div", { className: "co-text-edit-container" },
                React.createElement("input", { autoFocus: true, className: "co-editable-input", disabled: isLoading, onBlur: onBlur, onChange: onChange, onKeyUp: onKeyUp, value: value }),
                ";"));
        }
        return value;
    }, [isEditing, isLoading, onBlur, onChange, onKeyUp, originalValue, value]);
    return (React.createElement(EditableCell, { copyValue: copyValue, errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, ref: editableCellRef, pasteValue: pasteValue, removeOverride: removeOverride, setEditing: setEditing, title: originalValue }, content));
};
export default EditableTextCell;
