import * as React from 'react';
import FilterItem from '../../models/FilterItem';
import FilterItemInput from './FilterItemInput';
import './FilterItemInput.css';
import useOption from 'components/customHooks';
const RemoteFilterItem = ({ disabled = false, label, onChange, path, value = new FilterItem({ exclude: true, values: [] }), }) => {
    const options = useOption(path);
    return (React.createElement(FilterItemInput, { disabled: disabled, label: label, onChange: onChange, options: options, value: value }));
};
export default RemoteFilterItem;
