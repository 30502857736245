class TrancheMeta {
    bookrunners;
    lei;
    pricingDate;
    ticker;
    status;
    tap;
    constructor(response) {
        this.bookrunners = response?.bookrunners;
        this.lei = response?.lei;
        this.pricingDate = response?.pricingDate !== undefined ? new Date(response.pricingDate) : undefined;
        this.ticker = response?.ticker;
        this.status = response?.status;
        this.tap = response?.tap;
    }
    toResponse() {
        return {
            bookrunners: this.bookrunners,
            lei: this.lei,
            pricingDate: this.pricingDate ? this.pricingDate.toISOString().substring(0, 10) : undefined,
            ticker: this.ticker,
            status: this.status,
            tap: this.tap
        };
    }
}
export default TrancheMeta;
