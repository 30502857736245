import TimeRange from '../TimeRange';
class EsmaQuery {
    textCriteria;
    constructor(response) {
        this.textCriteria = response?.textCriteria ?? [];
    }
    getCreationDate = () => {
        const textCriteria = this.textCriteria.find((t) => t.name === 'mrkt_trdng_rqst_date');
        return textCriteria !== undefined ? TimeRange.from(textCriteria.values) : undefined;
    };
    getCurrency = () => {
        const textCriteria = this.textCriteria.find((t) => t.name === 'gnr_notional_curr_code');
        return textCriteria ? textCriteria.values : [];
    };
    getMaturity = () => {
        const textCriteria = this.textCriteria.find((t) => t.name === 'bnd_maturity_date');
        return textCriteria ? TimeRange.from(textCriteria.values) : undefined;
    };
    update(textCriteria, value) {
        const index = this.textCriteria.findIndex((l) => l.name === textCriteria.name);
        this.textCriteria[index] = { name: textCriteria.name, values: value };
    }
    remove(textCriteria) {
        this.textCriteria = this.textCriteria.filter((l) => l.name !== textCriteria.name);
    }
}
export default EsmaQuery;
