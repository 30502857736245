import TimestampFilter from './TimestampFilter';
import EditableTextCell from './EditableTextCell';
import DateTimeCell from './DateTimeCell';
import print from '../cells/print';
import EditableDropdownCell from './EditableDropdownCell';
import DocumentationCell from './DocumentationCell';
import EditableDateCell from './EditableDateCell';
import TimestampFilterPlus from './TimestampFilterPlus';
import MaturityCell from './MaturityCell';
import EditableBooleanCell from './EditableBooleanCell';
import BookrunnersCell from './BookrunnersCell';
function labelValuesToMap(values) {
    const map = new Map();
    values.filter(v => 'value' in v).forEach((v) => {
        if ('value' in v && v.value) {
            map.set(v.value, v.label);
        }
    });
    return map;
}
;
const overviewColumns = (sectorOptions, esgOptions, statusOfNotesOptions, currencyOptions, typeOptions, statusOptions, regionOptions) => [
    {
        accessorFn: print.sector(labelValuesToMap(sectorOptions)),
        header: 'Sector',
        id: 'sector',
        meta: {
            canCombine: true, className: 'co-static', dropdown: { isMulti: false, options: sectorOptions },
        },
        size: 80,
    },
    {
        accessorFn: print.types(labelValuesToMap(typeOptions)),
        header: 'Types',
        id: 'types',
        meta: { canCombine: true, className: 'co-static', dropdown: { isMulti: true, options: typeOptions } },
        size: 240,
    },
    {
        accessorFn: print.regions(labelValuesToMap(regionOptions)),
        header: 'Regions',
        id: 'regions',
        meta: { canCombine: true, className: 'co-static', dropdown: { isMulti: true, options: regionOptions } },
        size: 100,
    },
    {
        accessorFn: (t) => t.transaction.company?.name,
        header: 'Company',
        id: 'company',
        meta: { canCombine: true, className: 'co-static' },
        size: 240,
    },
    {
        accessorFn: print.documentation(labelValuesToMap(esgOptions), 'esg'),
        cell: DocumentationCell,
        header: 'ESG',
        id: 'esg',
        meta: { dropdown: { options: esgOptions }, isEditable: true },
        size: 80,
    },
    {
        accessorFn: print.documentationArray(labelValuesToMap(statusOfNotesOptions), 'statusOfNotes'),
        cell: DocumentationCell,
        header: 'Rank/Type',
        id: 'statusOfNotes',
        meta: { dropdown: { isMulti: true, options: statusOfNotesOptions }, isEditable: true },
        size: 200,
    },
    {
        accessorFn: print.issuer,
        cell: EditableTextCell,
        enableColumnFilter: true,
        header: 'Issuer',
        id: 'issuer',
        meta: { canCombine: true, style: { textAlign: 'right', fontWeight: 'bold' }, isEditable: true },
        size: 400,
    },
    {
        accessorFn: print.ticker,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Ticker',
        id: 'ticker',
        meta: { isEditable: true },
        size: 120,
    },
    {
        accessorFn: print.ratings,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Ratings',
        meta: { isEditable: true },
        id: 'ratings',
    },
    {
        accessorFn: print.currency,
        cell: EditableTextCell,
        enableColumnFilter: true,
        header: 'Currency',
        id: 'currency',
        meta: {
            dropdown: { options: currencyOptions }, isEditable: true,
        },
        size: 100,
    },
    {
        accessorFn: print.amount,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Amount',
        id: 'amount',
        maxSize: 82,
        meta: { isEditable: true },
    },
    {
        accessorFn: print.coupon,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Coupon',
        id: 'coupon',
        size: 80,
        meta: { isEditable: true, numberFormat: { minimumFractionDigits: 2 } },
    },
    {
        accessorFn: print.maturity,
        cell: MaturityCell,
        enableColumnFilter: false,
        header: 'Maturity',
        id: 'maturity',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.tenor,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Tenor',
        id: 'tenor',
        meta: { isEditable: true },
        size: 48,
    },
    {
        accessorFn: print.guidance('initialPriceThoughts'),
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'IPT',
        id: 'initialPriceThoughts',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.guidance('guidance'),
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Guidance',
        id: 'guidance',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.pricing,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Spd at Issue',
        id: 'pricing',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.iptMove,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Move from IPT',
        id: 'iptMove',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.book,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Last Book Update',
        id: 'books',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.oversubscription,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Oversubscription level',
        id: 'oversubscription',
        meta: { isEditable: true, numberFormat: { maximumFractionDigits: 1 } },
    },
    {
        accessorFn: print.bookrunners,
        cell: BookrunnersCell,
        header: 'Leads',
        id: 'bookrunners',
        meta: { canCombine: true, isEditable: true, style: { textAlign: 'left' } },
        size: 720,
    },
    {
        accessorFn: print.isins,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'ISIN',
        id: 'isin',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.lei,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'LEI',
        id: 'lei',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.pricingDate,
        cell: EditableDateCell,
        enableColumnFilter: true,
        header: 'Pricing Date',
        id: 'pricingDate',
        meta: {
            isEditable: true, Panel: TimestampFilterPlus,
        },
    },
    {
        accessorFn: print.settlementDate,
        cell: EditableDateCell,
        enableColumnFilter: false,
        header: 'Settlement Date',
        id: 'settlementDate',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.status(labelValuesToMap(statusOptions)),
        cell: EditableDropdownCell,
        header: 'Status',
        id: 'status',
        meta: { dropdown: { isMulti: false, options: statusOptions }, isEditable: true },
        size: 80,
    },
    {
        accessorFn: print.timing('booksSubject'),
        enableColumnFilter: false,
        header: 'Books Subject',
        id: 'booksSubject',
    },
    {
        accessorFn: print.timing('booksClose'),
        enableColumnFilter: false,
        header: 'Books Close',
        id: 'booksClose',
    },
    {
        accessorFn: print.timing('hedgeDeadline'),
        enableColumnFilter: false,
        header: 'Hedge Deadline',
        id: 'hedgeDeadline',
    },
    {
        accessorFn: print.timing('timeOfExecution'),
        enableColumnFilter: false,
        header: 'Time of Execution',
        id: 'timeOfExecution',
    },
    {
        accessorFn: print.timing('freeToTrade'),
        enableColumnFilter: false,
        header: 'Free to Trade',
        id: 'freeToTrade',
    },
    {
        accessorFn: print.interestBasis,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Interest Basis',
        id: 'interestBasis',
        meta: { isEditable: true },
    },
    {
        accessorFn: print.yield,
        cell: EditableTextCell,
        header: 'Yield',
        id: 'yield',
        maxSize: 200,
        minSize: 200,
        size: 200,
        meta: { isEditable: true },
    },
    {
        accessorFn: print.tap,
        cell: EditableBooleanCell,
        enableColumnFilter: false,
        header: 'Tap',
        id: 'tap',
        meta: { isEditable: true },
        size: 50,
    },
    {
        accessorFn: print.spread,
        cell: EditableTextCell,
        enableColumnFilter: false,
        header: 'Spread',
        id: 'spread',
        meta: { isEditable: true, numberFormat: { signDisplay: 'always' } },
    },
    {
        accessorFn: (t) => t.transaction.firstUpdate,
        cell: DateTimeCell,
        header: 'Announced',
        id: 'announcementDate',
        meta: { className: 'co-static' },
    },
    {
        accessorFn: (t) => t.transaction.lastUpdate,
        cell: DateTimeCell,
        header: 'Last Update',
        id: 'lastUpdate',
        meta: {
            className: 'co-static', Panel: TimestampFilter,
        },
    },
    {
        accessorFn: (t) => t.transaction.id,
        header: 'Transaction Id',
        id: 'transactionId',
    },
    {
        accessorFn: (t) => t.trancheIndex,
        header: 'Tranche Index',
        id: 'trancheIndex',
    }
];
export default overviewColumns;
