import * as React from 'react';
import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import PartyUnknowns from './PartyUnknowns';
import PartyForm from './PartyForm';
const PartyTable = ({ deleteFn, parties, hasError, isLoading, removeName, save, saveName, }) => {
    const [showUnknowns, setShowUnknowns] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const [party, setParty] = React.useState();
    const edit = React.useCallback((context) => {
        setParty(context.row.original);
        setShowForm(true);
    }, []);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit' },
                    { callback: deleteFn, label: 'Delete' },
                ],
            },
            size: 20,
        },
        {
            accessorKey: 'name',
            header: 'Party',
        },
        {
            accessorKey: 'shortName',
            header: 'Short Name',
        },
        {
            accessorKey: 'podId',
            header: 'Pod ID',
        },
        {
            accessorFn: (party) => party.names.join(' // '),
            header: 'Names',
            id: 'names',
            size: 800,
        },
    ], [deleteFn, edit]);
    const onClose = React.useCallback(() => {
        setShowUnknowns(false);
    }, []);
    const onClickUnknowns = React.useCallback(() => {
        setShowUnknowns(true);
    }, []);
    const onCloseForm = React.useCallback(() => {
        setShowForm(false);
    }, []);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Parties")),
        showUnknowns ? React.createElement(PartyUnknowns, { buttonLoading: isLoading, onClose: onClose, removeName: removeName, saveName: saveName, parties: parties }) : undefined,
        showForm ? React.createElement(PartyForm, { onClose: onCloseForm, party: party, save: save }) : undefined,
        React.createElement(CustomTable, { actionElements: [
                (React.createElement(Button, { disabled: hasError, key: "unknown-button", onClick: onClickUnknowns, size: "small", style: { marginLeft: '0.5rem', marginTop: '0.25rem' } }, "View Unknowns"))
            ], columns: columns, data: parties, emptyMessage: "You have no parties yet", errorMessage: "Error downloading parties", hasError: hasError, isLoading: isLoading, name: "party" })));
};
export default PartyTable;
