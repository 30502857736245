import * as React from 'react';
import { Card, Dropdown, } from '@symphony-ui/uitoolkit-components';
import ConstantViewer from './ConstantViewer';
import './Constants.css';
import useOption from 'components/customHooks';
const Constants = () => {
    const [field, setField] = React.useState(localStorage.getItem('field'));
    const fieldoptions = useOption('/syndicate/constant/fieldoptions');
    const changeField = React.useCallback((event) => {
        setField(event.target.value.value);
        localStorage.setItem('field', event.target.value.value);
    }, []);
    return (React.createElement(Card, { style: { display: 'flex', flexDirection: 'column', padding: '1rem' } },
        React.createElement(Dropdown, { label: "Field", onChange: changeField, options: fieldoptions, value: fieldoptions.find((o) => 'value' in o && o.value === field) }),
        field !== null ? React.createElement(ConstantViewer, { field: field }) : null));
};
export default Constants;
