import * as React from 'react';
import { getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import { useInfiniteQuery, useQueryClient, } from '@tanstack/react-query';
import { Loader, Typography } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import RenderingContext from 'contexts/RenderingContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import DraggableColumnHeader from './DraggableColumnHeader';
import ExportButton from './buttons/ExportButton';
import SettingsButton from './buttons/SettingsButton';
import { printBoolean, printDate } from './dateFormat';
import TableContext from './TableContext';
import createDataRequest from './infinite-data-request';
import TableStateClass from './TableStateClass';
import { TableFormattingDefault } from './TableFormatSettingsModal';
import './CustomTable.css';
import RowRender from './RowRender';
const InfiniteTable = function InfiniteTableElement({ columns, dataTransform, emptyMessage, errorMessage, fetchSize, meta, queryName, name, urlPage, urlTotal, setLoading = undefined, subComponent, actionElements = [], }) {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { setTableUpdateFn, setResetRowExpansion, setResetRowSelection, setSelection, } = React.useContext(TableContext);
    const tableContainerRef = React.useRef(null);
    const [tableState, setTableState] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        if (stored !== null) {
            const s = JSON.parse(stored);
            if ('state' in s) {
                return new TableStateClass(s.state);
            }
            localStorage.removeItem(`${name}-table-settings`);
        }
        return TableStateClass.DEFAULT;
    });
    const [scroll, setScroll] = React.useState(() => {
        const stored = sessionStorage.getItem(`${name}-table-scroll`);
        console.log("Setting table scroll initially to: %o", stored);
        return stored !== null ? JSON.parse(stored) : { x: 0, y: 0 };
    });
    const [initialScroll, setInitialScroll] = React.useState(scroll);
    const [formatting, setFormatting] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).formatting : TableFormattingDefault;
    });
    const [settingsMeta, setSettingsMeta] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        if (stored === null) {
            return undefined;
        }
        const { id, createdBy, createdDate, modifiedBy, modifiedDate, permissions, } = JSON.parse(stored);
        return {
            id, createdBy, createdDate, modifiedBy, modifiedDate, permissions,
        };
    });
    const [settingsName, setSettingsName] = React.useState(() => {
        const stored = localStorage.getItem(`${name}-table-settings`);
        return stored !== null ? JSON.parse(stored).name : undefined;
    });
    const [totalDBRowCount, setTotalDBRowCount] = React.useState(-1);
    const [hasError, setError] = React.useState(false);
    const [isTotalLoading, setTotalLoading] = React.useState(false);
    const rowHeight = 34;
    const rowMargin = 3;
    /**
     * This is the
     */
    const initialPageParam = React.useMemo(() => {
        const t = Math.round(initialScroll.y / (rowHeight + rowMargin) / fetchSize);
        console.log("INITIAL PAGE PARAMETER: %o", t);
        return t;
    }, [fetchSize, initialScroll.y]);
    const [renderValue, setRenderValue] = React.useState({
        dateFormat: formatting.dateFormat,
        printBoolean: (flag) => printBoolean(flag, formatting.booleanFormat),
        printDate: (date, precision) => printDate(date, formatting.dateFormat, precision),
        printNumber: (value) => Intl.NumberFormat().format(value),
    });
    /** SCROLL DETAILS START */
    const bodyRef = React.useRef(null);
    const headRef = React.useRef(null);
    React.useEffect(() => {
        // first stringify and then save, as otherwise the text becomes recursive (seems like a bug)
        const settings = {
            state: tableState,
            id: settingsMeta?.id,
            createdBy: settingsMeta?.createdBy,
            createdDate: settingsMeta?.createdDate,
            modifiedBy: settingsMeta?.modifiedBy,
            modifiedDate: settingsMeta?.modifiedDate,
            permissions: settingsMeta?.permissions,
            name: settingsName,
            formatting,
            table: name,
        };
        const stringified = JSON.stringify(settings);
        localStorage.setItem(`${name}-table-settings`, stringified);
    }, [formatting, name, settingsMeta, settingsName, scroll, tableState]);
    const queryClient = useQueryClient();
    const fetchData = React.useCallback(async (params) => {
        const request = createDataRequest(urlPage, params);
        try {
            if (setLoading !== undefined) {
                setLoading(true);
            }
            const body = await apiFetchJson(request, params.signal);
            return dataTransform !== undefined ? dataTransform(body) : body.map((u) => u);
        }
        catch (error) {
            sendFetchError('Unable to download data for table', error, request);
            if (error instanceof Error && error.name !== 'AbortError') {
                setError(true);
            }
            throw error;
        }
        finally {
            if (setLoading !== undefined) {
                setLoading(false);
            }
        }
    }, [apiFetchJson, dataTransform, setLoading, urlPage]);
    React.useEffect(() => {
        // first stringify and then save, as otherwise the text becomes recursive (seems like a bug)
        const settings = {
            state: tableState,
            id: settingsMeta?.id,
            createdBy: settingsMeta?.createdBy,
            createdDate: settingsMeta?.createdDate,
            modifiedBy: settingsMeta?.modifiedBy,
            modifiedDate: settingsMeta?.modifiedDate,
            permissions: settingsMeta?.permissions,
            name: settingsName,
            formatting,
            table: name,
        };
        const stringified = JSON.stringify(settings);
        localStorage.setItem(`${name}-table-settings`, stringified);
    }, [formatting, name, settingsMeta, settingsName, tableState]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const url = new ApiServerURL(urlTotal);
            if (tableState.columnFilters.length > 0) {
                url.searchParams.append('filterId', tableState.columnFilters.map((s) => s.id).join(','));
                url.searchParams.append('filterValue', tableState.columnFilters.map((f) => (Array.isArray(f.value) ? f.value.join('|') : f.value)).join(','));
            }
            const request = new ServerRequest(url);
            try {
                setTotalLoading(true);
                const body = await apiFetchJson(request, abortController.signal);
                setTotalDBRowCount(body);
            }
            catch (error) {
                sendFetchError('Unable to download options for filter', error, request);
            }
            finally {
                setTotalLoading(false);
            }
        })();
        return () => { abortController.abort(); };
    }, [tableState.columnFilters, apiFetchJson, urlTotal]);
    const queryKey = React.useMemo(() => [queryName ?? name, tableState.sorting, tableState.columnFilters, initialScroll.y], [name, queryName, tableState.sorting, tableState.columnFilters, initialScroll.y]);
    React.useEffect(() => {
        if (setTableUpdateFn !== undefined) {
            const myTable = (queryUpdateFn) => queryClient.setQueryData(queryKey, queryUpdateFn);
            setTableUpdateFn(() => myTable);
        }
    }, [queryClient, queryKey, setTableUpdateFn]);
    // react-query has an useInfiniteQuery hook just for this situation!
    const { data, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, isFetchingNextPage, isFetchingPreviousPage, isLoading, } = useInfiniteQuery({
        queryKey, // adding sorting state as key causes table to reset and fetch from new beginning upon sort
        queryFn: ({ pageParam, signal }) => (async () => {
            const start = pageParam * fetchSize;
            return fetchData({
                start, fetchSize, sorting: tableState.sorting, columnFilters: tableState.columnFilters, signal,
            });
        })(),
        getNextPageParam: (_lastGroup, groups, lastPageParam) => {
            if (lastPageParam === Math.floor((totalDBRowCount - 1) / fetchSize)) {
                return undefined;
            }
            return lastPageParam + 1;
        },
        getPreviousPageParam: (_lastGroup, groups, lastPageParam) => {
            if (lastPageParam === 0) {
                return undefined;
            }
            return lastPageParam - 1;
        },
        initialPageParam,
    });
    // we must flatten the array of arrays from the useInfiniteQuery hook
    const flatData = React.useMemo(() => data?.pages.filter((p) => p !== null).flatMap((page) => page) ?? [], [data]);
    React.useEffect(() => {
        if (setSelection !== undefined) {
            const selectedData = Object.keys(tableState.rowSelection)
                .map((index) => {
                const indexNumber = Number.parseInt(index, 10);
                return flatData[indexNumber];
            });
            setSelection(selectedData);
        }
    }, [flatData, tableState.rowSelection, setSelection]);
    const table = useReactTable({
        columnResizeMode: 'onChange',
        columns,
        data: flatData,
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getRowCanExpand: () => (subComponent !== undefined),
        getSortedRowModel: getSortedRowModel(),
        manualFiltering: true,
        manualSorting: true,
        meta,
        onStateChange: setTableState,
        state: tableState,
    });
    const focusInEvent = React.useCallback((event) => {
        const eventTarget = event.target;
        if (eventTarget.tagName === 'DIV' && eventTarget.classList.contains('co-editable')) {
            document.querySelectorAll('.co-active-parent').forEach((e) => {
                e.classList.remove('co-active-parent');
            });
            document.querySelectorAll('.co-active').forEach((e) => {
                e.classList.remove('co-active');
            });
            const parent = eventTarget.parentElement;
            if (parent !== null) {
                parent.classList.add('co-active-parent');
            }
            eventTarget.classList.add('co-active');
            eventTarget.setAttribute('tabIndex', '1');
            eventTarget.focus();
        }
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        window.addEventListener('focusin', focusInEvent, { signal: abortController.signal });
        return () => { abortController.abort(); };
    }, [focusInEvent]);
    // React.useLayoutEffect(() => {
    //   document.querySelectorAll('td').forEach((elem: HTMLTableCellElement) => {
    //     if (elem.offsetWidth < elem.scrollWidth && elem.textContent !== null) {
    //       elem.setAttribute('title', elem.textContent);
    //     }
    //   });
    // }, [isLoading]);
    /**
    * The values of the row selection can be changed programmatically, using React Table's
    * table.resetRowSelection. However, when the value of the checkbox is changed programatically,
    * the tick does NOT disappear. This is because the row doesn't change - only the result of
    * row.getIsSelected(), which is stored in separate variables in the background.
    * As the tick only disappears when clicking, we mimic the click for each row that is selected.
    */
    React.useEffect(() => {
        if (setResetRowSelection !== undefined) {
            setResetRowSelection(() => () => {
                if (bodyRef.current !== null) {
                    const rowElements = bodyRef.current.children;
                    Array.from(rowElements).forEach(row => {
                        Array.from(row.getElementsByClassName('tk-checkbox__input')).forEach((checkbox) => {
                            const inputElement = checkbox;
                            if (inputElement.checked) {
                                inputElement.click();
                            }
                        });
                    });
                    /*
                    * We reset it here too to ensure boxes that are not rendered are correctly toggeled too.
                    */
                    table.getSelectedRowModel().rows.forEach(r => { r.toggleSelected(); });
                }
            });
        }
    }, [setResetRowSelection, table]);
    /**
    * Set the function to reset the row expansion. Either specific rows are collapsed, or if an
    * empty string is used as parameter, then all expanded rows will be collapsed.
    */
    React.useEffect(() => {
        if (setResetRowExpansion !== undefined) {
            setResetRowExpansion(() => (items) => {
                table.getRowModel().rows.forEach((row) => {
                    if (items.length === 0 && row.getIsExpanded()) {
                        row.toggleExpanded(false);
                    }
                    else if (items.some((item) => item === row.original)) {
                        row.toggleExpanded(false);
                    }
                });
            });
        }
    }, [setResetRowExpansion, table]);
    // const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLTableRowElement>({
    //   count: totalDBRowCount,
    //   getScrollElement: () => tableContainerRef.current,
    //   getItemKey: (index) => index - start * fetchSize,
    //   estimateSize: () => rowHeight + rowMargin,
    //   initialOffset: 0,
    //   paddingStart: 0,
    //   scrollPaddingStart: 0,
    //   overscan: table.getRowModel().rows.length,
    // });
    React.useEffect(() => {
        if (totalDBRowCount > 0) {
            const body = tableContainerRef.current;
            if (body !== null) {
                const stored = sessionStorage.getItem(`${name}-table-scroll`);
                if (stored !== null) {
                    const firstScroll = JSON.parse(stored);
                    console.log("SCROLLING AGIN HERE???");
                    body.scrollTo({ left: firstScroll.x, top: firstScroll.y });
                }
            }
        }
    }, [name, totalDBRowCount]);
    const getParentNode = React.useCallback((element, tagName) => {
        if (element.tagName === tagName.toUpperCase()) {
            return element;
        }
        let parent = element;
        while (parent.parentElement !== null) {
            parent = parent.parentElement;
            if (parent.tagName === tagName.toUpperCase()) {
                return parent;
            }
        }
        return null;
    }, []);
    const keyDownEvent = React.useCallback((event) => {
        const { key } = event;
        if (document.activeElement !== null && (key === 'ArrowUp' || key === 'ArrowDown' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter')) {
            const tableBody = bodyRef.current;
            if (tableBody !== null && !(document.activeElement.tagName === 'INPUT')) {
                event.preventDefault();
                let editable = document.activeElement;
                while (editable !== null && !editable.classList.contains('co-editable')) {
                    editable = editable.parentElement;
                }
                if (editable !== null) {
                    if (key === 'ArrowLeft') {
                        let newCell = editable.previousElementSibling;
                        while (newCell !== null && !newCell.classList.contains('co-editable')) {
                            newCell = newCell.previousElementSibling;
                        }
                        if (newCell !== null) {
                            newCell.focus();
                        }
                    }
                    else if (key === 'ArrowRight') {
                        let newCell = editable.nextElementSibling;
                        while (newCell !== null && !newCell.classList.contains('co-editable')) {
                            newCell = newCell.nextElementSibling;
                        }
                        if (newCell !== null) {
                            newCell.focus();
                        }
                    }
                    else if (key === 'ArrowDown' || key === 'ArrowUp') {
                        const row = getParentNode(editable, 'TR');
                        if (row !== null) {
                            let i = 0;
                            while (row.children.item(i) !== editable) {
                                i += 1;
                            }
                            if (key === 'ArrowDown') {
                                const nextRow = row.nextElementSibling;
                                if (nextRow !== null) {
                                    const ithChild = nextRow.children.item(i);
                                    if (ithChild !== null) {
                                        ithChild.focus();
                                    }
                                }
                            }
                            if (key === 'ArrowUp') {
                                const previousRow = row.previousElementSibling;
                                if (previousRow !== null) {
                                    const ithChild = previousRow.children.item(i);
                                    if (ithChild !== null) {
                                        ithChild.focus();
                                    }
                                }
                            }
                        }
                    }
                    else { // i.e. key === 'Enter'
                        editable.focus();
                    }
                }
                else if (document.activeElement.tagName === 'BODY') {
                    const firstEditable = tableBody.querySelector('.co-editable');
                    if (firstEditable !== null) {
                        firstEditable.focus();
                    }
                }
            }
        }
    }, [getParentNode]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const clickCell = (event) => {
            const eventTarget = event.target;
            const parent = getParentNode(eventTarget, 'td');
            document.querySelectorAll('.co-active').forEach((element) => { element.classList.remove('co-active'); });
            if (parent?.classList.contains('co-edit')) {
                parent.classList.add('co-active');
            }
        };
        document.addEventListener('click', clickCell, { signal: abortController.signal });
        return () => { abortController.abort(); };
    }, [getParentNode]);
    React.useEffect(() => {
        const abortController = new AbortController();
        document.addEventListener('keydown', keyDownEvent, { signal: abortController.signal });
        return () => { abortController.abort(); };
    }, [keyDownEvent]);
    const firstIndex = React.useMemo(() => {
        return (data?.pageParams[0] ?? 0) * fetchSize;
    }, [data?.pageParams, fetchSize]);
    const lastIndex = React.useMemo(() => {
        return firstIndex + flatData.length;
    }, [firstIndex, flatData.length]);
    const onScroll = React.useCallback((event) => {
        const { currentTarget } = event;
        const scrolledTo = { x: currentTarget.scrollLeft, y: currentTarget.scrollTop };
        sessionStorage.setItem(`${name}-table-scroll`, JSON.stringify(scrolledTo));
        if (Math.abs(scroll.y - scrolledTo.y) > 4 * fetchSize * (rowHeight + rowMargin)) {
            setInitialScroll(scrolledTo);
        }
        else {
            const itemScrolledTo = currentTarget.scrollTop / (rowHeight + rowMargin);
            if (lastIndex - itemScrolledTo < fetchSize && hasNextPage && !isFetchingNextPage) {
                (async () => fetchNextPage())();
            }
            if (itemScrolledTo - firstIndex < fetchSize && hasPreviousPage && !isFetchingPreviousPage) {
                (async () => {
                    await fetchPreviousPage();
                    tableContainerRef.current?.scrollBy({ top: fetchSize * (rowMargin + rowHeight) });
                })();
            }
        }
        setScroll(scrolledTo);
    }, [
        fetchNextPage,
        fetchPreviousPage,
        fetchSize,
        firstIndex,
        hasNextPage,
        hasPreviousPage,
        isFetchingNextPage,
        isFetchingPreviousPage,
        lastIndex,
        name,
        scroll,
    ]);
    React.useEffect(() => {
        const loc = (new Intl.NumberFormat()).resolvedOptions().locale;
        setRenderValue({
            dateFormat: formatting.dateFormat,
            printBoolean: (flag) => printBoolean(flag, formatting.booleanFormat),
            printDate: (date, precision) => printDate(date, formatting.dateFormat, precision),
            printNumber: (value, options) => Intl.NumberFormat(loc, options).format(value),
        });
    }, [formatting.booleanFormat, formatting.dateFormat]);
    const hasData = React.useMemo(() => !isLoading && !isTotalLoading && !hasError && totalDBRowCount !== 0, [isLoading, isTotalLoading, hasError, totalDBRowCount]);
    // const topRowHeight = rowVirtualizer.getVirtualItems()[0]?.start.toString() + "px";
    // const bottomRowHeight = (totalDBRowCount * (rowHeight + rowMargin) - (rowVirtualizer.getVirtualItems()[rowVirtualizer.getVirtualItems().length - 1]?.end ?? 0)).toString() + "px";
    const topRowHeight = React.useMemo(() => {
        return (firstIndex * (rowHeight + rowMargin)).toString() + "px";
    }, [firstIndex, rowHeight, rowMargin]);
    const bottomRowHeight = React.useMemo(() => {
        return ((totalDBRowCount - lastIndex) * (rowHeight + rowMargin)).toString() + "px";
    }, [lastIndex, rowHeight, rowMargin, totalDBRowCount]);
    const numberOfColumns = table.getRowModel().rows.length > 0 ? table.getRowModel().rows[0].getVisibleCells().length : 999;
    return (React.createElement(RenderingContext.Provider, { value: renderValue },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%',
            } },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement("div", { style: { display: 'flex' } }, actionElements),
                React.createElement("div", { style: { alignItems: 'end', display: 'flex' } },
                    React.createElement(SettingsButton, { formatting: formatting, meta: settingsMeta, name: settingsName, setFormatting: setFormatting, setMeta: setSettingsMeta, setName: setSettingsName, table: table, tableState: tableState, tableName: name }),
                    React.createElement(ExportButton, { exportHeaders: formatting.exportHeaders, groupData: formatting.groupData, table: table }))),
            React.createElement("div", { className: "co-table-container tk-theme-condensed", onScroll: onScroll, ref: tableContainerRef },
                React.createElement("table", { className: `co-table ${meta?.className ?? ''} `, role: "grid", style: { height: isLoading || hasError ? '100%' : '' } },
                    React.createElement("thead", { ref: headRef }, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { className: "co-header-row", key: headerGroup.id }, headerGroup.headers.map((header) => (React.createElement(DraggableColumnHeader, { header: header, isNumber: typeof table.getRowModel().flatRows[0]?.getValue(header.column.id) === 'number', key: header.id, table: table }))))))),
                    React.createElement("tbody", { ref: bodyRef, style: { position: 'relative' } }, hasData
                        ?
                            React.createElement(React.Fragment, null,
                                React.createElement("tr", null,
                                    React.createElement("td", { "aria-label": "cell", colSpan: numberOfColumns, style: { height: topRowHeight } })),
                                table.getRowModel().rows.map(row => (React.createElement(RowRender, { key: row.index, meta: meta, row: row, subcomponent: row.getIsExpanded() && typeof subComponent === 'function' ? subComponent(row, table) : undefined, style: { height: `${rowHeight.toString()}px` } }))),
                                React.createElement("tr", null,
                                    React.createElement("td", { "aria-label": "cell", colSpan: numberOfColumns, style: { height: bottomRowHeight } })))
                        // (
                        // <>
                        //   {rowVirtualizer.getVirtualItems().length > 0 ? (
                        //     <tr>
                        //       <td aria-label="cell" colSpan={numberOfColumns} style={{ height: topRowHeight }} />
                        //     </tr>
                        //   )
                        //     : null}
                        //   {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        //     const keyNumber: number = virtualRow.key as number;
                        //     if (keyNumber >= 0 && table.getRowModel().rows.length > keyNumber) {
                        //       const row = table.getRowModel().rows[keyNumber]
                        //       return (
                        //         <RowRender
                        //           key={row.index}
                        //           meta={meta}
                        //           row={row}
                        //           subcomponent={row.getIsExpanded() && typeof subComponent === 'function' ? subComponent(row, table) : undefined}
                        //           style={{ height: `${rowHeight.toString()}px` }}
                        //         />
                        //       )
                        //     }
                        //     return <tr className="co-row co-row-load" key={virtualRow.index}><td colSpan={numberOfColumns}>LOADING...</td></tr>;
                        //   })}
                        //   {rowVirtualizer.getVirtualItems().length > 0 ? (
                        //     <tr>
                        //       <td aria-label="cell" colSpan={numberOfColumns} style={{ height: bottomRowHeight }} />
                        //     </tr>
                        //   ) : null}
                        // </>
                        // )
                        : React.createElement("tr", null,
                            React.createElement("td", { colSpan: 10, style: { height: totalDBRowCount * (rowHeight + rowMargin) } })))),
                !hasData ?
                    React.createElement("div", { className: "co-loader-table" }, isLoading || isTotalLoading
                        ? React.createElement(Loader, { className: "co-center-page", variant: isLoading ? 'primary' : 'attention' })
                        : React.createElement(Typography, { type: "h1" }, hasError ? errorMessage : emptyMessage))
                    : undefined))));
};
export default InfiniteTable;
