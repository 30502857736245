import { flexRender } from '@tanstack/react-table';
import * as React from 'react';
const CellRender = function CellRenderElement({ cell, meta }) {
    if (cell.column.columnDef.meta?.isEditable) {
        return flexRender(cell.column.columnDef.cell, cell.getContext());
    }
    return (React.createElement("td", { className: `co-table-cell ${cell.column.columnDef.meta?.className ?? ''}${cell.column.columnDef.meta?.isEditable ? ' co-edit' : ''} `, onClick: cell.column.columnDef.meta?.isClickable && meta?.onClickFn !== undefined ?
            meta.onClickFn(cell.row.original) :
            (() => {
                //
            }), role: "gridcell", style: cell.column.columnDef.meta?.style }, flexRender(cell.column.columnDef.cell, cell.getContext())));
};
export default CellRender;
