import * as React from 'react';
import { Card } from '@symphony-ui/uitoolkit-components';
import CustomTable from 'components/custom-table/CustomTable';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import ActionButton from 'components/custom-table/action/ActionButton';
import UserContext from 'contexts/UserContext';
import useOption from 'components/customHooks';
const ReportTable = ({ create, deleteFn, edit, hasError, isLoading, reports, }) => {
    const { checkEditable } = React.useContext(UserContext);
    const options = useOption('/nit/search/options');
    const actionCell = React.useCallback((context) => {
        const isEditable = checkEditable(context.row.original);
        const actionsMenu = [
            { callback: edit, label: isEditable ? 'Edit' : 'View' },
            { callback: deleteFn, label: 'Delete' },
        ];
        return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
    }, [checkEditable, deleteFn, edit]);
    const columns = React.useMemo(() => [
        {
            cell: actionCell,
            id: 'actions',
        },
        {
            accessorKey: 'name',
            header: 'Name',
            tooltip: 'The name you gave to the Report.',
        },
        {
            accessorKey: 'source',
            header: 'Source',
        }, {
            accessorFn: (report) => {
                return options.flatMap((o) => ('options' in o ? o.options : [])).find((o) => o.value === `${report.source}|${report.searchId}`)?.label ?? 'N/A';
            },
            header: 'Search',
            id: 'search',
        },
        {
            accessorFn: (report) => {
                const { frequency } = report;
                if (frequency) {
                    return frequency.value && frequency.type
                        ? `Every ${frequency.value} ${frequency.type.toLowerCase()}`
                        : `Daily at ${frequency.time}`;
                }
                return '';
            },
            header: 'Frequency',
            id: 'frequency',
        },
    ], [actionCell, options]);
    return (React.createElement(Card, null,
        React.createElement(CardHeaderTable, { create: create, disabled: hasError, title: "Reports" }),
        React.createElement(CustomTable, { columns: columns, data: reports, emptyMessage: "You have no reports yet. Please create a new one.", errorMessage: "Unable to download reports. Please check connection.", hasError: hasError, isLoading: isLoading, name: "report" })));
};
export default ReportTable;
