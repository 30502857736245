import { BannerType, Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import createDataRequest from 'components/custom-table/infinite-data-request';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError, sendMessage } from 'utils/messageUtils';
import TrancheWrapper from '../../models/TrancheWrapper';
import transformDataForInterprice from './spreadsheet-create';
import CsvTable from './CsvTable';
import useMap from 'components/customHooksMap';
const Interprice = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const fileInputRef = React.useRef(null);
    const [disabledSend, setDisabledSend] = React.useState(true);
    const [rawData, setRawData] = React.useState([]);
    const esgMap = useMap('/syndicate/constant/ESG/map');
    const onChangeFiles = React.useCallback(() => {
        if (fileInputRef.current?.files != null) {
            for (let i = 0; i < fileInputRef.current.files.length; i += 1) {
                const file = fileInputRef.current.files.item(i);
                if (file !== null && file.type !== 'text/csv') {
                    sendMessage(`Please upload an .csv file rather than a ${file.type}`, BannerType.ERROR);
                    fileInputRef.current.value = '';
                }
            }
        }
        setDisabledSend(fileInputRef.current?.files == null || fileInputRef.current.files.length === 0);
    }, []);
    const csvContent = React.useMemo(() => transformDataForInterprice(rawData, esgMap), [esgMap, rawData]);
    const onClickOpen = React.useCallback(() => {
        const lines = [];
        csvContent.forEach((l) => {
            const lineContent = l.join(',');
            lines.push(lineContent);
        });
        const content = lines.join('\n');
        const csvFile = new Blob([content], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = `${(new Date()).toISOString().substring(0, 10)} Interprice`;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }, [csvContent]);
    const onClickAutoSend = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/subscriptions/interprice-auto', { body: JSON.stringify(csvContent), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
        (async () => {
            try {
                await apiFetchNoContent(request);
                sendMessage('Automated Email sent to Interprice', BannerType.SUCCESS);
            }
            catch (error) {
                sendFetchError('Unable to send to Interprice automatically', error, request);
                //
            }
        })();
    }, [csvContent, apiFetchNoContent]);
    const onClickAutoTest = React.useCallback(() => {
        const request = new ServerRequest('/syndicate/subscriptions/interprice-test', { body: JSON.stringify(csvContent), headers: { 'Content-Type': 'application/json' }, method: 'POST' });
        (async () => {
            try {
                await apiFetchNoContent(request);
                sendMessage('Automated Test Email (not) sent to Interprice', BannerType.SUCCESS);
            }
            catch (error) {
                sendFetchError('Unable to send to Interprice automatically', error, request);
            }
        })();
    }, [csvContent, apiFetchNoContent]);
    const onClickSend = React.useCallback(() => {
        if (fileInputRef.current?.files != null) {
            const formData = new FormData();
            formData.append('file', fileInputRef.current.files[0]);
            const request = new ServerRequest('/syndicate/subscriptions/interprice', { body: formData, method: 'POST' });
            (async () => {
                try {
                    await apiFetchNoContent(request);
                    sendMessage('Uploaded Email sent to Interprice', BannerType.SUCCESS);
                }
                catch (error) {
                    sendFetchError('Unable to send to Interprice', error, request);
                }
            })();
        }
        if (fileInputRef.current?.files != null) {
            for (let i = 0; i < fileInputRef.current.files.length; i += 1) {
                const file = fileInputRef.current.files.item(i);
                if (file !== null && file.type !== 'text/csv') {
                    sendMessage(`Please upload an .csv file rather than a ${file.type}`, BannerType.ERROR);
                    fileInputRef.current.value = '';
                }
            }
        }
    }, [apiFetchNoContent]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const filter = [{ id: 'sector', value: 'CORPORATE|HY' }, { id: 'pricingDate', value: 'T_0' }];
        const request = createDataRequest('/syndicate/overview/page', {
            start: 0, fetchSize: 200, columnFilters: filter, signal: abortController.signal,
        });
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const tranches = body.map((t) => new TrancheWrapper(t));
                setRawData(tranches);
            }
            catch (error) {
                sendFetchError('Unable to download data for CSV', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson, esgMap]);
    return (React.createElement(Card, { style: { display: 'flex', flexDirection: 'column', padding: '1rem' } },
        React.createElement("div", { style: {
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem',
            } },
            React.createElement(Typography, { type: "h1" }, "Send Daily Email to Interprice"),
            React.createElement("div", null,
                React.createElement(Button, { name: "creator", onClick: () => updateView('CREATOR'), size: "small" }, "EMAIL CREATOR"))),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' } },
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { onClick: onClickAutoSend, style: { marginRight: '1rem' } }, "SEND AUTO"),
                React.createElement(Button, { onClick: onClickAutoTest }, "SEND TEST")),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { onClick: onClickOpen, style: { marginRight: '1rem' } }, "VIEW CSV"),
                React.createElement("label", { htmlFor: "file-input", style: { display: 'flex', flexDirection: 'column' } },
                    "Manually upload csv file",
                    React.createElement("input", { id: "file-input", onChange: onChangeFiles, ref: fileInputRef, type: "file" })),
                React.createElement(Button, { disabled: disabledSend, onClick: onClickSend }, "SEND"))),
        React.createElement("div", { style: { display: 'flex', overflowX: 'scroll' } },
            React.createElement(CsvTable, { data: csvContent }))));
};
export default Interprice;
