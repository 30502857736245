import { fromSource, Source } from '../../sources/sources';
import EuroclearFilter from '../../models/filter/EuroclearFilter';
import EuroclearSecurity from '../../models/security/EuroclearSecurity';
import EuroclearCompany from '../../models/company/EuroclearCompany';
import AnnaSecurity from '../../models/security/AnnaSecurity';
import AnnaCompany from '../../models/company/AnnaCompany';
import AnnaFilter from '../../models/filter/AnnaFilter';
import EsmaFilter from '../../models/filter/EsmaFilter';
import EsmaCompany from '../../models/company/EsmaCompany';
import EsmaSecurity from '../../models/security/EsmaSecurity';
import SecSecurity from '../../models/security/SecSecurity';
import SecCompany from '../../models/company/SecCompany';
import SecFilter from '../../models/filter/SecFilter';
const resultsDownload = (body) => {
    const newSource = fromSource(body.source);
    let newSecurities;
    let newCompanies;
    let newFilter;
    switch (newSource) {
        case Source.ANNA:
            newCompanies = body.companies.map((c) => ({
                company: new AnnaCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body
                .securities.map((s) => AnnaSecurity.create(s, newCompanies));
            newFilter = new AnnaFilter(body.filter);
            break;
        case Source.ESMA:
            newCompanies = body.companies.map((c) => ({
                company: new EsmaCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body.securities.map((s) => EsmaSecurity.create(s, newCompanies));
            newFilter = new EsmaFilter(body.filter);
            break;
        case Source.EUROCLEAR:
            newCompanies = body.companies.map((c) => ({
                company: new EuroclearCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body
                .securities.map((s) => EuroclearSecurity.create(s, newCompanies));
            newFilter = new EuroclearFilter(body.filter);
            break;
        case Source.SEC:
            newCompanies = body.companies.map((c) => ({
                company: new SecCompany(c.company),
                tags: c.tags,
            }));
            newSecurities = body
                .securities.map((s) => SecSecurity.create(s, newCompanies));
            newFilter = new SecFilter(body.filter);
            break;
        default:
            throw new Error(`Unable to find source: ${body.source}`);
    }
    return {
        filter: newFilter,
        securities: newSecurities,
        source: newSource,
    };
};
export default resultsDownload;
