import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import CellRender from 'components/custom-table/CellRender';
const DraggableRow = function DraggableRowElement({ meta, row, reorderRow }) {
    const [, dropRef] = useDrop({
        accept: 'row',
        drop: (draggedRow) => { reorderRow(draggedRow.index, row.index); },
    });
    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: 'row',
    });
    return (React.createElement("tr", { ref: previewRef, style: { opacity: isDragging ? 0.5 : 1 } },
        React.createElement("td", { ref: dropRef, style: { textAlign: 'center' } },
            React.createElement("button", { ref: dragRef, type: "button" }, "\uD83D\uDFF0")),
        row.getVisibleCells().map((cell) => (React.createElement(CellRender, { cell: cell, key: cell.id, meta: meta })))));
};
export default DraggableRow;
