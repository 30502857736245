import * as React from 'react';
import { Button, Card, Typography, } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import CustomTable from 'components/custom-table/CustomTable';
import Company from '../../models/Company';
import CompanyFormModal from './CompanyFormModal';
import useOption from 'components/customHooks';
const CompanyTable = ({ deleteFn, hasError, isLoading, companies, save, }) => {
    const [showFormModal, setShowFormModal] = React.useState(false);
    const [company, setCompany] = React.useState();
    const optionsSector = useOption('/admin/options/sector');
    const optionsType = useOption('/admin/options/type');
    const edit = React.useCallback(({ row: { original: company } }) => {
        setCompany(company);
        setShowFormModal(true);
    }, []);
    const deleteCallback = React.useCallback(({ row: { original: company } }) => {
        (async () => deleteFn(company))();
    }, [deleteFn]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' },
                    { callback: deleteCallback, label: 'Delete', type: 'error' },
                ],
            },
            size: 20
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'lei',
            header: 'LEI',
        },
        {
            accessorFn: (company) => optionsSector.filter((t) => company.sectors.includes(t.value)).map((t) => t.label).sort().join(', '),
            header: 'Sectors',
            id: 'sectors',
        },
        {
            accessorFn: (company) => optionsType.filter((t) => company.types.includes(t.value)).map((t) => t.label).sort().join(', '),
            header: 'Types',
            id: 'types',
        },
    ], [deleteCallback, edit, optionsType, optionsSector]);
    const onCloseForm = React.useCallback(() => {
        setShowFormModal(false);
    }, []);
    const onClickCreate = React.useCallback(() => {
        setCompany(Company.create());
        setShowFormModal(true);
    }, []);
    const actionElements = React.useMemo(() => {
        return [
            React.createElement(Button, { disabled: hasError, onClick: onClickCreate, size: "small", style: { marginLeft: '0.5rem' } }, "CREATE")
        ];
    }, [onClickCreate, hasError]);
    return (React.createElement(Card, null,
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Companies")),
        showFormModal && company !== undefined ?
            React.createElement(CompanyFormModal, { onClose: onCloseForm, company: company, save: save }) : undefined,
        React.createElement(CustomTable, { actionElements: actionElements, columns: columns, data: companies, emptyMessage: "You have no companies yet", errorMessage: "Error downloading companies", hasError: hasError, isLoading: isLoading, name: "company" })));
};
export default CompanyTable;
