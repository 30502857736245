import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle, Typography, } from '@symphony-ui/uitoolkit-components';
const Metadata = ({ hideModal, value }) => {
    const onClose = () => {
        hideModal();
    };
    return (React.createElement(Modal, { className: "co-items", closeButton: true, onClose: onClose, parentNode: document.body, size: "small", show: true },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Metadata")),
        React.createElement(ModalBody, null,
            React.createElement("div", null,
                React.createElement(Typography, { type: "small" }, "Id"),
                React.createElement(Typography, { variant: "bold" }, value?.id)),
            React.createElement("div", null,
                React.createElement(Typography, { type: "small" }, "Created By"),
                React.createElement(Typography, { variant: "bold" }, value?.createdBy?.displayName),
                React.createElement(Typography, { variant: "bold" }, value?.createdBy?.company)),
            React.createElement("div", null,
                React.createElement(Typography, { type: "small" }, "Created"),
                React.createElement(Typography, { variant: "bold" }, value?.createdDate !== undefined ? new Date(value.createdDate).toLocaleString() : '')),
            value?.lastModifiedBy !== undefined ? (React.createElement("div", null,
                React.createElement(Typography, { type: "small" }, "Last Modified By"),
                React.createElement(Typography, { variant: "bold" }, value.lastModifiedBy.displayName),
                React.createElement(Typography, { variant: "bold" }, value.lastModifiedBy.company))) : '',
            value?.lastModifiedDate !== undefined ? (React.createElement("div", null,
                React.createElement(Typography, { type: "small" }, "Last Modified"),
                React.createElement(Typography, { variant: "bold" }, new Date(value.lastModifiedDate).toLocaleString()))) : '')));
};
export default Metadata;
