var _a;
class Ipo {
    exchange;
    id;
    constructor(response) {
        this.exchange = response.exchange;
        this.id = response.id;
    }
    #clone() {
        return new _a({
            id: this.id,
            exchange: this.exchange
        });
    }
    updateExchange(newExchange) {
        const clone = this.#clone();
        clone.exchange = newExchange;
        return clone;
    }
}
_a = Ipo;
export default Ipo;
