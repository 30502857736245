var _a;
class TermSheet {
    id;
    coupon;
    issueDate;
    issuer;
    currency;
    interestBasis;
    maturityDate;
    nominal;
    constructor(response) {
        this.id = response.id;
        this.coupon = response.coupon;
        this.issueDate = response.issueDate;
        this.issuer = response.issuer;
        this.currency = response.currency;
        this.interestBasis = response.interestBasis;
        this.maturityDate = response.maturityDate;
        this.nominal = response.nominal;
    }
    #clone() {
        return new _a({
            id: this.id,
            coupon: this.coupon,
            issueDate: this.issueDate,
            issuer: this.issuer,
            currency: this.currency,
            interestBasis: this.interestBasis,
            maturityDate: this.maturityDate,
            nominal: this.nominal
        });
    }
    update(name, value) {
        const clone = this.#clone();
        clone[name] = value;
        return clone;
    }
}
_a = TermSheet;
export default TermSheet;
