class IpoQuery {
    exchange;
    constructor(response) {
        this.exchange = response?.exchange;
    }
    getCreationDate = () => undefined;
    getCurrency = () => undefined;
    getMaturity = () => undefined;
}
export default IpoQuery;
