import * as React from 'react';
const ResultsFilter = function FilterElement({ filter, options, setFilter, }) {
    return React.createElement("p", null);
    // const onChangeInput = React.useCallback((name: keyof F) => (event: EventWithValue<FilterItem>) => {
    //   const { value } = event.target;
    //   const updatedData: F = filter?.clone();
    //   updatedData[name] = value;
    //   setFilter(updatedData);
    // }, [setFilter]);
    // return (
    //   <div id="filter-box" className="base-form">
    //     {filter?.tags !== undefined ? Object.entries(filter.tags).map(([propertyName, value]: [string, GenericFilter]) => (
    //       <FilterItemInput
    //         label={value.name ?? 'UNKNOWN???'}
    //         onChange={onChangeInput(propertyName)}
    //         options={options[propertyName]}
    //         value={value.tags}
    //       />
    //     )) : null}
    //   </div>
    // );
};
export default ResultsFilter;
