import * as React from 'react';
import { Button, Modal, ModalBody, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import useOption from 'components/customHooks';
const TypeModal = ({ data: { transactionId, title }, onClose }) => {
    const { apiFetchNoContent } = React.useContext(DownloadContext);
    const [value, setValue] = React.useState();
    const typeOptions = useOption('/syndicate/overview/types/options');
    const onClick = React.useCallback((event) => {
        setValue(event.currentTarget.name);
        const url = new ApiServerURL('/syndicate/transactions/update-type');
        url.searchParams.append('id', transactionId);
        url.searchParams.append('type', event.currentTarget.name);
        const request = new ServerRequest(url);
        (async () => {
            try {
                await apiFetchNoContent(request);
                onClose();
            }
            catch (error) {
                sendFetchError('Unable to update type', error, request);
                //
            }
        })();
    }, [apiFetchNoContent, transactionId, onClose]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "medium" },
        React.createElement(ModalTitle, null, title),
        React.createElement(ModalBody, null,
            React.createElement("div", { style: { alignItems: 'center', display: 'flex', flexDirection: 'column' } }, typeOptions.map((o) => (React.createElement(Button, { disabled: value !== undefined && ('value' in o && o.value !== value), key: o.label, loading: ('value' in o && o.value === value), name: 'value' in o ? o.value : '', onClick: onClick, style: { marginBottom: '0.5rem', width: '8rem' } }, o.label)))))));
};
export default TypeModal;
