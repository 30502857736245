var _a;
class AnnaCompany {
    id;
    nameLong;
    hq;
    reg;
    tags;
    constructor(response) {
        this.id = response.id;
        this.nameLong = response.nameLong;
        this.hq = response.hq;
        this.reg = response.reg;
        this.tags = response.tags;
    }
    static sortField = 'nameLong';
    static columns = [
        { accessorKey: 'lei', header: 'LEI' },
        { accessorKey: 'nameLong', header: 'Name' },
        { accessorFn: (c) => c.reg.address1, header: 'Reg Address 1' },
        { accessorFn: (c) => c.reg.address2, header: 'Reg Address 2' },
        { accessorFn: (c) => c.reg.city, header: 'Reg City' },
        { accessorFn: (c) => c.reg.country, header: 'Reg Country' },
        { accessorFn: (c) => c.reg.postalCode, header: 'Reg Postal Code' },
        { accessorFn: (c) => c.reg.state, header: 'Reg State' },
        { accessorFn: (c) => c.hq.address1, header: 'HQ Address 1' },
        { accessorFn: (c) => c.hq.address2, header: 'HQ Address 2' },
        { accessorFn: (c) => c.hq.city, header: 'HQ City' },
        { accessorFn: (c) => c.hq.country, header: 'HQ Country' },
        { accessorFn: (c) => c.hq.postalCode, header: 'HQ Postal Code' },
        { accessorFn: (c) => c.hq.state, header: 'HQ State' },
        { accessorKey: 'nameShort', header: 'Name (short)' },
    ];
    static getSort = (s0, s1) => s0.nameLong.localeCompare(s1.nameLong);
    #clone() {
        return new _a({
            id: this.id,
            nameLong: this.nameLong,
            hq: this.hq,
            reg: this.reg,
            tags: this.tags
        });
    }
    updateAddress(type, item, value) {
        const clone = this.#clone();
        clone[type][item] = value;
        return clone;
    }
    updateNameLond(value) {
        const clone = this.#clone();
        clone.nameLong = value;
        return clone;
    }
    updateTags(allCompanyTags) {
        const clone = this.#clone();
        clone.tags = allCompanyTags.find(t => t.companyId === this.id);
        return clone;
    }
}
_a = AnnaCompany;
export default AnnaCompany;
