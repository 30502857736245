import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
import ActionCell from 'components/custom-table/action/ActionCell';
import ConstantModal from './ConstantModal';
import { Button } from '@symphony-ui/uitoolkit-components';
import TableCheckbox from '../transactions/transaction-viewer/transaction-table/TableCheckbox';
import { FiniteTableContext } from 'components/custom-table/TableContext';
import { removeOption } from 'components/customHooks';
const ConstantValues = ({ constant, field, setConstant }) => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { selection, resetRowSelection, } = React.useContext(FiniteTableContext);
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isLoadingMerge, setLoadingMerge] = React.useState(false);
    const [values, setValues] = React.useState([]);
    const [editConstant, setEditConstant] = React.useState();
    const [showModal, setShowModal] = React.useState(false);
    React.useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        const url = new ApiServerURL('/syndicate/constant/all');
        url.searchParams.append('field', field);
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                setValues(body);
                setConstant(body.length > 0 ? body[0] : undefined);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError('Unable to download constants for ' + field, error, request);
                    setError(true);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => { abortController.abort(); };
    }, [apiFetchJson, field, setConstant]);
    const saveMerge = React.useCallback(async () => {
        if (resetRowSelection !== undefined && selection !== undefined && selection.length > 1) {
            const idSelections = selection.map((t) => t.id).filter(i => i !== undefined);
            const idSelection = idSelections.join(',');
            resetRowSelection();
            setLoadingMerge(true);
            const formData = new FormData();
            formData.append('ids', idSelection);
            const request = new ServerRequest('/syndicate/constant/merge', { body: formData, method: 'POST' });
            try {
                const responseConstant = await apiFetchJson(request);
                const clone = [...values].
                    filter(v => v.id === responseConstant.id || idSelections.every((i => v.id !== i))).
                    map(c => c.id === responseConstant.id ? responseConstant : c);
                setValues(clone);
                resetRowSelection();
                const fields = [...new Set(selection.map(s => s.field))];
                const promises = fields.map((f) => removeOption('/syndicate/constant/' + f + '/options'));
                await Promise.all(promises);
                /**
               * we remove the older ones. As there are now two transactions with the same 'oldestId',
               * we cannot select the ones to remove based on Id.
               */
                //        resetRowExpansion(selection.map((s) => s));
            }
            catch (error) {
                sendFetchError('Unable to merge transactions', error, request);
                //
            }
            finally {
                setLoadingMerge(false);
            }
        }
    }, [apiFetchJson, resetRowSelection, selection, values]);
    const onClickMerge = React.useCallback(() => {
        console.log("Selection: %o", selection);
        if (selection !== undefined && selection.length > 1) {
            (async () => await saveMerge())();
        }
    }, [saveMerge, selection]);
    const onClickAdd = React.useCallback(() => {
        setEditConstant({ field, names: [] });
        setShowModal(true);
    }, [field]);
    const meta = React.useMemo(() => ({
        onClickFn: (item) => () => { setConstant(item); },
        rowClassFn: (row) => row.original.id === constant?.id ? "co-constant-active" : "",
    }), [constant, setConstant]);
    const deleteCallback = React.useCallback(({ row }) => {
        const request = new ServerRequest('/syndicate/constant', { body: JSON.stringify(row.original), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });
        (async () => {
            try {
                await apiFetchNoContent(request);
                const clone = [...values].filter(v => v.id !== row.original.id);
                setValues(clone);
                const fields = [...new Set(clone.map(s => s.field))];
                const promises = fields.map((f) => removeOption('/syndicate/constant/' + f + '/options'));
                await Promise.all(promises);
            }
            catch (error) {
                sendFetchError("Unable to delete constant", error, request);
            }
        })();
    }, [apiFetchNoContent, values]);
    const edit = React.useCallback(({ row }) => {
        setEditConstant(row.original);
        setShowModal(true);
    }, []);
    const genericOptions = React.useMemo(() => values.map(v => ({ value: v.id ?? "NONE", label: v.displayName ?? v.names[0] })), [values]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: edit, label: 'Edit', type: 'info' },
                    { callback: deleteCallback, label: 'Delete', type: 'error' },
                ],
            },
            size: 28
        },
        {
            cell: (TableCheckbox), header: () => null, id: 'constantCheckbox', size: 28,
        },
        {
            accessorFn: c => c.displayName ?? c.names[0],
            header: 'Name',
            meta: { isClickable: true },
        },
        {
            accessorKey: 'displayName',
            header: 'Display name',
            meta: { isClickable: true },
        },
        {
            accessorFn: c => genericOptions.filter(o => 'value' in o && c.genericIds?.includes(o.value)).map(o => o.label).join(", "),
            header: "More Generic"
        }
    ], [deleteCallback, edit, genericOptions]);
    const updateTable = React.useCallback((updatedConstant) => {
        let clone;
        if (values.some(v => v.id === updatedConstant.id)) {
            clone = [...values].map(v => v.id === updatedConstant.id ? updatedConstant : v);
        }
        else {
            clone = [...values].concat(updatedConstant);
        }
        setValues(clone);
    }, [values]);
    const disabledMerge = React.useMemo(() => selection !== undefined && selection.length < 2, [selection]);
    const actionElements = React.useMemo(() => [
        React.createElement(Button, { key: "add", name: "add", onClick: onClickAdd, size: "small" }, "ADD"),
        React.createElement(Button, { disabled: disabledMerge, key: "merge", loading: isLoadingMerge, name: "merge", onClick: onClickMerge, size: "small" }, "MERGE")
    ], [onClickAdd, onClickMerge, disabledMerge, isLoadingMerge]);
    return (React.createElement(React.Fragment, null,
        showModal && editConstant !== undefined ?
            React.createElement(ConstantModal, { constant: editConstant, genericOptions: genericOptions, setConstant: setConstant, onClose: () => { setShowModal(false); }, updateTable: updateTable })
            : undefined,
        React.createElement(CustomTable, { actionElements: actionElements, columns: columns, data: values, emptyMessage: "Can't find any constants", errorMessage: "Error downloading constants", hasError: hasError, isLoading: isLoading, meta: meta, name: "constants" })));
};
export default ConstantValues;
