import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import PermissionGroup from './PermissionGroup';
import './PermissionModal.css';
import { defaultPermissions } from 'components/metadata/utils';
const PermissionsModal = ({ hideModal, onChange, value = defaultPermissions }) => {
    console.log("Value: %o", value);
    const setValue = React.useCallback((name) => (value2) => {
        const newPermissions = { ...value, [name]: value2 };
        onChange(newPermissions);
    }, [value, onChange]);
    const onClose = () => {
        hideModal();
    };
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, parentNode: document.body, show: true, size: "medium" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Permissions")),
        React.createElement(ModalBody, { className: "co-permissions-group" },
            React.createElement(PermissionGroup, { label: "User", value: value.user, setValue: setValue('user') }),
            React.createElement(PermissionGroup, { label: "Company", value: value.group, setValue: setValue('group') }),
            React.createElement(PermissionGroup, { label: "Other", value: value.other, setValue: setValue('other') }))));
};
export default PermissionsModal;
