import ActionButton from 'components/custom-table/action/ActionButton';
import * as React from 'react';
import AnnaCompany from '../../models/company/AnnaCompany';
import CompanyTag from '../../models/company/CompanyTag';
const companiesTableActionCell = (setActualCompanyTags, setCompany, source) => function func(context) {
    const companyTags = ({ row: { original } }) => {
        const newCompanyTags = CompanyTag.fromCompany(original, source);
        setActualCompanyTags(newCompanyTags);
    };
    const actionsMenu = [{
            callback: companyTags,
            label: 'Edit Company Tags',
        }];
    const addressTest = (address) => (address.address1.includes('��') || address.address2.includes('��') || address.city.includes('��') || address.country.includes('��') || address.postalCode.includes('��') || address.state.includes('��'));
    const c = context.row.original;
    if (c instanceof AnnaCompany) {
        const details = ({ row: { original: rowCompany } }) => {
            if (rowCompany instanceof AnnaCompany) {
                setCompany(rowCompany);
            }
        };
        if (c.nameLong.includes('��') || (c.reg && addressTest(c.reg)) || (c.hq && addressTest(c.hq))) {
            actionsMenu.push({
                callback: details,
                label: 'Correct Diacriticals',
            });
        }
    }
    return (React.createElement(ActionButton, { actionsMenu: actionsMenu, context: context }));
};
export default companiesTableActionCell;
