import { Dropdown } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import { getOptions } from 'utils/change';
import './DataViewerForm.scss';
import useOption from 'components/customHooks';
const CategoryPicker = ({ category, isLoading, setCategory, }) => {
    const categoryOptions = useOption('/syndicate/regex/paragraphs/categoryoptions');
    const onChangeValue = React.useCallback((event) => {
        const newValue = event.target.value;
        if ('value' in newValue) {
            localStorage.setItem('category', newValue.value);
            setCategory(newValue.value);
        }
    }, [setCategory]);
    return (React.createElement("div", { className: "co-category-picker" },
        React.createElement(Dropdown, { isDisabled: isLoading, label: "Select a Category", name: "value", onChange: onChangeValue, options: categoryOptions, value: getOptions(category, categoryOptions) })));
};
export default CategoryPicker;
