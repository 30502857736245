import * as React from 'react';
import ProgressError from './ProgressError';
const ProgressBar = ({ close, data: { count, done, errors, timestamp, title, total }, }) => {
    const estimatedTime = React.useMemo(() => {
        if (total <= 0 || count <= 0) {
            return `Total ${total.toString()} items. Calculating estimated time`;
        }
        const timestampInMillis = new Date(timestamp).getTime();
        const estimatedMillis = (Date.now() - timestampInMillis) * ((total - count) / count);
        let seconds = Math.round(estimatedMillis / 1000);
        let minutes = Math.round(seconds / 60);
        seconds %= 60;
        const hours = Math.round(minutes / 60);
        minutes %= 60;
        let value = `Estimated time: ${seconds.toString()} seconds`;
        if (minutes === 0 && hours === 0) {
            return value;
        }
        value = `Estimated time: ${minutes.toString()} minutes and ${value}`;
        if (hours === 0) {
            return value;
        }
        return `Estimated time: ${hours.toString()} hours, ${value}`;
    }, [count, timestamp, total]);
    const doneAlready = React.useMemo(() => {
        if (count <= 0) {
            return "";
        }
        return `Done ${done.toString()}/${count.toString()}. `;
    }, [count, done]);
    const onClick = React.useCallback(() => {
        close();
    }, [close]);
    const onKeyUp = React.useCallback((event) => {
        if (event.key === 'Enter' || event.key === 'escape') {
            close();
        }
    }, [close]);
    return (React.createElement("div", { className: "tk-banner tk-banner--info tk-banner--medium", style: { maxHeight: '10rem', overflowY: 'scroll' } },
        React.createElement("div", { className: "tk-banner__variant-icon" }),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column', width: '100%' } },
            React.createElement("label", { htmlFor: title }, `${title}, ${doneAlready}${estimatedTime}`),
            total > 0
                ? React.createElement("progress", { id: title, max: total, value: count, style: { width: '100%' } })
                : undefined,
            React.createElement("div", null, errors !== undefined ? errors.map((e) => (React.createElement(ProgressError, { error: e, key: e.detail }))) : null)),
        React.createElement("div", { "aria-label": "Close", className: "tk-banner__close", onClick: onClick, role: "button", tabIndex: 0, onKeyUp: onKeyUp })));
};
export default ProgressBar;
