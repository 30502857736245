import { Button, Card, Typography } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import * as React from 'react';
import ServerRequest from 'models/ServerRequest';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError } from 'utils/messageUtils';
const EmailStatsOverview = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { updateView } = React.useContext(NavigationContext);
    const [stats, setStats] = React.useState([]);
    const days = React.useMemo(() => (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']), []);
    const dayofweek = React.useCallback((d) => ((d.getDay() + 6) % 7), []);
    const firstDateOfFirstWeekPreceding = React.useCallback((d) => {
        const dayOfWeek = dayofweek(d);
        const dayToCheck = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (6 - dayOfWeek));
        const onejan = new Date(dayToCheck.getFullYear(), 0, 1);
        const dayOfWeekOneJan = dayofweek(onejan);
        return new Date(onejan.getFullYear(), onejan.getMonth(), onejan.getDate() - dayOfWeekOneJan);
    }, [dayofweek]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/syndicate/email-stats/all');
        (async () => {
            try {
                const week = (d) => {
                    const firstDateOfFirstWeekBefore = firstDateOfFirstWeekPreceding(d);
                    return Math.trunc((((d.getTime() - firstDateOfFirstWeekBefore.getTime()) / 86400000)) / 7) + 1;
                };
                const body = await apiFetchJson(request, abortController.signal);
                const newStats = body.map((s) => {
                    const date = new Date(s.date);
                    return { date, total: s.total };
                });
                const dates = body.map(b => new Date(b.date).getTime());
                const firstDate = new Date(Math.min(...dates));
                const lastDate = new Date(Math.max(...dates));
                let date = lastDate;
                let weeklyStats;
                const weeklyStatsList = [];
                while (date >= firstDate) {
                    const w = week(date);
                    const day = dayofweek(date);
                    if (day === 6 || weeklyStats === undefined) {
                        if (weeklyStats !== undefined) {
                            weeklyStatsList.push(weeklyStats);
                        }
                        weeklyStats = { week: w, year: date.getFullYear(), values: {} };
                    }
                    const emailStats = newStats.find(s => s.date.getTime() === date.getTime());
                    if (emailStats !== undefined) {
                        weeklyStats.values[days[day]] = emailStats;
                    }
                    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
                }
                if (weeklyStats !== undefined) {
                    weeklyStatsList.push(weeklyStats);
                }
                setStats(weeklyStatsList);
            }
            catch (error) {
                sendFetchError('Unable to download email stats', error, request);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson, days, dayofweek, firstDateOfFirstWeekPreceding]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "co-header-main" },
            React.createElement(Typography, { type: "h1" }, "Email Stats"),
            React.createElement("div", null,
                React.createElement(Button, { onClick: () => updateView('CONTRIBUTORS'), size: "small" }, "CONTRIBUTORS"),
                React.createElement(Button, { onClick: () => updateView('OVERVIEW'), size: "small" }, "OVERVIEW"))),
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Week No."),
                    days.map((d) => (React.createElement("th", { key: d }, d))))),
            React.createElement("tbody", { style: { textAlign: 'center' } }, stats.map((l) => (React.createElement("tr", { key: l.week },
                React.createElement("td", null, l.week),
                days.map((v) => (React.createElement("td", { key: l.week.toString() + l.year.toString() + v, title: l.values[v]?.date.toLocaleDateString() }, l.values[v]?.total))))))))));
};
export default EmailStatsOverview;
