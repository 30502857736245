import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import * as React from 'react';
import Popup from 'components/custom-table/action/Popup';
import { sendFetchError } from 'utils/messageUtils';
import TrancheWrapper from '../../../../models/TrancheWrapper';
import EditableCell from './EditableCell';
const EditableDropdownCell = ({ cell, column, row, table, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [errorText, setErrorText] = React.useState();
    const [isDisabled, setDisabled] = React.useState(false);
    const [isEditing, setEditing] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [value, setvalue] = React.useState(row.original.overrideMap?.[column.id]
        ?? row.original.meta?.[column.id]);
    const [className, setClassName] = React.useState('');
    const save = React.useCallback(async (newValue) => {
        if (table.options.meta !== undefined && typeof table.options.meta.updateTable === 'function') {
            const url = new ApiServerURL('/syndicate/transactions/override');
            url.searchParams.append('transactionId', row.original.transaction.id);
            url.searchParams.append('trancheIndex', row.original.trancheIndex.toString());
            url.searchParams.append('key', column.id);
            if (newValue !== undefined) {
                url.searchParams.append('value', newValue);
            }
            const editableTranches = table.getRowModel().rows.filter((r) => r.original.transaction.id === row.original.transaction.id).map((r) => r.original.setEditable(true));
            table.options.meta.updateTable(editableTranches);
            setLoading(true);
            setDisabled(true);
            const request = new ServerRequest(url, { method: newValue !== undefined ? 'PATCH' : 'DELETE' });
            try {
                const body = await apiFetchJson(request);
                const tranches = body.map((t) => new TrancheWrapper(t));
                table.options.meta.updateTable(tranches);
                const updatedTranche = body[row.original.trancheIndex];
                setvalue(updatedTranche.overrideMap?.[column.id] ?? updatedTranche.meta?.[column.id]);
            }
            catch (error) {
                setErrorText('Error updating override information');
                sendFetchError('Unable to update', error, request);
            }
            finally {
                setLoading(false);
                setEditing(false);
            }
        }
    }, [column.id, apiFetchJson, row.original.trancheIndex, row.original.transaction.id, table]);
    const onChange = React.useCallback((event) => {
        setEditing(false);
        const newValue = event.target.value.value;
        setvalue(newValue);
        (async () => save(event.target.value.value))();
    }, [save]);
    const removeOverride = React.useCallback(() => {
        (async () => {
            await save(undefined);
        })();
    }, [save]);
    const closeDropdown = React.useCallback(() => {
        setDisabled(true);
        if (value !== undefined) {
            (async () => {
                await save(value);
                setEditing(false);
            })();
        }
        else {
            setEditing(false);
        }
    }, [save, value]);
    const title = React.useMemo(() => {
        const newTitleValue = row.original.overrideMap?.[column.id] !== undefined && row.original.meta?.[column.id] != null
            ? row.original.meta[column.id] : undefined;
        const newTitle = column.columnDef.meta?.dropdown?.options.find((o) => 'value' in o && o.value === newTitleValue)?.label;
        return newTitle;
    }, [column.id, row.original, column.columnDef.meta?.dropdown]);
    const content = React.useMemo(() => {
        const newValue = row.original.overrideMap?.[column.id] ?? row.original.meta?.[column.id];
        if (isEditing) {
            setvalue(newValue);
            return (React.createElement(Popup, { close: closeDropdown, style: { width: `${((column.columnDef.size ?? 150) + 20).toString()}px` } },
                React.createElement(Dropdown, { autoFocus: true, displayArrowIndicator: false, disabled: isDisabled, placeholder: "Select...", enableTermSearch: false, isInputClearable: true, isMultiSelect: false, isTypeAheadEnabled: false, closeMenuOnSelect: true, menuIsOpen: true, onChange: onChange, options: column.columnDef.meta?.dropdown?.options ?? [], value: column.columnDef.meta?.dropdown?.options.find((o) => 'value' in o && value === o.value) })));
        }
        setClassName('co-centered-box');
        const text = column.columnDef.meta?.dropdown?.options.find((o) => 'value' in o && o.value === newValue)?.label ?? '?';
        return text;
    }, [closeDropdown, column.columnDef.size, column.id, isDisabled, isEditing, column.columnDef.meta?.dropdown,
        onChange, row.original, value]);
    const copyValue = React.useCallback(async () => {
        console.log("Copying: %o", value);
        if (value !== undefined) {
            const t = { type: 'text/plain' };
            const clipboardText = { columnId: column.id, text: value };
            const p = JSON.stringify(clipboardText);
            const blob = new Blob([p], t);
            const items = new ClipboardItem({ 'text/plain': blob });
            await navigator.clipboard.write([items]);
        }
    }, [column, value]);
    const pasteValue = React.useCallback(async () => {
        const clipboard = await navigator.clipboard.read();
        const blob = await clipboard[0].getType('text/plain');
        const text = await blob.text();
        try {
            const clipboardText = JSON.parse(text);
            if (typeof clipboardText === 'object' && clipboardText !== null && 'columnId' in clipboardText && 'text' in clipboardText && clipboardText.columnId === column.id && typeof clipboardText.text === 'string') {
                setvalue(clipboardText.text);
                await save(clipboardText.text);
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }
        catch (error) {
            // the current value in the clipboard is not a saved value
        }
    }, [column.id, save]);
    return (React.createElement(EditableCell, { className: className, copyValue: copyValue, errorText: errorText, isEditing: isEditing, isLoading: isLoading, isLoadingRow: row.original.isEditing, isOverriden: row.original.overrideMap?.[column.id] !== undefined, key: cell.id, pasteValue: pasteValue, removeOverride: removeOverride, setEditing: setEditing, title: title }, content));
};
export default EditableDropdownCell;
