import * as React from 'react';
import { Button } from '@symphony-ui/uitoolkit-components';
import ActionCell from 'components/custom-table/action/ActionCell';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import TableDnd from '../regexes/regex-viewer/regex-table/TableDnd';
import EditableNameTextCell from './EditableNameTextCell';
import NameAddModal from './NameAddModal';
const ConstantNameTable = ({ constant, setConstant }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const [showModal, setShowModal] = React.useState(false);
    const names = React.useMemo(() => {
        const { id } = constant;
        return id !== undefined ? constant.names.map((name, index) => ({ id, index, name })) : [];
    }, [constant]);
    // const meta: TableMeta<ConstantName> = {
    //   updateTable: (updatedNames: ConstantName[]) => { setNames(updatedNames); },
    // };
    const deleteFn = React.useCallback(({ row: { original } }) => {
        const { id } = constant;
        if (id !== undefined) {
            const url = new ApiServerURL('/syndicate/constant/name');
            url.searchParams.append('id', id);
            url.searchParams.append('index', original.index.toString());
            const request = new ServerRequest(url, { method: 'DELETE' });
            (async () => {
                try {
                    const body = await apiFetchJson(request);
                    setConstant(body);
                }
                catch (error) {
                    sendFetchError('Unable to delete constant name', error, request);
                }
            })();
        }
    }, [apiFetchJson, constant, setConstant]);
    const columns = React.useMemo(() => [
        {
            cell: ActionCell,
            id: 'actions',
            meta: {
                actionsMenu: [
                    { callback: deleteFn, label: 'Delete' },
                ],
            },
            size: 20,
        },
        {
            accessorFn: (t) => t.name,
            cell: EditableNameTextCell,
            id: 'name',
            meta: { isEditable: true },
            size: 1000,
        },
    ], [deleteFn]);
    const setData = React.useCallback((d) => {
        const constantClone = { ...constant };
        constantClone.names = d.map((n) => n.name);
        const url = new ApiServerURL('/syndicate/constant');
        const request = new ServerRequest(url, {
            body: JSON.stringify(constantClone),
            headers: { 'content-type': 'application/json' },
            method: 'PUT',
        });
        (async () => {
            try {
                const body = await apiFetchJson(request);
                setConstant(body);
            }
            catch (error) {
                sendFetchError('Unable to update constant info', error, request);
            }
        })();
    }, [apiFetchJson, constant, setConstant]);
    const addName = React.useCallback(async (value) => {
        const { id } = constant;
        if (id !== undefined) {
            const url = new ApiServerURL('/syndicate/constant/name');
            url.searchParams.append('id', id);
            url.searchParams.append('name', value);
            const request = new ServerRequest(url, { method: 'PUT' });
            try {
                const body = await apiFetchJson(request);
                setConstant(body);
            }
            catch (error) {
                sendFetchError('Unable to update constant', error, request);
                //
            }
        }
    }, [apiFetchJson, constant, setConstant]);
    const onClickAdd = React.useCallback(() => { setShowModal(true); }, []);
    const onClickSort = React.useCallback(() => {
        const sortedNames = names.sort((a, b) => {
            const regexA = new RegExp(a.name);
            if (regexA.test(b.name)) {
                return 1;
            }
            const regexB = new RegExp(b.name);
            if (regexB.test(a.name)) {
                return -1;
            }
            return 0;
        });
        // save only if there has been a change
        if (sortedNames.some((n, index) => n.index !== index)) {
            setData(sortedNames);
        }
    }, [names, setData]);
    const actionElements = React.useMemo(() => {
        return [
            // <ConstantDisplayName constant={constant} key="display-name" setConstant={setConstant} />,
            React.createElement(Button, { onClick: onClickAdd, key: "add-button", size: "small", variant: 'secondary' }, "ADD"),
            React.createElement(Button, { onClick: onClickSort, key: "sort-button", size: "small", style: { marginLeft: '0.5rem' }, variant: 'secondary' }, "SORT")
        ];
    }, [onClickAdd, onClickSort]);
    const meta = React.useMemo(() => {
        return {
            updateTable: (items) => {
                const clone = { ...constant };
                clone.names = items.map(i => i.name);
                setConstant(clone);
            }
        };
    }, [constant, setConstant]);
    return (React.createElement(React.Fragment, null,
        showModal
            ? (React.createElement(NameAddModal, { addName: addName, closeModal: () => { setShowModal(false); } }))
            : undefined,
        React.createElement(TableDnd, { actionElements: actionElements, columns: columns, data: names, emptyMessage: "Nothing to see here", errorMessage: "oops we have an errors", hasError: false, isLoading: false, meta: meta, setData: setData })));
};
export default ConstantNameTable;
