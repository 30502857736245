import { Button } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
const AuthenticationUser = () => {
    const auth = useAuth();
    const [user, setUser] = React.useState(auth.user);
    const onClickRefresh = React.useCallback(() => {
        setUser(auth.user);
        const store = auth.settings.userStore;
        if (store !== undefined) {
            (async () => {
                const keys = await store.getAllKeys();
                const output = await Promise.all(keys.map((key) => store.get(key)));
                output.forEach((o) => console.log('User: %o', o));
            })();
        }
        const store2 = auth.settings.stateStore;
        if (store2 !== undefined) {
            (async () => {
                const keys = await store2.getAllKeys();
                const output = await Promise.all(keys.map((key) => store2.get(key)));
                output.forEach((o) => console.log('User: %o', o));
            })();
        }
    }, [auth.settings.userStore, auth.settings.stateStore, auth.user]);
    const printDateTime = React.useCallback((timestamp) => {
        if (timestamp === undefined) {
            return '-';
        }
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }, []);
    if (user === null) {
        return 'User is null';
    }
    if (user === undefined) {
        return 'User is undefined';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: onClickRefresh }, "REFRESH"),
        React.createElement("table", { style: { whiteSpace: 'nowrap' } },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, "Access Token"),
                    React.createElement("td", { style: { width: '5rem' }, title: user.access_token }, user.access_token)),
                React.createElement("tr", null,
                    React.createElement("td", null, "Expired"),
                    React.createElement("td", null, user.expired)),
                React.createElement("tr", null,
                    React.createElement("td", null, "Expires At"),
                    React.createElement("td", null, printDateTime(user.expires_at))),
                React.createElement("tr", null,
                    React.createElement("td", null, "Expired In"),
                    React.createElement("td", null, user.expires_in)),
                React.createElement("tr", null,
                    React.createElement("td", null, "ID Token"),
                    React.createElement("td", { title: user.id_token }, user.id_token)),
                React.createElement("tr", null,
                    React.createElement("td", null, "Profile"),
                    React.createElement("td", { title: JSON.stringify(user.profile) }, JSON.stringify(user.profile))),
                React.createElement("tr", null,
                    React.createElement("td", null, "Refresh Token"),
                    React.createElement("td", null, user.refresh_token)),
                React.createElement("tr", null,
                    React.createElement("td", null, "Scope"),
                    React.createElement("td", null, user.scope)),
                React.createElement("tr", null,
                    React.createElement("td", null, "Scopes"),
                    React.createElement("td", null, user.scopes)),
                React.createElement("tr", null,
                    React.createElement("td", null, "Session State"),
                    React.createElement("td", null, user.session_state)),
                React.createElement("tr", null,
                    React.createElement("td", null, "State"),
                    React.createElement("td", null, JSON.stringify(user.state)))))));
};
export default AuthenticationUser;
