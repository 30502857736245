import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError } from 'utils/messageUtils';
import Currency from '../../models/Currency';
import CurrencyTable from './CurrencyTable';
import CurrencyForm from './CurrencyForm';
import ServerRequest from 'models/ServerRequest';
const Currencies = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [currencies, setCurrencies] = React.useState([]);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/core/currencies');
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const loaded = body.map(c => new Currency(c));
                setCurrencies(loaded);
            }
            catch (error) {
                if (!(error instanceof Error && error.name !== 'AbortError')) {
                    sendFetchError('Unable to download currencies', error, request);
                    setError(true);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const create = React.useCallback(() => {
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: item } }) => {
        const requestInit = { body: JSON.stringify(item), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' };
        const request = new ServerRequest('/core/currencies', requestInit);
        (async () => {
            try {
                await apiFetchNoContent(request);
                const filteredCurrencies = currencies.filter((u) => u.id !== item.id);
                setCurrencies(filteredCurrencies);
            }
            catch (error) {
                sendFetchError("Unable to delete currency", error, request);
            }
        })();
    }, [apiFetchNoContent, currencies]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setDetails(item);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        const method = data.id ? 'PUT' : 'POST';
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method };
        const request = new ServerRequest('/core/currencies', requestInit);
        try {
            const body = await apiFetchJson(request);
            const updated = new Currency(body);
            const index = currencies.findIndex((d) => d.id === updated.id);
            const updatedCurrencies = [...currencies];
            if (index === -1) {
                updatedCurrencies.push(updated);
            }
            else {
                updatedCurrencies[index] = updated;
            }
            setCurrencies(updatedCurrencies);
            updateView('TABLE');
        }
        catch (error) {
            sendFetchError('Unable to save currency', error, request);
        }
    }, [apiFetchJson, currencies, updateView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(CurrencyForm, { currency: details, save: save }));
        case 'TABLE':
            return (React.createElement(CurrencyTable, { create: create, deleteFn: deleteFn, edit: edit, currencies: currencies, hasError: hasError, isLoading: isLoading }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Currencies;
