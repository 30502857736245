import AnnaSearch from '../models/search/AnnaSearch';
import EsmaSearch from '../models/search/EsmaSearch';
import EuroclearSearch from '../models/search/EuroclearSearch';
import IpoSearch from '../models/search/IpoSearch';
import SecSearch from '../models/search/SecSearch';
export var SecuritySource;
(function (SecuritySource) {
    SecuritySource["ANNA"] = "ANNA";
    SecuritySource["ESMA"] = "ESMA";
    SecuritySource["EUROCLEAR"] = "Euroclear";
})(SecuritySource || (SecuritySource = {}));
export var Source;
(function (Source) {
    Source["ANNA"] = "ANNA";
    Source["ESMA"] = "ESMA";
    Source["EUROCLEAR"] = "Euroclear";
    Source["SEC"] = "SEC";
    Source["IPO"] = "IPO";
})(Source || (Source = {}));
export const fromSource = (value) => {
    switch (value.toUpperCase()) {
        case 'ANNA':
            return Source.ANNA;
        case 'ESMA':
            return Source.ESMA;
        case 'EUROCLEAR':
            return Source.EUROCLEAR;
        case 'SEC':
            return Source.SEC;
        case 'IPO':
            return Source.IPO;
        default:
            throw new Error(`Unknown source: ${value}`);
    }
};
export const databaseOptions = [
    { label: 'Euroclear', value: Source.EUROCLEAR },
    { label: 'ESMA', value: Source.ESMA },
    { label: 'ANNA', value: Source.ANNA },
    { label: 'SEC', value: Source.SEC },
    { label: 'IPO', value: Source.IPO },
];
export const turnIntoClass = (db, search) => {
    const anySearch = search;
    switch (db) {
        case Source.ANNA:
            return new AnnaSearch(anySearch);
        case Source.ESMA:
            return new EsmaSearch(anySearch);
        case Source.EUROCLEAR:
            return new EuroclearSearch(anySearch);
        case Source.IPO:
            return new IpoSearch(anySearch);
        case Source.SEC:
            return new SecSearch(anySearch);
        default:
            throw new Error('Unable to find source');
    }
};
