import QualifiedSearch from '../QualifiedSearch';
import TimeRange from '../TimeRange';
class EuroclearQuery {
    codecreationdate;
    currency;
    market;
    maturitydate;
    q;
    rate;
    ratetype;
    securitytype;
    getCreationDate = () => this.codecreationdate;
    getCurrency = () => this.currency;
    getMaturity = () => this.maturitydate;
    constructor(response) {
        this.codecreationdate = response?.codecreationdate ? new TimeRange(response.codecreationdate) : undefined;
        this.currency = response?.currency;
        this.market = response?.market;
        this.maturitydate = response?.maturitydate ? new TimeRange(response.maturitydate) : undefined;
        this.q = response?.q ? new QualifiedSearch(response.q) : undefined;
        this.rate = response?.rate ? new QualifiedSearch(response.rate) : undefined;
        this.ratetype = response?.ratetype;
        this.securitytype = response?.securitytype;
    }
    static create(base) {
        const euroclearQuery = base;
        const newQuery = new EuroclearQuery(euroclearQuery);
        if (newQuery.maturitydate != null) {
            newQuery.maturitydate = new TimeRange(newQuery.maturitydate);
        }
        if (newQuery.codecreationdate != null) {
            newQuery.codecreationdate = new TimeRange(newQuery.codecreationdate);
        }
        if (newQuery.q != null) {
            newQuery.q = QualifiedSearch.create(newQuery.q);
        }
        if (newQuery.rate != null) {
            newQuery.rate = QualifiedSearch.create(newQuery.rate);
        }
        return newQuery;
    }
}
export default EuroclearQuery;
