import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import Workflow from '../../model/Workflow';
import WorkflowTable from './WorkflowTable';
import WorkflowForm from './WorkflowForm';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
const Workflows = () => {
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const { view, updateView } = React.useContext(NavigationContext);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [workflows, setWorkflows] = React.useState([]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/mtn/workflows');
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const loaded = body.map(w => new Workflow(w));
                setWorkflows(loaded);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    setError(true);
                    sendFetchError('Unable to download workflows', error, request);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const create = React.useCallback(() => {
        setDetails(new Workflow({}));
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: item } }) => {
        const requestInit = { body: JSON.stringify(item), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' };
        const request = new ServerRequest('/mtn/workflows', requestInit);
        (async () => {
            try {
                await apiFetchNoContent(request);
                const filteredWorkflows = workflows.filter((u) => u.id !== item.id);
                setWorkflows(filteredWorkflows);
            }
            catch (error) {
                sendFetchError('Unable to delete workflow', error, request);
            }
        })();
    }, [apiFetchNoContent, workflows]);
    const edit = React.useCallback(({ row: { original: item } }) => {
        setDetails(item);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        const method = data.id ? 'PUT' : 'POST';
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method };
        const request = new ServerRequest('/mtn/workflows', requestInit);
        try {
            const body = await apiFetchJson(request);
            const updatedWorkflow = new Workflow(body);
            const index = workflows.findIndex((d) => d.id === updatedWorkflow.id);
            const updatedWorkflows = [...workflows];
            if (index === -1) {
                updatedWorkflows.push(updatedWorkflow);
            }
            else {
                updatedWorkflows[index] = updatedWorkflow;
            }
            setWorkflows(updatedWorkflows);
            updateView('TABLE');
        }
        catch (error) {
            sendFetchError('Unable to Save Workflow', error, request);
        }
    }, [apiFetchJson, updateView, workflows]);
    switch (view) {
        case 'FORM':
            return (React.createElement(WorkflowForm, { save: save, workflow: details ?? new Workflow({}) }));
        case 'TABLE':
            return (React.createElement(WorkflowTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, workflows: workflows }));
        default:
            updateView('TABLE');
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Workflows;
