import * as React from 'react';
import { Dropdown } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import OptionRenderer from './OptionRenderer';
import './DataViewerForm.scss';
const KeyPicker = ({ isLoading, myKey, setMyKey }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const valueFn = React.useCallback((key) => ({
        data: key.id !== undefined && key.id !== null ? {
            id: key.id,
            number: key.number,
        } : undefined,
        label: key.name,
        value: key.name,
    }), []);
    const [hasError, setError] = React.useState(false);
    const [options, setOptions] = React.useState((myKey === undefined ? [] : [valueFn(myKey)]));
    const [inputValue, setInputValue] = React.useState();
    const onChangeValue = React.useCallback(({ target: { value: newValue } }) => {
        if (newValue.data !== undefined) {
            const stored = { id: newValue.data.id, name: newValue.value, number: newValue.data.number };
            localStorage.setItem('key', JSON.stringify(stored));
            setMyKey(stored);
        }
    }, [setMyKey]);
    const value = React.useMemo(() => myKey !== undefined ? valueFn(myKey) : undefined, [myKey, valueFn]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const inputName = inputValue ?? myKey?.name;
        if (inputName === undefined || inputName === '') {
            return undefined;
        }
        const timeout = setTimeout(() => {
            const url = new ApiServerURL('/syndicate/regex/keyvalues/keyoptions');
            url.searchParams.append('key', inputName);
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    setOptions(body);
                }
                catch (error) {
                    if (!(error instanceof Error && error.name === 'AbortError')) {
                        sendFetchError('Unable to download key options', error, request);
                        setError(true);
                    }
                }
            })();
        }, 250);
        return () => {
            clearTimeout(timeout);
            abortController.abort();
        };
    }, [apiFetchJson, inputValue, myKey]);
    return (React.createElement("div", { className: "co-key-picker" },
        React.createElement(Dropdown, { enableTermSearch: true, options: options, isDisabled: hasError || isLoading, label: "Select a Key", name: "value", onInputChange: (e) => { setInputValue(e); }, onChange: onChangeValue, optionRenderer: (OptionRenderer), placeholder: "Type or select key...", showRequired: true, value: value })));
};
export default KeyPicker;
