var _a;
class FilterItem {
    exclude;
    values;
    constructor(response) {
        this.exclude = response?.exclude ?? true;
        this.values = response?.values ?? [];
    }
    static isEmpty(filterItem) {
        return filterItem === undefined ? true : filterItem.values.length > 0;
    }
    #clone() {
        return new _a({ exclude: this.exclude, values: this.values });
    }
    update(values) {
        const clone = this.#clone();
        clone.values = values;
        return clone;
    }
}
_a = FilterItem;
export default FilterItem;
