import { defaultPermissions } from 'components/metadata/utils';
class Report {
    createdBy;
    createdDate;
    frequency;
    id;
    lastModifiedBy;
    lastModifiedDate;
    name;
    permissions;
    searchId;
    showEmpty;
    source;
    streamIds;
    constructor(response) {
        this.createdBy = response.createdBy;
        this.createdDate = response.createdDate;
        this.frequency = response.frequency;
        this.id = response.id;
        this.lastModifiedBy = response.lastModifiedBy;
        this.lastModifiedDate = response.lastModifiedDate;
        this.name = response.name;
        this.permissions = response.permissions ?? defaultPermissions;
        this.searchId = response.searchId;
        this.showEmpty = response.showEmpty;
        this.source = response.source;
        this.streamIds = response.streamIds ?? [];
    }
    clone() {
        return new Report({
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            frequency: this.frequency,
            id: this.id,
            lastModifiedBy: this.lastModifiedBy,
            lastModifiedDate: this.lastModifiedDate,
            name: this.name,
            permissions: this.permissions,
            searchId: this.searchId,
            showEmpty: this.showEmpty,
            source: this.source,
            streamIds: this.streamIds
        });
    }
    setSearchId(value) {
        this.searchId = value;
        return this;
    }
    updateFrequency(value) {
        const clone = this.clone();
        clone.frequency = value;
        return clone;
    }
    update(name, value) {
        const clone = this.clone();
        clone[name] = value;
        return clone;
    }
    updateSearch(searchId, source) {
        const clone = this.clone();
        this.searchId = searchId;
        this.source = source;
        return clone;
    }
    updatePermissions(permissions) {
        const clone = this.clone();
        clone.permissions = permissions;
        return clone;
    }
    static create() {
        return new Report({});
    }
}
export default Report;
