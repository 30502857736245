import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ApiServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import KeyValueRegex from '../../../models/regexes/KeyValueRegex';
import Regex from '../../../models/regexes/Regex';
import { escapeRegex } from '../../../models/regexes/regexUtils';
import ItemRegex from '../../../models/regexes/ItemRegex';
import LineRegex from '../../../models/regexes/LineRegex';
const AmendedContent = ({ content, setName, setOriginalRegex, setRegex, setShowKey, setShowRegex, setTitle, setType, }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const divElement = React.useRef(null);
    const itemClickListener = React.useCallback((preceding, value) => () => {
        setTitle(`Item: ${value}`);
        const newRegex = new Regex({ markers: [], template: escapeRegex(value) });
        setType('listitems');
        setRegex(newRegex);
        setShowRegex(true);
        if (preceding.length === 0) {
            setOriginalRegex(undefined);
        }
        else {
            const url = new ApiServerURL('/syndicate/regex/listitems/itemregex');
            url.searchParams.append('values', preceding.join(','));
            const request = new ServerRequest(url);
            (async () => {
                try {
                    const body = await apiFetchJson(request);
                    const original = ItemRegex.create(body.itemRegex);
                    setOriginalRegex(ItemRegex.create(original));
                }
                catch (error) {
                    sendFetchError('Unable to download original regex', error, request);
                    //
                }
            })();
        }
    }, [apiFetchJson, setOriginalRegex, setShowRegex, setRegex, setTitle, setType]);
    const paragraphListener = React.useCallback((text, category) => () => {
        setTitle(text);
        setType('paragraphs');
        const newRegex = new Regex({ markers: [], template: escapeRegex(text) });
        setRegex(newRegex);
        setShowRegex(true);
        const url = new ApiServerURL('/syndicate/regex/paragraphs');
        url.searchParams.append('category', category);
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchJson(request);
                setOriginalRegex(LineRegex.create(body));
            }
            catch (error) {
                sendFetchError('Unable to download original regex', error, request);
            }
        })();
    }, [apiFetchJson, setOriginalRegex, setRegex, setShowRegex, setTitle, setType]);
    const keyClickListener = React.useCallback((key) => () => {
        setTitle(key);
        setName(key);
        setShowKey(true);
    }, [setName, setShowKey, setTitle]);
    const valueClickListener = React.useCallback((key, value) => () => {
        setTitle(`${key}: ${value}`);
        setType('keyvalues');
        const newRegex = new Regex({ markers: [], template: escapeRegex(value) });
        setRegex(newRegex);
        setShowRegex(true);
        const url = new ApiServerURL('/syndicate/regex/keyvalues/keyvalueregex');
        url.searchParams.append('key', key);
        const request = new ServerRequest(url);
        (async () => {
            try {
                const body = await apiFetchJson(request);
                setOriginalRegex(KeyValueRegex.create(body));
            }
            catch (error) {
                sendFetchError('Unable to download original regex', error, request);
            }
        })();
    }, [apiFetchJson, setOriginalRegex, setRegex, setShowRegex, setTitle, setType]);
    const getValueWithoutFootnotes = React.useCallback((cell) => {
        const clone = cell.cloneNode(true);
        const childElement = clone.children;
        Array.from(childElement).filter((child) => child.tagName === 'SUP').forEach((child) => child.remove());
        Array.from(childElement).filter((child) => child.tagName === 'BR').forEach((child) => child.append('\n'));
        return clone.textContent;
    }, []);
    const getOwnText = React.useCallback((element) => {
        let text = '';
        for (let ind = 0; ind < element.childNodes.length; ind += 1) {
            const node = element.childNodes.item(ind);
            if (node instanceof Text && node.nodeValue !== null) {
                text += node.nodeValue;
            }
        }
        return text;
    }, []);
    const getPreceding = React.useCallback((element) => {
        const parent = element.parentElement?.parentElement;
        if (parent !== null && parent !== undefined && parent.tagName === 'LI') {
            const li = parent;
            const text = getOwnText(li);
            return getPreceding(li).concat(text);
        }
        return [];
    }, [getOwnText]);
    React.useLayoutEffect(() => {
        const abortController = new AbortController();
        if (divElement.current !== null) {
            const tables = divElement.current.querySelectorAll('table');
            tables.forEach((table) => {
                const isTransposed = table.classList.contains('cmo-transposed');
                table.querySelectorAll('tr').forEach((tr) => {
                    tr.querySelectorAll('.co-parse-no-key').forEach((keyEntry) => {
                        const keyEntryText = getValueWithoutFootnotes(keyEntry);
                        if (keyEntryText !== null) {
                            const key = keyEntryText.substring(0, keyEntryText.length - (keyEntryText.endsWith(':') ? 1 : 0));
                            keyEntry.addEventListener('click', keyClickListener(key), { signal: abortController.signal });
                        }
                    });
                    tr.querySelectorAll('.co-parse-no-regex').forEach((valueEntry) => {
                        let keyEntry;
                        if (isTransposed) {
                            const index = Array.from(tr.children).indexOf(valueEntry);
                            const row = table.querySelector('tr');
                            keyEntry = row !== null ? row.querySelectorAll('td').item(index) : tr.querySelector('td');
                        }
                        else {
                            keyEntry = tr.querySelector('td');
                        }
                        if (keyEntry !== null) {
                            const keyEntryText = getValueWithoutFootnotes(keyEntry);
                            const valueWithoutFootnotes = getValueWithoutFootnotes(valueEntry);
                            if (keyEntryText !== null && valueWithoutFootnotes !== null) {
                                const key = keyEntryText.substring(0, keyEntryText.length - (keyEntryText.endsWith(':') ? 1 : 0));
                                valueEntry.addEventListener('click', valueClickListener(key, valueWithoutFootnotes), { signal: abortController.signal });
                            }
                        }
                    });
                });
            });
        }
        return () => abortController.abort();
    }, [content, getValueWithoutFootnotes, keyClickListener, valueClickListener]);
    React.useLayoutEffect(() => {
        const abortController = new AbortController();
        if (divElement.current !== null) {
            const uls = divElement.current.querySelectorAll('ul');
            uls.forEach((ul) => {
                ul.querySelectorAll('li').forEach((li) => {
                    if (li.classList.contains('co-parse-no-regex')) {
                        const value = getOwnText(li);
                        const preceding = getPreceding(li);
                        li.addEventListener('click', itemClickListener(preceding, value), { signal: abortController.signal });
                    }
                });
            });
        }
        return () => abortController.abort();
    }, [content, divElement, getOwnText, getPreceding, itemClickListener]);
    React.useLayoutEffect(() => {
        const abortController = new AbortController();
        if (divElement.current !== null) {
            const ps = divElement.current.querySelectorAll('p');
            ps.forEach((p) => {
                p.querySelectorAll('span').forEach(s => {
                    const { textContent } = s;
                    if (textContent !== null) {
                        s.classList.forEach(c => {
                            if (c.startsWith('co-cat-')) {
                                s.addEventListener('click', paragraphListener(textContent, c.substring(7).toUpperCase()), { signal: abortController.signal });
                            }
                        });
                    }
                });
            });
        }
        return () => abortController.abort();
    }, [content, divElement, getOwnText, getPreceding, itemClickListener, paragraphListener]);
    return (React.createElement("div", { ref: divElement, dangerouslySetInnerHTML: { __html: content } }));
};
export default AmendedContent;
