var _a;
class EsmaCompany {
    id;
    lei;
    entity;
    tags;
    static columns = [
        { accessorKey: 'lei', header: 'LEI' },
        { accessorKey: 'entity.legalName.name', header: 'Name' },
    ];
    static getSort = (s0, s1) => s0.entity.legalName.name.localeCompare(s1.entity.legalName.name);
    constructor(response) {
        this.entity = response.entity;
        this.id = response.id;
        this.lei = response.lei;
        this.tags = response.tags;
    }
    #clone() {
        return new _a({
            entity: this.entity,
            id: this.id,
            lei: this.lei,
            tags: this.tags
        });
    }
    updateTags(allCompanyTags) {
        const clone = this.#clone();
        clone.tags = allCompanyTags.find(t => t.companyId === this.id);
        return clone;
    }
}
_a = EsmaCompany;
export default EsmaCompany;
