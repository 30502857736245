import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { sendFetchError } from 'utils/messageUtils';
import TableContext from 'components/custom-table/TableContext';
import TransactionWrapper from '../../../../models/TransactionWrapper';
import TransactionMetaForm from '../TransactionMetaForm';
import './TransactionMetaModal.css';
const TransactionMetaModal = ({ onClose, transaction }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { tableUpdateFn } = React.useContext(TableContext);
    const [isLoading, setLoading] = React.useState(false);
    const [transactionMeta, setTransactionMeta] = React.useState({});
    const isDisabled = React.useMemo(() => transactionMeta.sector === undefined || transactionMeta.regions === undefined || transactionMeta.regions.length === 0 || transactionMeta.types === undefined || transactionMeta.types.length === 0 || (transactionMeta.companyName === undefined || transactionMeta.companyName.length < 3), [transactionMeta]);
    const initialTransactionMeta = React.useMemo(() => transaction.getMeta(), [transaction]);
    const save = React.useCallback(async () => {
        if (transactionMeta.sector !== undefined && transactionMeta.regions !== undefined && transactionMeta.types !== undefined && tableUpdateFn !== undefined && transactionMeta.companyName !== undefined) {
            const formData = new FormData();
            formData.append('transactionId', transaction.id);
            formData.append('sector', transactionMeta.sector);
            formData.append('regions', transactionMeta.regions.join(","));
            formData.append('types', transactionMeta.types.join(','));
            formData.append('companyName', transactionMeta.companyName);
            const request = new ServerRequest('/syndicate/transactions/meta-all-update', { body: formData, method: 'PATCH' });
            setLoading(true);
            try {
                const responseTransction = await apiFetchJson(request);
                const returnTransaction = new TransactionWrapper(responseTransction);
                const updateFn = (data) => ({
                    pageParams: data.pageParams,
                    pages: [...data.pages].map((p) => p
                        .map((t) => (t.id === transaction.id ? returnTransaction : t))),
                });
                tableUpdateFn(updateFn);
            }
            catch (error) {
                sendFetchError('Unable to merge transactions', error, request);
            }
            finally {
                setLoading(false);
            }
        }
    }, [apiFetchJson, transactionMeta, transaction.id, tableUpdateFn]);
    const onClickSave = React.useCallback(() => {
        if (!isDisabled) {
            (async () => {
                setLoading(true);
                await save();
                setLoading(false);
                onClose();
            })();
        }
    }, [onClose, save, isDisabled]);
    const onKeyUpSave = React.useCallback((event) => {
        if (event.key === 'Enter' && !isDisabled) {
            (async () => {
                await save();
                onClose();
            })();
        }
    }, [isDisabled, onClose, save]);
    return (React.createElement(Modal, { closeButton: true, onClose: onClose, show: true, size: "large" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Set Meta data transaction"),
            React.createElement("div", null, transaction.name)),
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "cmo-meta-form" },
                React.createElement(TransactionMetaForm, { isLoading: isLoading, initialTransactionMeta: initialTransactionMeta, setTransactionMeta: setTransactionMeta }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { disabled: isDisabled, loading: isLoading, onClick: onClickSave, onKeyUp: onKeyUpSave }, "SAVE"))));
};
export default TransactionMetaModal;
