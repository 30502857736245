import * as React from 'react';
import { Card, Dropdown, Typography, } from '@symphony-ui/uitoolkit-components';
import './IpoTable.css';
import IpoExchangeTable from './IpoExchangeTable';
import Ipo from '../models/Ipo';
import useOption from 'components/customHooks';
const IpoTable = ({ exchange, hasError, isLoading, ipos, setExchange, }) => {
    const [data, setData] = React.useState(new Ipo({}));
    const exchangeOptions = useOption('/bb/ipo/options');
    const onChangeDropdown = React.useCallback((event) => {
        const { value } = event.target;
        const newObject = data.updateExchange(value.value);
        setData(newObject);
    }, [data]);
    React.useEffect(() => {
        setExchange(data.exchange);
    }, [data, setExchange]);
    return (React.createElement(Card, { className: "co-card-main" },
        React.createElement("div", { className: "card-header" },
            React.createElement(Typography, { type: "h1" }, "Releases"),
            React.createElement("div", { className: "co-dropdown-row" },
                React.createElement(Dropdown, { id: "exchange-dropdown", name: "exchange", label: "Download Ipos from Exchange", onChange: onChangeDropdown, options: exchangeOptions, value: exchangeOptions.find((o) => 'value' in o && o.value === exchange) }))),
        exchange !== undefined
            ? (React.createElement(IpoExchangeTable, { exchange: exchange, ipos: ipos, emptyMessage: "You have no ipos yet", errorMessage: "Error downloading ipos", hasError: hasError, isLoading: isLoading })) : undefined));
};
export default IpoTable;
