import * as React from 'react';
import CellRender from './CellRender';
const RowRender = function RowRenderElement({ meta, row, subcomponent, style }) {
    const className = React.useMemo(() => {
        if (meta?.rowClassFn === undefined) {
            return "co-row";
        }
        const classText = meta.rowClassFn(row);
        if (classText === undefined) {
            return "co-row";
        }
        return "co-row " + classText;
    }, [meta, row]);
    return React.createElement(React.Fragment, null,
        React.createElement("tr", { className: className, style: style }, row.getVisibleCells().map((cell) => React.createElement(CellRender, { cell: cell, key: cell.id, meta: meta }))),
        row.getIsExpanded() ? subcomponent : undefined);
};
export default RowRender;
