import { appFromModule, moduleFromApp } from '../utils/appUtils';
class NavigationService {
    appView;
    tab;
    view;
    #user;
    #storage;
    availableTabs;
    #setAppView;
    #setTab;
    #setView;
    #setAvailableTabs;
    constructor({ appView, availableTabs, tab, view, user, storage, setAppView, setTab, setView, setAvailableTabs, }) {
        this.appView = appView;
        this.tab = tab;
        this.view = view;
        this.#setAppView = setAppView;
        this.#setTab = setTab;
        this.#setView = setView;
        this.#user = user;
        this.#storage = storage ?? localStorage;
        this.availableTabs = availableTabs;
        this.#setAvailableTabs = setAvailableTabs;
        const stored = this.#getStored();
        if (stored?.current !== undefined) {
            if (this.#isAccessible(stored.current.appView)) {
                setAppView(stored.current.appView);
                setTab(stored.current.tab);
                setView(stored.current.view);
            }
            else if (this.#user != null) {
                this.#setAppView(undefined);
            }
        }
    }
    #getStored = () => {
        const stored = this.#storage.getItem('navigation');
        if (stored === null) {
            return null;
        }
        return JSON.parse(stored);
    };
    availableApps = () => {
        if (this.#user == null) {
            return undefined;
        }
        const claims = this.#user.profile;
        return Object.keys(claims.authorityMap).map((name) => appFromModule(name));
    };
    updateAvailableTabs = (allTabs) => {
        const authoritiesTab = this.appAuthorities();
        const newAvailableTabs = authoritiesTab !== undefined ? allTabs.filter((t) => t.auth === undefined || t.auth.some((a) => authoritiesTab.includes(a) || authoritiesTab.includes(`ROLE_${a}`))) : undefined;
        this.#setAvailableTabs(newAvailableTabs);
        if (this.tab === undefined) {
            this.updateTab(newAvailableTabs !== undefined ? newAvailableTabs[0].code : undefined);
        }
    };
    #store = (newApp, newTab, newView) => {
        const newNavigation = this.#getStored() ?? {};
        if (newApp !== undefined) {
            newNavigation.current = { appView: newApp, tab: newTab, view: newView };
        }
        else {
            delete newNavigation.current;
        }
        if (newApp !== undefined && newTab !== undefined) {
            const storedTabs = newNavigation.tabs ?? {};
            storedTabs[newApp] = newTab;
        }
        if (newApp !== undefined && newTab !== undefined && newView !== undefined) {
            const storedViews = newNavigation.views ?? {};
            if (newApp in storedViews) {
                storedViews[newApp][newTab] = newView;
            }
            else {
                storedViews[newApp] = { [newTab]: newView };
            }
        }
        if (newNavigation.current !== undefined || newNavigation.tabs !== undefined || newNavigation.views !== undefined) {
            this.#storage.setItem('navigation', JSON.stringify(newNavigation));
        }
        else {
            this.#storage.removeItem('navigation');
        }
    };
    appAuthorities = () => {
        if (this.appView === undefined) {
            return undefined;
        }
        if (this.#user == null) {
            return [];
        }
        const claims = this.#user.profile;
        const module = moduleFromApp(this.appView);
        return claims.authorityMap[module];
    };
    #isAccessible = (app) => {
        if (this.#user == null) {
            return false;
        }
        const claims = this.#user.profile;
        const module = moduleFromApp(app);
        return module in claims.authorityMap && claims.authorityMap[module] && claims.authorityMap[module].length > 0;
    };
    updateAppView = (appView) => {
        if (appView !== this.appView) {
            const stored = this.#getStored();
            const newTab = appView !== undefined ? stored?.tabs?.[appView] : undefined;
            const newView = appView !== undefined && newTab !== undefined ? stored?.views?.[appView]?.[newTab] : undefined;
            this.#setAvailableTabs(undefined);
            this.#setAppView(appView);
            this.#setTab(newTab);
            this.#setView(newView);
            this.#store(appView, newTab, newView);
        }
    };
    isAdmin = () => {
        if (this.appView === undefined || this.#user == null) {
            return false;
        }
        const claims = this.#user.profile;
        const module = moduleFromApp(this.appView);
        const moduleAuthorities = claims.authorityMap[module];
        return moduleAuthorities?.some(a => a.includes("ADMIN"));
    };
    updateTab = (tab, view) => {
        const stored = this.#getStored();
        const newView = view === undefined && this.appView !== undefined && tab !== undefined ? stored?.views?.[this.appView]?.[tab] : view;
        if (tab !== this.tab || newView !== this.view) {
            this.#setTab(tab);
            this.#setView(newView);
            this.#store(this.appView, tab, newView);
        }
    };
    updateView = (view) => {
        if (view !== this.view) {
            this.#setView(view);
            this.#store(this.appView, this.tab, view);
        }
    };
}
export default NavigationService;
