import * as React from 'react';
import { Button, Icon } from '@symphony-ui/uitoolkit-components';
import Metadata from 'components/Metadata';
import PermissionsModal from 'components/permissions/PermissionsModal';
const MetadataButtons = ({ disabled, data, onChange }) => {
    const [modalContent, setModalContent] = React.useState('none');
    const hideModal = React.useCallback(() => {
        setModalContent('none');
    }, []);
    const clickShowMeta = React.useCallback(() => {
        setModalContent('meta');
    }, []);
    const clickShowPermissions = React.useCallback(() => {
        setModalContent('permissions');
    }, []);
    return (React.createElement("div", { className: "co-form-buttons" },
        modalContent === 'meta' ?
            (React.createElement(Metadata, { hideModal: hideModal, value: data })) : undefined,
        modalContent === 'permissions' ?
            React.createElement(PermissionsModal, { hideModal: hideModal, onChange: onChange, value: data?.permissions }) : undefined,
        data?.id ? (React.createElement(Button, { iconButton: true, onClick: clickShowMeta },
            React.createElement(Icon, { iconName: "info-round" }))) : '',
        !disabled ? (React.createElement(Button, { iconButton: true, onClick: clickShowPermissions },
            React.createElement(Icon, { iconName: "people" }))) : ''));
};
export default MetadataButtons;
