class Party {
    id;
    name;
    names;
    podId;
    shortName;
    constructor(response) {
        this.id = response.id;
        this.name = response.name;
        this.names = response.names;
        this.podId = response.podId;
        this.shortName = response.shortName;
    }
}
export default Party;
