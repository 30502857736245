import * as React from 'react';
import { flexRender, useReactTable, getCoreRowModel, } from '@tanstack/react-table';
import 'components/custom-table/CustomTable.css';
import { Loader, Typography } from '@symphony-ui/uitoolkit-components';
import ScrollContext from 'components/custom-table/ScrollContext';
import DraggableRow from './DraggableRow';
const TableDnd = function TableDndElement({ actionElements, columns, data, emptyMessage, errorMessage, hasError, isLoading, meta, setData, }) {
    const tableContainerRef = React.useRef(null);
    const reorderRow = React.useCallback((draggedRowIndex, targetRowIndex) => {
        data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
        setData([...data]);
    }, [data, setData]);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (originalRow, index) => index.toString(), // good to have guaranteed unique row ids/keys for rendering
        meta,
    });
    /** SCROLL DETAILS START */
    const bodyRef = React.useRef(null);
    const headRef = React.useRef(null);
    const [tbodyX, setTbodyX] = React.useState(0);
    const [tbodyY, setTbodyY] = React.useState(0);
    const [theadX, setTheadX] = React.useState(0);
    const [theadY, setTheadY] = React.useState(0);
    const [scrollX, setScrollX] = React.useState(0);
    const [scrollY, setScrollY] = React.useState(0);
    const onScroll = React
        .useCallback((event) => {
        setScrollX(event.currentTarget.scrollLeft || 0);
        setScrollY(event.currentTarget.scrollTop || 0);
    }, []);
    React.useEffect(() => {
        if (bodyRef.current !== null) {
            setTbodyX(bodyRef.current.getBoundingClientRect().x);
            setTbodyY(bodyRef.current.getBoundingClientRect().y);
        }
        if (headRef.current !== null) {
            setTheadX(headRef.current.getBoundingClientRect().x);
            setTheadY(headRef.current.getBoundingClientRect().y);
        }
    }, []);
    const scrollValue = React.useMemo(() => ({
        scrollX, scrollY, tbodyX, tbodyY, theadX, theadY,
    }), [scrollX, scrollY, tbodyX, tbodyY, theadX, theadY]);
    /** SCROLL DETAILS END */
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%' } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("div", { style: { display: 'flex' } }, actionElements)),
        React.createElement("div", { className: "co-table-container tk-theme-condensed", onScroll: onScroll, ref: tableContainerRef },
            React.createElement(ScrollContext.Provider, { value: scrollValue },
                React.createElement("table", { className: `co-table ${meta?.className ?? ''}` },
                    React.createElement("thead", { ref: headRef }, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { className: "co-header-row", key: headerGroup.id },
                        React.createElement("th", { "aria-label": "dnd-icon", className: "co-header", style: {
                                width: '3rem',
                            } }),
                        headerGroup.headers.map((header) => (React.createElement("th", { className: "co-header", colSpan: header.colSpan, key: header.id, style: {
                                width: (header.getSize() < 1000 ? header.getSize() : ''),
                            } },
                            React.createElement("div", { className: "co-header-wrapper" }, header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext()))))))))),
                    React.createElement("tbody", { ref: bodyRef, style: { position: 'relative' } }, !isLoading && table.getRowModel().rows.length > 0
                        ? table.getRowModel().rows.map((row) => (React.createElement(DraggableRow, { key: row.id, meta: meta, row: row, reorderRow: reorderRow }))) : null)),
                isLoading || table.getRowModel().rows.length === 0
                    ? (React.createElement("div", { className: 'co-loader-table' }, isLoading ?
                        React.createElement(Loader, { className: "co-center-page", variant: "primary" })
                        : React.createElement(Typography, { type: "h1" }, hasError ? errorMessage : emptyMessage)))
                    : null))));
};
export default TableDnd;
