import { Source } from '../../sources/sources';
import EuroclearFilter from '../filter/EuroclearFilter';
import EuroclearQuery from '../query/EuroclearQuery';
import MetaData from 'components/metadata/MetaData';
class EuroclearSearch extends MetaData {
    filter;
    name;
    query;
    constructor(response) {
        super(response);
        this.filter = response?.filter ? new EuroclearFilter(response.filter) : new EuroclearFilter();
        this.name = response?.name;
        this.query = response?.query ? new EuroclearQuery(response.query) : new EuroclearQuery();
    }
    clone() {
        return new EuroclearSearch({
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            filter: this.filter,
            id: this.id,
            lastModifiedBy: this.lastModifiedBy,
            lastModifiedDate: this.lastModifiedDate,
            name: this.name,
            permissions: this.permissions,
            query: this.query
        });
    }
    database() { return Source.EUROCLEAR; }
    updateName(value) {
        const clone = this.clone();
        clone.name = value;
        return clone;
    }
    updatePermissions(value) {
        const clone = this.clone();
        clone.permissions = value;
        return clone;
    }
}
export default EuroclearSearch;
