class QualifiedSearch {
    qualifier;
    text;
    constructor(response) {
        this.qualifier = response.qualifier ?? 'sw';
        this.text = response.text ?? [];
    }
    toString() {
        return `${this.qualifier} ${this.text.join(', ')}`;
    }
    clean() {
        return this.text.length ? this : null;
    }
    updateQualifier(value) {
        return new QualifiedSearch({ qualifier: value, text: this.text });
    }
    chooseTag(value) {
        return new QualifiedSearch({ qualifier: this.qualifier, text: [...this.text, value.toUpperCase()] });
    }
    clearTag() {
        return new QualifiedSearch({ qualifier: this.qualifier, text: [] });
    }
    removeTag(removingValue) {
        const newText = this.text.filter((el) => el !== removingValue.value);
        return new QualifiedSearch({ qualifier: this.qualifier, text: newText });
    }
    static create(base) {
        return new QualifiedSearch(base);
    }
    static get options() { return [{ label: 'Starts with', value: 'sw' }, { label: 'Equal to', value: 'eq' }, { label: 'Contains', value: 'ct' }]; }
}
export default QualifiedSearch;
