import { moduleFromApp, } from 'utils/appUtils';
class UserAccessService {
    user;
    csrf;
    constructor({ user, csrf }) {
        this.user = user;
        this.csrf = csrf;
    }
    authorizationHeader = () => {
        if (this.user == null) {
            return null;
        }
        return `Bearer ${this.user.access_token}`;
    };
    appAuthorities = (app) => {
        if (this.user == null) {
            return undefined;
        }
        const claims = this.user.profile;
        const name = moduleFromApp(app);
        if (claims.authorityMap[name] === undefined) {
            return undefined;
        }
        const values = claims.authorityMap[name];
        return values;
    };
    setUser = (user) => {
        this.setUser(user);
    };
    hasAuthority = (app, value) => {
        if (value === undefined) {
            return true;
        }
        const values = this.appAuthorities(app);
        if (values === undefined) {
            return false;
        }
        return values.includes(value) || values.includes(`ROLE_${value}`);
    };
    canAccess = (app) => {
        const claims = this.appAuthorities(app);
        return claims === undefined;
    };
    checkEditable = (object) => {
        if (this.user == null) {
            return false;
        }
        const claims = this.user.profile;
        return (object.createdBy === undefined || object.permissions === undefined ||
            object.permissions.other.write
            || (object.permissions.group.write && object.createdBy.company === claims.company)
            || (object.permissions.user.write && object.createdBy.id === claims.id));
    };
    // #setCsrfHeaders = async (signal?: AbortSignal): Promise<void> => {
    //   if (this.user != null && !this.user.expired) {
    //     const request = new ServerRequest('/csrf', { credentials: 'include', headers: { Authorization: `Bearer ${this.user.access_token}` } });
    //     try {
    //       const response: Response = await fetch(request, { signal });
    //       if (response.status === 200) {
    //         this.csrf = await response.json() as CSRF;
    //       }
    //     } catch (error) {
    //       //
    //     }
    //   }
    // };
    authorizedFetch = async (request, signal) => {
        if (this.user != null) {
            request.headers.append('Authorization', `Bearer ${this.user.access_token}`);
            if (request.method !== 'TRACE' && request.method !== 'HEAD' && request.method !== 'GET' && request.method !== 'OPTIONS') {
                // if (this.csrf === undefined) {
                //   await this.#setCsrfHeaders(signal);
                // }
                if (this.csrf !== undefined) {
                    request.headers.append(this.csrf.headerName, this.csrf.token);
                }
            }
        }
        else {
            console.warn('Trying authorized fetch without user ie without Authorization Header');
        }
        const response = await fetch(request, { signal });
        if (response.status === 401) {
            const event = new CustomEvent('logout');
            window.dispatchEvent(event);
        }
        return response;
    };
}
export default UserAccessService;
