import { Client, StompConfig, } from '@stomp/stompjs';
import ApiServerURL from 'models/ServerUrl';
import WebsocketURL from 'models/WebsocketUrl';
import SockJS from 'sockjs-client';
class WebSocketService {
    #client;
    #subscriptions;
    #subscribed;
    #pending;
    #token;
    #csrf;
    constructor(token, csrf) {
        this.#subscriptions = [];
        this.#subscribed = [];
        this.#pending = [];
        this.#client = new Client();
        this.#token = token;
        this.#csrf = csrf;
    }
    activate = () => {
        const stompConfig = this.#createStompConfig();
        if (stompConfig !== undefined) {
            this.#setupConfig(stompConfig);
        }
    };
    deactivate = async () => {
        await this.#client.deactivate();
    };
    #setupConfig = (stompConfig) => {
        try {
            this.#client = new Client(stompConfig);
            // Fallback code
            if (typeof WebSocket !== 'function') {
                // For SockJS you need to set a factory that creates a new SockJS instance
                // to be used for each (re)connect
                this.#client.webSocketFactory = () => new SockJS(new ApiServerURL('/stomp').toString());
            }
            this.#client.onChangeState = (a) => {
                //        console.log(`[ws] Current WebSocket state: ${a.toString()}`);
            };
            this.#client.onConnect = (frame) => {
                const connectedEvent = new CustomEvent('websocket.connected');
                document.dispatchEvent(connectedEvent);
                //        console.log('[ws] WebSocket connected: %o', frame);
            };
            this.#client.onDisconnect = (frame) => {
                const connectedEvent = new CustomEvent('websocket.diconnected');
                document.dispatchEvent(connectedEvent);
                //        console.log('[ws] Disconnected: %o', frame);
            };
            this.#client.onStompError = (frame) => {
                // Will be invoked in case of error encountered at Broker
                // Bad login/passcode typically will cause an error
                // Compliant brokers will set `message` header with a brief message. Body may contain details.
                // Compliant brokers will terminate the connection after any error
                //        console.log(`[ws] Broker reported error: ${frame.headers.message}. Additional details: ${frame.body}`);
            };
            this.#client.onUnhandledFrame = (frame) => {
                //        console.log('[ws] Unhandled Frame: %o', frame);
            };
            this.#client.onUnhandledMessage = (message) => {
                //        console.log('[ws] Unhandled Message: %o', message);
            };
            this.#client.onUnhandledReceipt = (receipt) => {
                //        console.log('[ws] Unhandled Receipt: %o', receipt);
            };
            this.#client.onWebSocketClose = (event) => {
                //        console.log('[ws] WebSocket Close: %o', event);
            };
            this.#client.onWebSocketError = (error) => {
                //        console.error('[ws] WebSocket Error: %o', error);
            };
            this.#client.activate();
        }
        catch (e) {
            console.error('Cannot connect to websocket: %o', e);
        }
    };
    addSubscription = (destination, callback) => {
        if (!this.#pending.includes(destination) && !this.#subscribed.includes(destination)) {
            this.#pending.push(destination);
            if (this.#client.connected) {
                this.#subscribe(destination, callback);
            }
            else {
                document.addEventListener('websocket.connected', () => {
                    if (this.#token !== undefined && (this.#token.expiresAt === undefined || this.#token.expiresAt * 1000 > Date.now())) {
                        this.#subscribe(destination, callback);
                    }
                });
            }
        }
    };
    removeSubscription = (destination) => {
        this.#unsubscribe(destination);
    };
    #unsubscribe = (destination) => {
        if (this.#client.connected) {
            this.#client.unsubscribe(destination);
            this.#subscribed = this.#subscribed.filter((s) => s !== destination);
            this.#subscribed.push(destination);
        }
    };
    #subscribe = (destination, callback) => {
        if (this.#client.connected) {
            const subscription = this.#client.subscribe(destination, callback);
            this.#subscriptions.push(subscription);
            this.#pending = this.#pending.filter((value) => value !== destination);
            this.#subscribed.push(destination);
        }
    };
    #getConnectHeaders = () => {
        if (this.#token === undefined || this.#csrf === undefined) {
            return undefined;
        }
        const authorizationHeader = JSON.stringify(this.#token);
        const stompHeaders = { [this.#csrf.headerName]: this.#csrf.token, Authorization: authorizationHeader };
        return stompHeaders;
    };
    #createStompConfig = () => {
        const stompHeaders = this.#getConnectHeaders();
        if (stompHeaders === undefined || this.#csrf === undefined) {
            return undefined;
        }
        const stompConfig = new StompConfig();
        const url = new WebsocketURL(`/ws?_csrf=${this.#csrf.token}`);
        stompConfig.brokerURL = url.toString();
        stompConfig.connectHeaders = stompHeaders;
        //    stompConfig.debug = (msg: string) => console.warn(`[ws debug] ${msg}`);
        stompConfig.logRawCommunication = true;
        stompConfig.beforeConnect = () => {
            if (this.#token !== undefined && this.#token.expiresAt !== undefined && this.#token.expiresAt * 1000 < Date.now()) {
                console.log("FIRST DEACTIVATING?? token expires at: %o", new Date(this.#token.expiresAt * 1000).toLocaleString());
                (async () => this.#client.deactivate())();
            }
        };
        return stompConfig;
    };
}
export default WebSocketService;
