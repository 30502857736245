var _a;
class Workflow {
    id;
    partiesBloomberg;
    partiesBooking;
    partiesIsin;
    partiesListing;
    partiesDocumentation;
    constructor(response) {
        this.id = response.id;
        this.partiesBloomberg = response.partiesBloomberg;
        this.partiesBooking = response.partiesBooking;
        this.partiesDocumentation = response.partiesDocumentation;
        this.partiesIsin = response.partiesIsin;
        this.partiesListing = response.partiesListing;
    }
    #clone() {
        return new _a({
            id: this.id,
            partiesBloomberg: this.partiesBloomberg,
            partiesBooking: this.partiesBooking,
            partiesDocumentation: this.partiesDocumentation,
            partiesIsin: this.partiesIsin,
            partiesListing: this.partiesListing
        });
    }
    updateId(value) {
        const clone = this.#clone();
        clone.id = value;
        return clone;
    }
    updateParties(name, value) {
        const clone = this.#clone();
        clone[name] = value;
        return clone;
    }
}
_a = Workflow;
export default Workflow;
