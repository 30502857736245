export function getOptions(value, dropdownOptions) {
    if (value === undefined) {
        return undefined;
    }
    const foundLabelValue = dropdownOptions.find((o) => ('value' in o && o.value === value));
    if (foundLabelValue !== undefined) {
        return foundLabelValue;
    }
    const subOptions = dropdownOptions.find((o) => ('options' in o && getOptions(value, o.options) !== undefined));
    return subOptions !== undefined ? getOptions(value, subOptions.options) : undefined;
}
;
export const getOptionsSource = (value, dropdownOptions) => {
    return getOptions(value, dropdownOptions);
};
export const getOptionsMulti = (values, dropdownOptions) => {
    const labelValues = [];
    if (values === undefined || dropdownOptions === undefined) {
        return undefined;
    }
    dropdownOptions.forEach((o) => {
        if ('value' in o) {
            if (values.includes(o.value)) {
                labelValues.push(o);
            }
        }
        else {
            const subOptionsValues = getOptionsMulti(values, o.options);
            if (subOptionsValues !== undefined) {
                labelValues.push(...subOptionsValues);
            }
        }
    });
    return labelValues;
};
