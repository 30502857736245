import FilterItem from '../FilterItem';
import EsmaSecurity from '../security/EsmaSecurity';
class EsmaFilter {
    exclude;
    name;
    values;
    tags;
    constructor(response) {
        this.exclude = response?.exclude ?? true;
        this.name = response?.name;
        this.values = response?.values ?? [];
        this.tags = response?.tags;
    }
    clone() {
        return new EsmaFilter({
            exclude: this.exclude,
            name: this.name,
            values: this.values,
            tags: this.tags
        });
    }
    updateName() {
        return (event) => {
            const clone = this.clone();
            clone.name = event.target.value;
            return clone;
        };
    }
    updateTags(event) {
        const newFilter = this.clone();
        if (newFilter.tags === undefined) {
            newFilter.tags = new FilterItem({
                exclude: false,
                values: [],
            });
        }
        newFilter.tags.values = event.target.value.map((t) => t.value);
        return newFilter;
    }
    create() {
        const test = (contains, name) => (this[name]?.exclude && !contains)
            ?? (!this[name]?.exclude && contains);
        return (s) => {
            if (s instanceof EsmaSecurity) {
                const tagsTest = this.tags?.values.length ? test(s.tags.some((t) => this.tags?.values.includes(t)), 'tags') : true;
                return tagsTest;
            }
            return false;
        };
    }
}
export default EsmaFilter;
