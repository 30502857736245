import * as React from 'react';
import { Button, Card, Dropdown, Switch, TextField, Validation, } from '@symphony-ui/uitoolkit-components';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import CustomValidators from 'components/CustomValidators';
import MetadataButtons from 'components/metadata/MetadataButtons';
import { errorMessage, initialValidation, } from 'utils/validation/initialValidation';
import SelectionStatus from '@symphony-ui/uitoolkit-components/components/selection/SelectionStatus';
import UserContext from 'contexts/UserContext';
import Report from '../../models/Report';
import FrequencyInput from '../frequency-input/FrequencyInput';
import useOption from 'components/customHooks';
const ReportForm = ({ report, save }) => {
    const { checkEditable } = React.useContext(UserContext);
    const [data, setData] = React.useState(report ?? Report.create());
    const [errorState, setErrorState] = React.useState({});
    const options = useOption('/nit/search/options');
    const validators = React.useMemo(() => ({
        frequency: CustomValidators.Frequency,
        name: [Validators.Required, Validators.MaxLength(24)],
        search: CustomValidators.RequiredOption,
    }), []);
    const disabled = React.useMemo(() => data.id === undefined || report === undefined ? false : !checkEditable(report), [checkEditable, data.id, report]);
    const title = React.useMemo(() => {
        if (data.id === undefined || report === undefined) {
            return 'Reports';
        }
        const isEditable = checkEditable(report);
        return isEditable ? 'Edit Report' : 'Report';
    }, [data.id, checkEditable, report]);
    React.useEffect(() => {
        (async () => {
            const initialErrorState = await initialValidation(validators, report);
            setErrorState(initialErrorState);
        })();
    }, [report, validators]);
    const disabledButton = React.useMemo(() => Object.values(errorState).some(v => v === false), [errorState]);
    const onChangeFrequency = React.useCallback((event) => {
        const { value } = event.target;
        const newReport = data.updateFrequency(value);
        setData(newReport);
    }, [data]);
    const onChangeInput = React.useCallback((name) => (event) => {
        const { value } = event.target;
        setData(data.update(name, value));
    }, [data]);
    const onChangePermissions = React.useCallback((event) => {
        setData(data.updatePermissions(event));
    }, [data]);
    const onChangeSelect = React.useCallback((event) => {
        const newValue = event.target.value;
        const newData = data.updateSearch(newValue.value, newValue.data);
        setData(newData);
    }, [data]);
    const onValidationChanged = (name) => (isValid) => {
        const newErrorState = { ...errorState, [name]: isValid };
        setErrorState(newErrorState);
    };
    // Give a name to the Report so that you can easily find it later.
    return (React.createElement(Card, null,
        React.createElement(CardHeaderForm, { newView: "TABLE", title: title }),
        React.createElement("div", { className: "base-form" },
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('search'), validator: validators.search },
                React.createElement(Dropdown, { isDisabled: disabled, label: "Search to run", onChange: onChangeSelect, name: "search", options: options, value: options.find(o => 'value' in o && 'data' in o && o.data === data.source && o.value === data.searchId) })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('name'), validator: validators.name },
                React.createElement(TextField, { disabled: disabled, id: "name", label: "Name", value: data.name ?? '', onChange: onChangeInput('name'), tooltip: "" })),
            React.createElement(Validation, { errorMessage: errorMessage, onValidationChanged: onValidationChanged('frequency'), validator: validators.frequency },
                React.createElement(FrequencyInput, { defaultPeriod: "MINUTES", disabled: disabled, label: "Frequency", onChange: onChangeFrequency, tooltip: "Set how often you want the report to run, and post into the stream. You can toggle whether you want it to run every day at a fixed time, or at an interval of a fixed number of minutes or hours.", value: data.frequency ?? undefined })),
            React.createElement(Switch, { disabled: disabled, label: "Show Empty Search", status: data.showEmpty ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: data.showEmpty ? 'true' : 'false', name: "showEmpty", onChange: onChangeInput })),
        React.createElement("div", { className: "co-form-buttons-plus co-item" },
            React.createElement(Button, { disabled: disabledButton, onClick: save(data) }, "Save"),
            React.createElement(MetadataButtons, { data: data, onChange: onChangePermissions, disabled: disabled }))));
};
export default ReportForm;
