import { Card, SelectionStatus, Switch, Typography, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import ApiServerURL from 'models/ServerUrl';
import { sendFetchError } from 'utils/messageUtils';
import SectorSelector from './SectorSelector';
import NavigationContext from 'contexts/NavigationContext';
import SyndNotificationSubscription from '../../models/SyndNotificationSubscription';
const SyndNotificationSettings = () => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { isAdmin } = React.useContext(NavigationContext);
    const [notification, setNotification] = React.useState(undefined);
    const pushId = React.useMemo(async () => {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const subscription = await serviceWorkerRegistration.pushManager.getSubscription();
        if (subscription === null) {
            return undefined;
        }
        const key = subscription.getKey('p256dh');
        const auth = subscription.getKey('auth');
        if (key === null || auth === null) {
            return undefined;
        }
        const auth8Array = new Uint8Array(auth);
        const authText = String.fromCharCode.apply(null, Array.from(auth8Array));
        const key8Array = new Uint8Array(key);
        const keyText = String.fromCharCode.apply(null, Array.from(key8Array));
        return {
            auth: window.btoa(authText),
            endpoint: subscription.endpoint,
            key: window.btoa(keyText),
        };
    }, []);
    const saveNotification = React.useCallback(async (newNotification) => {
        const p = await pushId;
        if (p !== undefined) {
            const formData = new FormData();
            formData.append('subscription', new Blob([JSON.stringify(p)], { type: 'application/json' }));
            formData.append('notifications', new Blob([JSON.stringify(newNotification)], { type: 'application/json' }));
            const request = new ServerRequest('/syndicate/notification-sub', { body: formData, method: 'POST' });
            try {
                const body = await apiFetchJson(request);
                const updatedSubscription = new SyndNotificationSubscription(body);
                setNotification(updatedSubscription);
            }
            catch (error) {
                console.error("ERROR: %o", error);
                sendFetchError('Unable to save notification', error, request);
            }
        }
    }, [apiFetchJson, pushId]);
    const onChangeAdminNotifications = React.useCallback(() => {
        const not = notification !== undefined ?
            notification.toggleAdmin()
            : new SyndNotificationSubscription({ admin: true });
        (async () => saveNotification(not))();
    }, [notification, saveNotification]);
    const updateFilter = React.useCallback(async (filter) => {
        const not = notification !== undefined ?
            notification.updateFilter(filter) :
            SyndNotificationSubscription.createFromFilter(filter);
        await saveNotification(not);
    }, [notification, saveNotification]);
    React.useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            const push = await pushId;
            if (push !== undefined) {
                const url = new ApiServerURL('/syndicate/notification-sub');
                url.searchParams.append('key', push.key);
                url.searchParams.append('auth', push.auth);
                url.searchParams.append('endpoint', push.endpoint);
                const request = new ServerRequest(url);
                try {
                    const body = await apiFetchJson(request, abortController.signal);
                    const newNotification = new SyndNotificationSubscription(body ?? {});
                    setNotification(newNotification);
                }
                catch (error) {
                    sendFetchError('Unable to load notification subscription', error, request);
                }
            }
        })();
    }, [apiFetchJson, pushId]);
    return (React.createElement(Card, { className: "co-card-stack", key: "NOTIFICATION", style: { padding: '1rem', height: 'auto' } },
        React.createElement(Typography, { type: "h1" }, "Push Notifications"),
        isAdmin() ?
            React.createElement(Switch, { label: "Get Admin Notifications", name: "admin-notifications", onChange: onChangeAdminNotifications, status: notification?.admin ? SelectionStatus.SWITCHED : SelectionStatus.UNSWITCHED, value: notification?.admin ? 'true' : 'false' }) :
            undefined,
        React.createElement(SectorSelector, { filter: notification?.filter, updateFilter: updateFilter })));
};
export default SyndNotificationSettings;
