import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
import DownloadContext from '../../../core/contexts/DownloadContext';
import ServerRequest from '../../../core/models/ServerRequest';
const ResultsPicker = ({ result, setDisabled, setResult, }) => {
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [placeHolder, setPlaceHolder] = React.useState('Select...');
    const { apiFetchJson } = React.useContext(DownloadContext);
    const onChangeResult = React.useCallback((event) => {
        const { data, label, value } = event.target.value;
        const newResult = result !== undefined && data !== undefined ? { id: value, source: data, label } : undefined;
        setResult(newResult);
    }, [setResult, result]);
    React.useEffect(() => {
        setDisabled(isLoading || hasError);
    }, [isLoading, hasError, setDisabled]);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/nit/results/options');
        (async () => {
            setLoading(true);
            try {
                const body = await apiFetchJson(request, abortController.signal);
                if (body.length > 0) {
                    setOptions(body);
                    const first = body[0];
                    const option = 'options' in first ? first.options[0] : first;
                    if (option.data !== undefined) {
                        const newResult = {
                            label: option.label,
                            source: option.data,
                            id: option.value,
                        };
                        setResult(newResult);
                    }
                }
                else {
                    setError(true);
                    setPlaceHolder('No results found. Please run a search first.');
                }
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError('Unable to download results options', error, request);
                    setError(true);
                    setPlaceHolder('Unable to download options. Please check connection');
                }
            }
            setLoading(false);
        })();
        return () => abortController.abort();
    }, [apiFetchJson, setResult]);
    return (React.createElement(Dropdown, { className: "co-dropdown", isDisabled: hasError || isLoading, label: "Please select from saved results", onChange: onChangeResult, options: options, placeHolder: placeHolder, value: options.find(o => 'value' in o && result?.id === o.value) }));
};
export default ResultsPicker;
