class User {
    id;
    accountNonExpired;
    accountNonLocked;
    credentialsNonExpired;
    enabled;
    company;
    companyId;
    displayName;
    authorityMap;
    username;
    password;
    firstName;
    lastName;
    emailAddress;
    title;
    jobFunction;
    location;
    department;
    division;
    mobilePhoneNumber;
    workPhoneNumber;
    static NEW = new User({});
    constructor(user) {
        this.id = user?.id;
        this.accountNonExpired = user?.accountNonExpired;
        this.accountNonLocked = user?.accountNonLocked;
        this.credentialsNonExpired = user?.credentialsNonExpired;
        this.enabled = user?.enabled;
        this.companyId = user?.companyId;
        this.company = user?.company;
        this.displayName = user?.displayName;
        this.authorityMap = user?.authorityMap;
        this.username = user?.username;
        this.password = user?.password;
        this.firstName = user?.firstName;
        this.lastName = user?.lastName;
        this.emailAddress = user?.emailAddress;
        this.title = user?.title;
        this.jobFunction = user?.jobFunction;
        this.location = user?.location;
        this.department = user?.department;
        this.division = user?.division;
        this.mobilePhoneNumber = user?.mobilePhoneNumber;
        this.workPhoneNumber = user?.workPhoneNumber;
    }
    toggleBooleanField(field) {
        const clone = new User(this);
        clone[field] = !clone[field];
        return clone;
    }
    updateField(field, value) {
        const clone = new User(this);
        clone[field] = value;
        return clone;
    }
    updateAuthorities(values) {
        const clone = new User(this);
        clone.authorityMap = undefined;
        values.forEach((value) => {
            if (value.data !== undefined) {
                if (clone.authorityMap === undefined) {
                    clone.authorityMap = {};
                }
                if (clone.authorityMap[value.data] === undefined) {
                    clone.authorityMap[value.data] = [];
                }
                clone.authorityMap[value.data]?.push(value.value);
            }
        });
        return clone;
    }
}
export default User;
