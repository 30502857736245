import * as React from 'react';
const TableContext = React.createContext({});
export const FiniteTableContext = React.createContext({});
export default TableContext;
export const InfiniteTableContextProvider = ({ children }) => {
    const [resetRowExpansion, setResetRowExpansion] = React.useState();
    const [resetRowSelection, setResetRowSelection] = React.useState();
    const [selection, setSelection] = React.useState();
    const [tableUpdateFn, setTableUpdateFn] = React.useState();
    const value = React.useMemo(() => {
        const t = {
            resetRowExpansion, setResetRowExpansion, tableUpdateFn, selection, setSelection, resetRowSelection, setResetRowSelection, setTableUpdateFn,
        };
        return t;
    }, [resetRowExpansion, setResetRowExpansion, resetRowSelection, setResetRowSelection, selection, setSelection, tableUpdateFn, setTableUpdateFn]);
    return React.createElement(TableContext.Provider, { value: value }, children);
};
export const FiniteTableContextProvider = ({ children }) => {
    const [resetRowExpansion, setResetRowExpansion] = React.useState();
    const [resetRowSelection, setResetRowSelection] = React.useState();
    const [selection, setSelection] = React.useState();
    const [tableUpdateFn, setTableUpdateFn] = React.useState();
    const value = React.useMemo(() => {
        const t = {
            resetRowExpansion, setResetRowExpansion, tableUpdateFn, selection, setSelection, resetRowSelection, setResetRowSelection, setTableUpdateFn,
        };
        return t;
    }, [resetRowExpansion, setResetRowExpansion, resetRowSelection, setResetRowSelection, selection, setSelection, tableUpdateFn, setTableUpdateFn]);
    return React.createElement(FiniteTableContext.Provider, { value: value }, children);
};
