import printGuidance, { benchmarkName, printInterestRateBenchmark } from './printGuidance';
const textToNumber = (text) => {
    const numberRegex = /^-?[\d,.]+$/;
    if ((numberRegex.exec(text)) === null) {
        return text;
    }
    const parsed = Number.parseFloat(text);
    return Number.isNaN(parsed) ? text : parsed;
};
const printIntlNumber = (value) => Intl.NumberFormat().format(value);
export default {
    amount: ({ data, overrideMap }) => {
        if (overrideMap?.amount && typeof overrideMap.amount === 'string') {
            return textToNumber(overrideMap.amount);
        }
        if (data?.nominal?.tapAmount?.size !== undefined) {
            if (typeof data.nominal.tapAmount.size.value === 'number') {
                return data.nominal.tapAmount.size.value / 1000000;
            }
        }
        if (data?.nominal?.amount?.size !== undefined) {
            const { size } = data.nominal.amount;
            if (size.value !== undefined) {
                if (size.qualifier !== undefined) {
                    const printQualifier = (q) => {
                        switch (q) {
                            case 'EXPECTED':
                                return 'exp.';
                            case 'INDICATIVE':
                                return 'ind.';
                            case 'MINIMUM':
                                return 'min.';
                            case 'UP_TO':
                                return 'max.';
                            case 'WILL_NOT_GROW':
                                return 'wng';
                            default:
                                return q;
                        }
                    };
                    return `${printIntlNumber(size.value / 1000000)} (${printQualifier(size.qualifier)})`;
                }
                return size.value / 1000000;
            }
            if (size.range !== undefined) {
                const { minimum, maximum } = size.range;
                return `${minimum ? printIntlNumber(minimum / 1000000) : '?'} - ${maximum ? printIntlNumber(maximum / 1000000) : '?'}`;
            }
            if (size.isBenchmark) {
                return 'Benchmark';
            }
            if (size.isSubBenchmark) {
                return 'Sub Benchmark';
            }
        }
        return undefined;
    },
    book: ({ data, overrideMap }) => {
        if (overrideMap?.books && typeof overrideMap.books === 'string') {
            return textToNumber(overrideMap.books);
        }
        if (data?.management?.books?.size?.value !== undefined) {
            return data.management.books.size.value / 1000000;
        }
        return undefined;
    },
    bookrunners: ({ meta, overrideMap }) => {
        if (overrideMap?.bookrunners && typeof overrideMap.bookrunners === 'string') {
            return overrideMap.bookrunners;
        }
        if (meta?.bookrunners !== undefined) {
            if (meta.bookrunners.length > 8) {
                const jgcs = meta.bookrunners.filter((b) => b.roles?.includes('GLOBAL_COORDINATOR'));
                if (jgcs.length === 1) {
                    return `SGC: ${jgcs[0].name}`;
                }
                else if (jgcs.length > 1) {
                    return `JGCs: ${jgcs.map((m) => m.name).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())).join(', ')}`;
                }
            }
            return meta.bookrunners.map((m) => m.name).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())).join(', ');
        }
        return undefined;
    },
    coupon: ({ data, overrideMap }) => {
        if (overrideMap?.coupon && typeof overrideMap.coupon === 'string') {
            return textToNumber(overrideMap.coupon);
        }
        if (data !== undefined) {
            const coupons = data.coupons ?? data.profitRates;
            if (coupons !== undefined) {
                const [coupon] = coupons;
                if (coupon.fixedRate?.value !== undefined) {
                    if (coupon.fixedRate.indicative === true) {
                        return `${coupon.fixedRate.value} (indic.)`;
                    }
                    return coupon.fixedRate.value;
                }
                if (coupon.floatingRate?.referenceRate !== undefined && coupon.floatingRate.margin !== undefined) {
                    const items = [];
                    items.push(printInterestRateBenchmark(coupon.floatingRate.referenceRate));
                    const margin = ` ${coupon.floatingRate.margin > 0 ? '+' : ''}${coupon.floatingRate.margin.toString() || 'flat'}`;
                    items.push(margin);
                    return items.join('');
                }
            }
        }
        return undefined;
    },
    currency: ({ data, overrideMap }) => overrideMap?.currency ?? data?.nominal?.amount?.currency ?? data?.nominal?.tapAmount?.currency,
    documentation: (map, field) => ({ data, overrideMap }) => {
        if (overrideMap && field in overrideMap) {
            const val = overrideMap[field];
            if (typeof val === 'string') {
                return map.get(val) ?? '';
            }
        }
        if (data?.documentation?.[field] !== undefined) {
            return map.get(data.documentation[field]);
        }
        return undefined;
    },
    documentationArray: (map, field) => ({ data, overrideMap }) => {
        if (overrideMap && field in overrideMap && typeof overrideMap[field] === 'string') {
            const val = overrideMap[field];
            if (typeof val === 'string') {
                return val.split(',').map((c) => map.get(c)).join(' ');
            }
        }
        if (data?.documentation?.[field] !== undefined) {
            return data.documentation[field].map((c) => map.get(c)).join(' ');
        }
        return undefined;
    },
    guidance: (type) => ({ data, overrideMap }) => {
        if (overrideMap?.[type]) {
            return overrideMap[type];
        }
        if (data === undefined) {
            return undefined;
        }
        if (type === 'initialPriceThoughts') {
            if (data.initialPriceThoughts !== undefined) {
                return printGuidance(data.initialPriceThoughts);
            }
            if (data.guidance !== undefined && data.revisedGuidance !== undefined) {
                return printGuidance(data.guidance);
            }
        }
        if (type === 'guidance') {
            const items = [];
            if ((data.revisedGuidance === undefined || data.initialPriceThoughts !== undefined) && data.guidance !== undefined) {
                items.push(printGuidance(data.guidance));
            }
            if (data.revisedGuidance !== undefined) {
                items.push(`revised to ${printGuidance(data.revisedGuidance)}`);
            }
            return items.join(' ');
        }
        return undefined;
    },
    interestBasis: ({ data, overrideMap }) => {
        if (overrideMap?.interestBasis) {
            return overrideMap.interestBasis;
        }
        if (overrideMap?.coupon) {
            return Number.isNaN(Number.parseFloat(overrideMap.coupon)) ? 'FLOATING' : 'FIXED';
        }
        if (data?.coupons !== undefined) {
            const [coupon] = data.coupons;
            if (coupon.interestBasis !== undefined) {
                return coupon.interestBasis;
            }
            if (coupon.fixedRate !== undefined) {
                return 'FIXED';
            }
            if (coupon.floatingRate !== undefined) {
                return 'FLOATING';
            }
        }
        if (data?.profitRates !== undefined) {
            const [coupon] = data.profitRates;
            if (coupon.interestBasis !== undefined) {
                return coupon.interestBasis;
            }
            if (coupon.fixedRate !== undefined) {
                return 'FIXED';
            }
            if (coupon.floatingRate !== undefined) {
                return 'FLOATING';
            }
        }
        return undefined;
    },
    iptMove: ({ data, overrideMap }) => {
        if (overrideMap?.iptMove) {
            return textToNumber(overrideMap.iptMove);
        }
        if (data?.pricing !== undefined) {
            if (data.pricing.spread?.value !== undefined) {
                const { value } = data.pricing.spread;
                const reoffer = value;
                if (data.initialPriceThoughts?.spread !== undefined) {
                    if (data.initialPriceThoughts.spread.value !== undefined) {
                        return reoffer - data.initialPriceThoughts.spread.value.value;
                    }
                    if (data.initialPriceThoughts.spread.range !== undefined) {
                        return reoffer - data.initialPriceThoughts.spread.range.minimum;
                    }
                }
                if (data.guidance?.spread !== undefined) {
                    if (data.guidance.spread.value !== undefined) {
                        return reoffer - data.guidance.spread.value.value;
                    }
                    if (data.guidance.spread.range !== undefined) {
                        return reoffer - data.guidance.spread.range.minimum;
                    }
                }
                if (data.revisedGuidance?.spread !== undefined) {
                    if (data.revisedGuidance.spread.value !== undefined) {
                        return reoffer - data.revisedGuidance.spread.value.value;
                    }
                    if (data.revisedGuidance.spread.range !== undefined) {
                        return reoffer - data.revisedGuidance.spread.range.minimum;
                    }
                }
            }
            if (data.pricing.yield?.value !== undefined) {
                const reofferYield = data.pricing.yield.value;
                if (data.initialPriceThoughts?.yield !== undefined) {
                    if (data.initialPriceThoughts.yield.value !== undefined) {
                        return 100 * (reofferYield - data.initialPriceThoughts.yield.value.value);
                    }
                    if (data.initialPriceThoughts.yield.range !== undefined) {
                        return 100 * (reofferYield - data.initialPriceThoughts.yield.range.minimum);
                    }
                }
                if (data.guidance?.yield !== undefined) {
                    if (data.guidance.yield.value) {
                        return 100 * (reofferYield - data.guidance.yield.value.value);
                    }
                    if (data.guidance.yield.range !== undefined) {
                        return 100 * (reofferYield - data.guidance.yield.range.minimum);
                    }
                }
                if (data.revisedGuidance?.yield !== undefined) {
                    if (data.revisedGuidance.yield.value !== undefined) {
                        return 100 * (reofferYield - data.revisedGuidance.yield.value.value);
                    }
                    if (data.revisedGuidance.yield.range !== undefined) {
                        return 100 * (reofferYield - data.revisedGuidance.yield.range.minimum);
                    }
                }
            }
        }
        return '';
    },
    isins: ({ data, overrideMap }) => {
        if (overrideMap?.isin !== undefined) {
            return overrideMap.isin;
        }
        if (data?.identifiers !== undefined) {
            return data.identifiers.filter((i) => i.type === 'ISIN' && 'value' in i)
                .map((i) => i.value + ('regulation' in i ? ` (${i.regulation === 'REGS' ? 'RegS' : '144A'})` : '')).join(', ');
        }
        return undefined;
    },
    issuer: ({ data, overrideMap }) => {
        if (overrideMap?.issuer !== undefined) {
            return overrideMap.issuer;
        }
        if (data?.obligor?.name !== undefined) {
            return `${data.obligor.name.replace(/, acting through.*/, '')} (Obligor)`;
        }
        if (data?.issuer !== undefined) {
            const text = [];
            if (data.issuer.name != null) {
                text.push(data.issuer.name.replace(/, acting through.*/, ''));
            }
            else if (data.issuer.shortName != null) {
                text.push(data.issuer.shortName);
            }
            if (data.issuer.branche != null) {
                text.push(`(${data.issuer.branche})`);
            }
            return text.join(' ');
        }
        return undefined;
    },
    issuerRaw: ({ data, overrideMap }) => {
        if (data?.obligor?.name !== undefined) {
            return `${data.obligor.name.replace(/, acting through.*/, '')} (Obligor)`;
        }
        if (data?.issuer !== undefined) {
            const text = [];
            if (data.issuer.name != null) {
                text.push(data.issuer.name.replace(/, acting through.*/, ''));
            }
            else if (data.issuer.shortName != null) {
                text.push(data.issuer.shortName);
            }
            if (data.issuer.branche != null) {
                text.push(`(${data.issuer.branche})`);
            }
            if (text.length > 0) {
                return text.join(' ');
            }
        }
        return overrideMap?.issuer;
    },
    lei: ({ meta, overrideMap }) => overrideMap?.lei ?? meta?.lei,
    maturity: ({ data, overrideMap }) => {
        const override = overrideMap?.maturity;
        if (override !== undefined)
            return override;
        if (data?.maturity !== undefined) {
            const { maturity } = data;
            if ('scheduledDissolutionDate' in data.maturity) {
                return data.maturity.scheduledDissolutionDate;
            }
            const { date } = maturity;
            if (date !== undefined) {
                const { dayOfMonth, monthValue, year } = date;
                const total = [];
                if (year !== undefined)
                    total.push(year);
                if (monthValue !== undefined)
                    total.push(monthValue.toString().padStart(2, '0'));
                if (dayOfMonth !== undefined)
                    total.push(dayOfMonth.toString().padStart(2, '0'));
                return total.join('-');
            }
            if (maturity.perpetual || maturity.tenor?.perpetual) {
                return 'Perpetual';
            }
        }
        return undefined;
    },
    oversubscription: ({ data, overrideMap }) => {
        if (overrideMap?.oversubscription !== undefined) {
            return textToNumber(overrideMap.oversubscription);
        }
        let bookSize;
        if (overrideMap?.books !== undefined) {
            const overrideBookSize = Number.parseInt(overrideMap.books, 10) * 1000000;
            if (!Number.isNaN(overrideBookSize)) {
                bookSize = overrideBookSize;
            }
        }
        if (bookSize === undefined && data?.management?.books?.size?.value !== undefined) {
            bookSize = data.management.books.size.value;
        }
        let amount;
        if (overrideMap?.amount !== undefined) {
            const overrideAmount = Number.parseInt(overrideMap.amount, 10) * 1000000;
            if (!Number.isNaN(overrideAmount)) {
                amount = overrideAmount;
            }
        }
        if (amount === undefined && data?.nominal?.amount?.size?.value !== undefined) {
            amount = data.nominal.amount.size.value;
        }
        if (amount !== undefined && bookSize !== undefined) {
            return bookSize / amount;
        }
        return undefined;
    },
    pricing: ({ data, overrideMap }) => {
        if (overrideMap?.pricing !== undefined) {
            return overrideMap.pricing;
        }
        if (data !== undefined) {
            const isYieldBase = ((data.initialPriceThoughts?.spread === undefined && data.initialPriceThoughts?.yield !== undefined)
                || (data.guidance?.spread === undefined && data.guidance?.yield !== undefined));
            if (data.pricing !== undefined) {
                const items = [];
                if (!isYieldBase) {
                    if (data.pricing.spread !== undefined) {
                        const { spread } = data.pricing;
                        if (spread.benchmark !== undefined) {
                            items.push(benchmarkName(spread.benchmark));
                        }
                        if (spread.value !== undefined) {
                            items.push((spread.value > 0 ? '+' : '') + spread.value.toString());
                        }
                    }
                    else {
                        const yieldPricing = data.pricing.yield;
                        if (yieldPricing?.value !== undefined) {
                            items.push(`${yieldPricing.value.toString()}% `);
                        }
                    }
                    return items.join(' ');
                }
                const yieldPricing = data.pricing.yield;
                if (yieldPricing?.value !== undefined) {
                    items.push(`${yieldPricing.value.toString()}% `);
                    return items.join(' ');
                }
            }
        }
        return '';
    },
    pricingDate: ({ meta, overrideMap }) => {
        if (overrideMap?.pricingDate === 'TBC') {
            return undefined;
        }
        return overrideMap?.pricingDate !== undefined ? new Date(overrideMap.pricingDate) : meta?.pricingDate;
    },
    ratings: ({ data, overrideMap }) => {
        if (overrideMap?.ratings && typeof overrideMap.ratings === 'string') {
            return overrideMap.ratings;
        }
        const print = (ratings) => {
            if (ratings.unrated) {
                return 'Unrated';
            }
            const values = ratings.values ?? [];
            if (values.filter((r) => r.agency !== undefined).length === 0) {
                const text = values.map((r) => r.value);
                return text.join('/');
            }
            const moodys = values.find((r) => r.agency === 'MOODYS');
            const sp = values.find((r) => r.agency === 'S_P');
            const fitch = values.find((r) => r.agency === 'FITCH');
            const text = [];
            if (typeof moodys !== 'undefined') {
                text.push(moodys.value);
            }
            if (typeof sp !== 'undefined') {
                const suffix = typeof fitch === 'undefined' && typeof moodys === 'undefined' ? ' (S&P)' : '';
                text.push(sp.value + suffix);
            }
            else if (typeof fitch !== 'undefined' && typeof moodys !== 'undefined') {
                text.push('-');
            }
            if (typeof fitch !== 'undefined') {
                const suffix = typeof sp === 'undefined' && typeof moodys === 'undefined' ? ' (Fitch)' : '';
                text.push(fitch.value + suffix);
            }
            else if (typeof sp !== 'undefined' && typeof moodys !== 'undefined') {
                text.push('-');
            }
            return text.join('/');
        };
        if (((data?.ratings?.values !== undefined && data.ratings.values.length > 0) || data?.ratings?.unrated)) {
            return print(data.ratings);
        }
        if (data?.issuer?.ratings !== undefined && ((data.issuer.ratings.values !== undefined && data.issuer.ratings.values.length > 0) || data.issuer.ratings.unrated)) {
            return print(data.issuer.ratings);
        }
        return undefined;
    },
    regions: (map) => ({ transaction }) => {
        return transaction.regions?.map((t) => map.get(t)).join(', ');
    },
    sector: (map) => ({ transaction }) => {
        return transaction.sector ? map.get(transaction.sector) : undefined;
    },
    settlementDate: ({ data, overrideMap }) => {
        if (overrideMap?.settlementDate === 'TBC') {
            return undefined;
        }
        const settlementDateString = overrideMap?.settlementDate ?? data?.settlement?.date;
        return settlementDateString !== undefined ? new Date(settlementDateString) : undefined;
    },
    spread: ({ data, overrideMap }) => {
        if (overrideMap?.spread) {
            return textToNumber(overrideMap.spread);
        }
        if (overrideMap?.pricing && typeof overrideMap.pricing === 'string' && !overrideMap.pricing.trimEnd().endsWith('%')
            && (overrideMap.pricing.includes("+") || overrideMap.pricing.includes("-"))) {
            const [first] = overrideMap.pricing.split(/[+-]/);
            return textToNumber(overrideMap.pricing.substring(first.length));
        }
        if (data !== undefined) {
            const isYieldBase = ((data.initialPriceThoughts?.spread === undefined && data.initialPriceThoughts?.yield !== undefined)
                || (data.guidance?.spread === undefined && data.guidance?.yield !== undefined));
            if (!isYieldBase && data.pricing?.spread != null) {
                const { spread } = data.pricing;
                if ('value' in spread) {
                    return spread.value;
                    //          return (spread.value > 0 ? '+' : '') + spread.value;
                }
            }
        }
        return undefined;
    },
    status: (map) => ({ meta, overrideMap }) => {
        if (overrideMap?.status !== undefined) {
            return overrideMap.status;
        }
        return meta?.status !== undefined ? map.get(meta.status) : undefined;
    },
    tap: ({ meta, overrideMap }) => {
        if (overrideMap?.tap?.toUpperCase() !== undefined) {
            return overrideMap.tap.toUpperCase() === 'TRUE';
        }
        return meta?.tap;
    },
    tenor: ({ data, meta, overrideMap }) => {
        if (overrideMap?.tenor !== undefined) {
            return overrideMap.tenor;
        }
        if (data !== undefined) {
            const { maturity } = data;
            let matMonth = null;
            let matYear = null;
            if (overrideMap?.maturity) {
                const dateRegex = /(?<year>[0-9]{4})(-(?<month>[0-9]{2})?)(-(?<day>[0-9]{2})?)/;
                const dateMatchArray = dateRegex.exec(overrideMap.maturity);
                const overrideDateGroups = dateMatchArray?.groups;
                if (overrideDateGroups !== undefined) {
                    const { year, month } = overrideDateGroups;
                    matYear = Number.parseInt(year, 10);
                    if (typeof month === 'string')
                        matMonth = Number.parseInt(month, 10);
                }
            }
            else if (maturity?.date !== undefined) {
                matYear = maturity.date.year ?? null;
                matMonth = maturity.date.monthValue ?? null;
            }
            if (matYear !== null && matMonth !== null && (overrideMap?.tap?.toUpperCase() === 'TRUE' || meta?.tap)) {
                return new Date(matYear, matMonth - 1, 1).toLocaleDateString((new Intl.DateTimeFormat()).resolvedOptions().locale, { month: 'short', year: '2-digit' });
            }
            if (maturity !== undefined) {
                if (maturity.tenor !== undefined) {
                    const text = [];
                    if (maturity.tenor.qualifier === 'LONG') {
                        text.push('Long ');
                    }
                    else if (maturity.tenor.qualifier === 'SHORT') {
                        text.push('Short ');
                    }
                    if (maturity.tenor.value != null) {
                        const { value } = maturity.tenor;
                        if (value.periodMultiplier !== undefined) {
                            text.push(value.periodMultiplier.toString());
                        }
                    }
                    else if (maturity.tenor.perpetual) {
                        text.push('Perp');
                    }
                    if (maturity.tenor.nonCall?.periodMultiplier !== undefined) {
                        text.push(`NC${maturity.tenor.nonCall.periodMultiplier.toString()} `);
                    }
                    else if (maturity.tenor.value?.period !== undefined) {
                        text.push(maturity.tenor.value.period);
                    }
                    return text.join('');
                }
                if (maturity.weightedAverageLife?.periodMultiplier !== undefined) {
                    return `${maturity.weightedAverageLife.periodMultiplier.toString()}${maturity.weightedAverageLife.period} WAL`;
                }
            }
            if (matMonth !== null && matYear !== null) {
                const today = new Date();
                const years = (matYear - today.getFullYear())
                    + (matMonth - today.getMonth() - 1) / 12;
                const rounded = Math.round(years * 10) / 10;
                return `[${rounded.toString()}Y]`;
            }
        }
        return undefined;
    },
    ticker: ({ meta, overrideMap }) => overrideMap?.ticker ?? meta?.ticker,
    timing: (type) => ({ data }) => {
        if (data?.management !== undefined) {
            const text = [];
            const value = data.management[type];
            if (value?.times !== undefined) {
                const time = value.times.find((t) => t.time !== undefined);
                if (time?.time !== undefined) {
                    text.push(time.time.substring(0, 5));
                    if ('zone' in time) {
                        text.push(time.zone);
                    }
                    if (value.times[0].time === undefined) {
                        text.push('?!');
                    }
                    return text.join(' ');
                }
            }
        }
        return undefined;
    },
    types: (map) => ({ transaction }) => {
        return transaction.types?.map((t) => map.get(t)).join(', ');
    },
    yield: ({ data, overrideMap }) => (overrideMap?.yield !== undefined ? textToNumber(overrideMap.yield) : data?.pricing?.yield?.value),
};
