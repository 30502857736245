import UserContext from 'contexts/UserContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError, setOnline } from 'utils/messageUtils';
const CACHE = "options";
export default function useOption(url, options) {
    const [data, setData] = React.useState([]);
    const { authorizedFetch } = React.useContext(UserContext);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest(url);
        (async () => {
            const cache = await caches.open(CACHE);
            const matchedRequest = await cache.match(request);
            if (matchedRequest !== undefined && (options === undefined || options.cache)) {
                const body = await matchedRequest.json();
                setData(body);
            }
            else {
                try {
                    const response = await authorizedFetch(request, abortController.signal);
                    setOnline();
                    if (response.status === 200) {
                        await cache.put(request, response.clone());
                        const body = await response.json();
                        const hasLabel = (value) => typeof value === 'object' && value !== null && 'label' in value;
                        const sorted = body.sort((a, b) => hasLabel(a) && hasLabel(b) ? (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1) : 0);
                        setData(sorted);
                    }
                }
                catch (error) {
                    sendFetchError('Unable to download options', error, request);
                }
            }
        })();
        return () => abortController.abort();
    }, [authorizedFetch, options, url]);
    return data;
}
export async function removeOption(url) {
    const cache = await caches.open(CACHE);
    const request = new ServerRequest(url);
    await cache.delete(request);
}
