import { Button, TextField } from '@symphony-ui/uitoolkit-components';
import TableContext from 'components/custom-table/TableContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import * as React from 'react';
import { sendFetchError } from 'utils/messageUtils';
const WebPushTestForm = ({ responses, setResponses }) => {
    const { apiFetchJson } = React.useContext(DownloadContext);
    const { selection } = React.useContext(TableContext);
    const [isLoading, setLoading] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [body, setBody] = React.useState('');
    const [icon, setIcon] = React.useState('maskable_cmo_icon_x512.png');
    const [actionButton, setActionButton] = React.useState('maskable_cmo_icon_x48.png');
    const [badge, setBadge] = React.useState('maskable_cmo_icon_x48.png');
    const onChangeTitle = React.useCallback((event) => {
        setTitle(event.target.value);
    }, []);
    const onChangeBody = React.useCallback((event) => {
        setBody(event.target.value);
    }, []);
    const onChangeIcon = React.useCallback((event) => {
        setIcon(event.target.value);
    }, []);
    const onChangeActionButton = React.useCallback((event) => {
        setActionButton(event.target.value);
    }, []);
    const onChangeBadge = React.useCallback((event) => {
        setBadge(event.target.value);
    }, []);
    const onClickSend = React.useCallback((path) => () => {
        if (selection !== undefined) {
            const pushData = {
                options: {
                    actions: [
                        {
                            action: 'nothing', icon: `/assets/${actionButton}`, title: 'CLICK ME',
                        },
                    ],
                    body,
                    badge: `/assets/${badge}`,
                    icon: `/assets/${icon}`,
                },
                title,
            };
            const formData = new FormData();
            formData.append('ids', new Blob([JSON.stringify(selection.map((s) => s.id))], { type: 'application/json' }));
            formData.append('notification', new Blob([JSON.stringify(pushData)], { type: 'application/json' }));
            const init = { body: formData, method: 'POST' };
            const request = new ServerRequest(`/notification/test-${path}`, init);
            (async () => {
                setLoading(true);
                try {
                    const responseBody = await apiFetchJson(request);
                    const clone = [...responses];
                    const updatedResponse = clone.map((r) => {
                        const updated = responseBody.find((d) => d.id === r.id);
                        const copy = { ...r };
                        copy.statusCode = updated !== undefined ? updated.statusCode : -1;
                        copy.body = updated !== undefined ? updated.body : '';
                        return copy;
                    });
                    setResponses(updatedResponse);
                }
                catch (error) {
                    sendFetchError('Error testing web push', error, request);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [actionButton, apiFetchJson, badge, body, icon, responses, selection, setResponses, title]);
    const disabled = React.useMemo(() => isLoading, [isLoading]);
    return (React.createElement("div", { style: { display: 'flex', width: '100%' } },
        React.createElement("div", { style: { marginRight: '1rem', width: '100%' } },
            React.createElement(TextField, { disabled: disabled, label: "Title", onChange: onChangeTitle, value: title })),
        React.createElement("div", { style: { marginRight: '1rem', width: '100%' } },
            React.createElement(TextField, { disabled: disabled, label: "Body", onChange: onChangeBody, value: body })),
        React.createElement("div", { style: { marginRight: '1rem', width: '100%' } },
            React.createElement(TextField, { disabled: disabled, label: "Icon", onChange: onChangeIcon, value: icon })),
        React.createElement("div", { style: { marginRight: '1rem', width: '100%' } },
            React.createElement(TextField, { disabled: disabled, label: "Badge", onChange: onChangeBadge, value: badge })),
        React.createElement("div", { style: { marginRight: '1rem', width: '100%' } },
            React.createElement(TextField, { disabled: disabled, label: "Action Button", onChange: onChangeActionButton, value: actionButton })),
        React.createElement("div", { style: { marginLeft: '1rem', marginTop: '1.5rem' } },
            React.createElement(Button, { disabled: disabled, loading: isLoading, onClick: onClickSend('user'), type: "button" }, "SEND"))));
};
export default WebPushTestForm;
