import * as React from 'react';
import DownloadContext from 'contexts/DownloadContext';
import LoadingPage from 'components/loading-page/LoadingPage';
import NavigationContext from 'contexts/NavigationContext';
import { sendFetchError } from 'utils/messageUtils';
import Benchmark from '../../models/Benchmark';
import BenchmarkTable from './BenchmarkTable';
import BenchmarkForm from './BenchmarkForm';
import ServerRequest from 'models/ServerRequest';
const Benchmarks = () => {
    const { view, updateView } = React.useContext(NavigationContext);
    const { apiFetchJson, apiFetchNoContent } = React.useContext(DownloadContext);
    const [benchmarks, setBenchmarks] = React.useState([]);
    const [details, setDetails] = React.useState();
    const [hasError, setError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const abortController = new AbortController();
        const request = new ServerRequest('/core/benchmarks');
        (async () => {
            try {
                const body = await apiFetchJson(request, abortController.signal);
                const downloadedBenchmarks = body.map((u) => new Benchmark(u));
                setBenchmarks(downloadedBenchmarks);
            }
            catch (error) {
                if (!(error instanceof Error && error.name === 'AbortError')) {
                    sendFetchError('Unable to load benchmarks', error, request);
                    setError(true);
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => abortController.abort();
    }, [apiFetchJson]);
    const create = React.useCallback(() => {
        setDetails(new Benchmark({}));
        updateView('FORM');
    }, [updateView]);
    const deleteFn = React.useCallback(({ row: { original: benchmark } }) => {
        const requestInit = { body: JSON.stringify(benchmark), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' };
        const request = new ServerRequest('/core/benchmarks', requestInit);
        try {
            (async () => {
                await apiFetchNoContent(request);
                const filteredBenchmarks = benchmarks.filter((u) => u.id !== benchmark.id);
                setBenchmarks(filteredBenchmarks);
            })();
        }
        catch (error) {
            sendFetchError('Unable to delete benchmark', error, request);
        }
    }, [apiFetchNoContent, benchmarks]);
    const edit = React.useCallback(({ row: { original: benchmark } }) => {
        setDetails(benchmark);
        updateView('FORM');
    }, [updateView]);
    const save = React.useCallback(async (data) => {
        const method = data.id ? 'PUT' : 'POST';
        const requestInit = { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method };
        const request = new ServerRequest('/core/benchmarks', requestInit);
        try {
            const body = await apiFetchJson(request);
            const updatedBenchmark = new Benchmark(body);
            const index = benchmarks.findIndex((d) => d.id === updatedBenchmark.id);
            const updatedCurrencies = [...benchmarks];
            if (index === -1) {
                updatedCurrencies.push(updatedBenchmark);
            }
            else {
                updatedCurrencies[index] = updatedBenchmark;
            }
            setBenchmarks(updatedCurrencies);
            updateView('TABLE');
        }
        catch (error) {
            sendFetchError('Unable to save Benchmark', error, request);
        }
    }, [apiFetchJson, benchmarks, updateView]);
    React.useEffect(() => {
        if (view === undefined) {
            updateView('TABLE');
        }
    }, [view, updateView]);
    switch (view) {
        case 'FORM':
            return (React.createElement(BenchmarkForm, { benchmark: details, save: save }));
        case 'TABLE':
            return (React.createElement(BenchmarkTable, { create: create, deleteFn: deleteFn, edit: edit, hasError: hasError, isLoading: isLoading, benchmarks: benchmarks }));
        default:
            updateView('TABLE');
            return (React.createElement(LoadingPage, null));
    }
};
export default Benchmarks;
