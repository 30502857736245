import { Dropdown, } from '@symphony-ui/uitoolkit-components';
import * as React from 'react';
const KeyValueRegexPicker = ({ isDisabled, keyValueRegexId, options, setKeyValueRegexId, }) => {
    const onChangeKey = React.useCallback((event) => {
        const { value } = event.target;
        setKeyValueRegexId(value.value);
    }, [setKeyValueRegexId]);
    return (React.createElement("div", { style: { height: '4.5rem' } },
        React.createElement("div", { style: { width: 'calc(100% - 4rem)', position: 'absolute' } },
            React.createElement(Dropdown, { isDisabled: isDisabled, isInputClearable: true, label: "Key Value Regex", name: "key", onChange: onChangeKey, value: options.find((o) => (o.value === keyValueRegexId)), options: options }))));
};
export default KeyValueRegexPicker;
