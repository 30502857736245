import { Source } from '../../sources/sources';
import IpoFilter from '../filter/IpoFilter';
import IpoQuery from '../query/IpoQuery';
import MetaData from 'components/metadata/MetaData';
class IpoSearch extends MetaData {
    filter;
    name;
    query;
    constructor(response) {
        super(response);
        this.filter = response?.filter ? new IpoFilter(response.filter) : new IpoFilter();
        this.name = response?.name;
        this.query = response?.query ? new IpoQuery(response.query) : new IpoQuery();
    }
    static create(base) {
        const iposearch = base;
        return new IpoSearch(iposearch);
    }
    database() { return Source.IPO; }
    clone() {
        return new IpoSearch({
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            lastModifiedBy: this.lastModifiedBy,
            lastModifiedDate: this.lastModifiedDate,
            id: this.id,
            permissions: this.permissions,
            filter: this.filter,
            name: this.name,
            query: this.query
        });
    }
    updateName(value) {
        const clone = this.clone();
        clone.name = value;
        return clone;
    }
    updatePermissions(value) {
        const clone = this.clone();
        clone.permissions = value;
        return clone;
    }
}
export default IpoSearch;
